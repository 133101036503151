import React, { useState } from 'react';
import { List, ListItem, Checkbox } from '@material-ui/core';

const SelectableList = ({ items, onChange, listItemComponent = ListItemInner, selectedIds, idField }) => {
  const handleToggle = (value) => () => {
    const currentIndex = selectedIds.indexOf(value[idField]);
    const newSelectedItems = [...selectedIds];

    if (currentIndex === -1) {
      newSelectedItems.push(value[idField]);
    } else {
      newSelectedItems.splice(currentIndex, 1);
    }

    onChange(newSelectedItems);
  };


  return (
    <List>
      {items.map((item) => (
        <ListItem key={item[idField]} button onClick={handleToggle(item)}>
          <Checkbox
            edge="start"
            checked={selectedIds.indexOf(item[idField]) !== -1}
            tabIndex={-1}
            disableRipple
          />
          {
						listItemComponent(item)	
					}
        </ListItem>
      ))}
    </List>
  );
};

const ListItemInner = item => {
	return <div>{item.text}</div>
}

export { SelectableList };