import React, { useState } from 'react';
import { TextInput } from '../TextInput';
import styled from 'styled-components';

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
  margin: 6px 0 6px;
  width: 100%;
`

export default function NewsArticle(link, i, props) {
  const { name, onChange, disabled, newsError } = props;
  const [titleFocus, setTitleFocus] = useState(false)
  const [urlFocus, setUrlFocus] = useState(false)

  return (
    <Row>
      <TextInput
        hasError={!titleFocus && newsError?.title}
        disabled={disabled}
        name={`title-${i}`}
        value={link.title}
        onChange={onChange}
        placeholder='Article title'
        mode='condensed'
        style={{
          fontSize: '16px',
          width: 'auto'
        }}
        errorMessage="Please enter a valid title."
        onFocus={() => setTitleFocus(true)}
        onBlur={() => setTitleFocus(false)}
      />
      <TextInput
        hasError={!urlFocus && newsError?.url}
        disabled={disabled}
        name={`url-${i}`}
        value={link.url}
        onChange={onChange}
        placeholder='Article URL'
        mode='condensed'
        style={{
          fontSize: '16px',
          width: 'auto'
        }}
        errorMessage="Please enter a valid URL."
        onFocus={() => setUrlFocus(true)}
        onBlur={() => setUrlFocus(false)}
      />
    </Row>
  );
}
