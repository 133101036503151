import React, { useEffect, useState, useMemo } from 'react';
import superagent from 'superagent';
import {isPermitted, useFeathers} from '../../app/util';
import { useSelector } from 'react-redux';
import {
  useParams,
  useLocation,
  useHistory
} from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';

import {
	LoadingSpinner,
  DataDetailToolbar,
  LineGraph,
  CircularProgressBar,
  BarGraph
} from '../../components';
import styled from 'styled-components';
import moment from 'moment'
import Typography from '@material-ui/core/Typography';
import qs from 'qs';
import { IconButton } from '@material-ui/core';

const ElectionProgress = () => {
  const feathers = useFeathers();
  const electionKey = useParams().key;
  const user = useSelector(state => state.user);
  const editPerms = isPermitted(user, ['editor', 'super-admin']);
  const electionDataPreloaded = useSelector(state => state?.elections?.byKey[electionKey]);
  const [ election, setElection ] = useState(electionDataPreloaded);
  const [ electionImplementation, setElectionImplementation ] = useState(null)
  const [ loading, setLoading ] = useState(!Boolean(electionDataPreloaded));

  const location = useLocation()
  const history = useHistory()
  const refreshElectionData = async () => {
    try {
      const newData = await feathers.getService('elections').get(
        electionKey
      );
      setElection(newData)
      const eiData = await feathers.getService('election-implementations').get(electionKey);
      setElectionImplementation(eiData)

      setLoading(false)
    } catch(err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if(feathers) {
      refreshElectionData()
    }
  }, [ feathers ])

  const profilesCompletedLastWeek = useMemo(() => {
    if(!electionImplementation) return [];
    return Object.entries(electionImplementation.aggregatedInformation.byDate).map(([date, stats]) => {
      return {
        date,
        value: stats.numProfilesCompleted
      }
    })
  }, [electionImplementation])


  const profilesByPriority = useMemo(() => {
    if(!electionImplementation) return [];
    return Object.entries(electionImplementation.aggregatedInformation.byPriority).map(([priorityLevel, stats]) => {
      const numCompleted = stats.numCandidatesCompleted;
      const numRemaining = stats.numCandidates - numCompleted;
      return {
        category: priorityLevel.slice(0, 1).toUpperCase() + priorityLevel.slice(1) + ' priority',
        segments: [
          { value: numCompleted, color: '#3f6c46', label: 'Completed' },
          { value: numRemaining, color: '#c3dcc7', label: 'Remaining' }
        ]
      }
    })
  }, [electionImplementation])

  if(loading) {
    return (
      <Wrapper>
        <LoadingTile>
          <LoadingSpinner scale={0.8}/>
        </LoadingTile>
      </Wrapper>
    )
  }


  return (
    <Wrapper>
      <DataDetailToolbar
        onBack={{ to: {
            pathname: `/elections/${electionKey}`,
          }}}
        navTree={[
          {
            text: 'Elections',
            to: {
              pathname: `/elections`,
            }
          },
          {
            text: election?.name,
            to: {
							pathname: `/elections/${electionKey}`,
            }
          },
          { text: 'Progress' }
        ]}
      />
      <Inner>
        <Typography variant='h2'>Progress</Typography>
        <Tile>
          <div style={{ display: 'flex', gap: '22px', alignItems: 'center', justifyContent: 'space-around' }}>
            <CircularProgressBar
              percentage={Boolean(electionImplementation?.aggregatedInformation?.numScoutingDistricts) 
                ? Math.round((electionImplementation?.aggregatedInformation?.numScoutingDistrictsCompleted / electionImplementation?.aggregatedInformation?.numScoutingDistricts) * 100)
                : 0}
              subtext={'scouting complete'}
              dataTip={
                `${typeof(electionImplementation?.aggregatedInformation?.numScoutingDistrictsCompleted) === 'undefined' 
                ? '--' 
                : electionImplementation?.aggregatedInformation?.numScoutingDistrictsCompleted
              } of ${
                typeof(electionImplementation?.aggregatedInformation?.numScoutingDistricts) === 'undefined'
                ? '--'
                : electionImplementation?.aggregatedInformation?.numScoutingDistricts
              } districts scouted`}
            />
            <CircularProgressBar
              percentage={Boolean(electionImplementation?.aggregatedInformation?.numProfiles) 
                ? Math.round((electionImplementation?.aggregatedInformation?.numProfilesCompleted / electionImplementation?.aggregatedInformation?.numProfiles) * 100)
                : 0}
              subtext={'profiles complete'}
              dataTip={
                `${typeof(electionImplementation?.aggregatedInformation?.numProfilesCompleted) === 'undefined' 
                ? '--' 
                : electionImplementation?.aggregatedInformation?.numProfilesCompleted
              } of ${
                typeof(electionImplementation?.aggregatedInformation?.numProfiles) === 'undefined'
                ? '--'
                : electionImplementation?.aggregatedInformation?.numProfiles
              } complete`}
            />
            <CircularProgressBar
              percentage={Boolean(electionImplementation?.aggregatedInformation?.numRaces) 
                ? Math.round((electionImplementation?.aggregatedInformation?.numRacesCompleted / electionImplementation?.aggregatedInformation?.numRaces) * 100)
                : 0}
              subtext={'races complete'}
              dataTip={
                `${typeof(electionImplementation?.aggregatedInformation?.numRacesCompleted) === 'undefined' 
                ? '--' 
                : electionImplementation?.aggregatedInformation?.numRacesCompleted
              } of ${
                typeof(electionImplementation?.aggregatedInformation?.numRaces) === 'undefined'
                ? '--'
                : electionImplementation?.aggregatedInformation?.numRaces
              } complete`}
            />
            <CircularProgressBar
              percentage={Boolean(electionImplementation?.aggregatedInformation?.numMeasures) 
                ? Math.round((electionImplementation?.aggregatedInformation?.numMeasuresCompleted / electionImplementation?.aggregatedInformation?.numMeasures) * 100)
                : 0}
              subtext={'measures complete'}
              dataTip={
                `${typeof(electionImplementation?.aggregatedInformation?.numMeasuresCompleted) === 'undefined' 
                ? '--' 
                : electionImplementation?.aggregatedInformation?.numMeasuresCompleted
              } of ${
                typeof(electionImplementation?.aggregatedInformation?.numMeasures) === 'undefined'
                ? '--'
                : electionImplementation?.aggregatedInformation?.numMeasures
              } complete`}
            />
          </div>
        </Tile>
        <Tile>
          <Typography variant="body1" style={{ fontWeight: 'bold' }}>By priority</Typography>
          <BarGraph
            data={profilesByPriority}
            height={400}
          />
        </Tile>
        <Typography variant='h2' style={{ marginTop: '36px'}}>Team velocity</Typography>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch', gap: '24px'}}>
          <Tile style={{ paddingRight: '36px' }}>
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>Profiles completed today</Typography>
            <Typography variant='h1' style={{ margin: '24px 0 0' }}>
              {electionImplementation?.aggregatedInformation?.profilesCompletedToday ?? 0}
            </Typography>
            <Typography variant='body2' style={{ margin: '0 0 24px' }}>profiles</Typography>
          </Tile>
          <Tile style={{ paddingRight: '36px' }}>
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>Profiles completed this week</Typography>
            <Typography variant='h1' style={{ margin: '24px 0 0' }}>
              {electionImplementation?.aggregatedInformation?.profilesCompletedLastWeek ?? 0}
            </Typography>
            <Typography variant='body2' style={{ margin: '0 0 24px' }}>profiles</Typography>
          </Tile>
        </div>
        <Tile>
          <Typography variant="body1" style={{ fontWeight: 'bold' }}>Profiles completed</Typography>
          <LineGraph
            data={profilesCompletedLastWeek}
            startDate={moment().subtract(7, 'days').format('YYYY-MM-DD')}
            endDate={moment().format('YYYY-MM-DD')}
            height={300}
          />
        </Tile>
      </Inner>
    </Wrapper>
  )
}


const Wrapper = styled.div`
  width: calc(100% - 2 * 24px);
  min-height: calc(100% - 24px * 2);
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: stretch;
`;

const LoadingTile = styled.div`
  width: 100%;
  align-self: center;
  justify-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Tile = styled.div`
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 24px;
`

const Inner = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding-top: 36px;
`

export default ElectionProgress;
