import React, { useState, useEffect } from 'react'
import { useFeathers, isPermitted, researcherLevelPermissions } from '../../app/util';
import { useSelector } from 'react-redux';
import { FieldInlineEdit } from '../FieldInlineEdit';
import { DataField } from '../DataField';
import { FieldButtonToolbar } from '../FieldButtonToolbar';
import { ImpactIssueInput } from '../ImpactIssueInput';
import { ImpactIssues } from '../ImpactIssues';
import Typography from '@material-ui/core/Typography';
import ReactMarkdown from 'react-markdown';
import {IssuesToCoverInput} from "../IssuesToCoverInput";
import { TypeDropdown } from '../TypeDropdown';
import { Chip } from '@material-ui/core';

const typeMap = {
  'long': {
    fieldNameRace: 'descriptionLong',
    fieldNameOffice: 'descriptionLong',
    label: 'Long description',
    multiline: true
  },
  'short': {
    fieldNameRace: 'descriptionShort',
    fieldNameOffice: 'descriptionShort',
    label: 'Short description',
    multiline: false
  },
  'title': {
    fieldNameRace: 'officeName',
    fieldNameOffice: 'name',
    label: 'Title',
    multiline: false
  },
  'impactIssues': {
    fieldNameRace: 'impactIssues',
    fieldNameOffice: 'impactIssues',
    label: 'How this office impacts your life'
  },
  'issuesToCover': {
    fieldNameRace: 'issuesToCover',
    fieldNameOffice: 'issuesToCover',
    label: 'Issues to cover'
  },
  'districtTypes': {
    fieldNameOffice: 'districtTypes',
    label: 'District types'
  }
}

export const EditOfficeField = ({
  raceId,
  officeId,
  priorityLevel = 'high',
  value,
  onChange,
  style = {},
  defaultValue,
  districtTypesForState,
  editingDisabled = false,
  type,                       // long, short, title, or impactIssues
}) => {
  const editPermissionsRequired = officeId
    ? ['editor', 'super-admin']
    : ['editor', 'super-admin', 'publisher', 'reviewer'];
  
  const paramsForType = typeMap[type];
  if(!paramsForType) throw new Error('Invalid type provided to EditOfficeField');

  const feathers = useFeathers();
  const user = useSelector(state => state.user);
  const editable = editingDisabled 
    ? false 
    : (
      isPermitted(user, editPermissionsRequired) || 
      (
        (
          isPermitted(user, ['researcher']) && 
          (priorityLevel ? researcherLevelPermissions[user?.researcherLevel].includes(priorityLevel) : true)
        )
      )
    );
  const [ editing, setEditing ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ impactIssues, setImpactIssues ] = useState(
    (type === 'impactIssues' && value)
    ? value.map(ii => ({ issue: { key: ii?.key, name: ii?.name }, how: ii.how }))
    : []
  );
  const [ districtTypes, setDistrictTypes ] = useState(
    (type === 'districtTypes' && value)
    ? value
    : []
  );

  const [ issuesToCover, setIssuesToCover ] = useState(
    (type === 'issuesToCover' && value)
      ? value.map(ii => ({ issue: { key: ii?.key, name: ii?.name }}))
      : []
  );

  const onChangeImpactIssues = (e) => {
    setImpactIssues(e.target.value.slice())
  }

  const onChangeIssuesToCover = (e) => {
    setIssuesToCover(e.target.value.slice())
  }

  const onChangeDistrictTypes = (e) => {
    setDistrictTypes(e.slice())
  }

  const save = async (newVal) => {
    setLoading(true)
    try {
      if(!raceId && !officeId) throw new Error('No race or office id provided...')
      const isRaceEdit = Boolean(raceId);

      const valuesMatch = Array.isArray(newVal) ? issueArrayEq(newVal, defaultValue) : newVal === defaultValue;
      const valToSet = valuesMatch && isRaceEdit ? (Array.isArray(newVal) ? [] : null ): newVal;

      const update = {};
      const fieldName = isRaceEdit ? typeMap[type].fieldNameRace : typeMap[type].fieldNameOffice;
      update[fieldName] = valToSet;
      const res = await feathers.getService(isRaceEdit ? 'races' : 'offices').patch(isRaceEdit ? raceId : officeId, update);
      console.log(res)
      setEditing(false)
      if(onChange) onChange(res)
    } catch(error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }
  const isDefault = Array.isArray(value)
    ? issueArrayEq(value, defaultValue)
    : value === defaultValue;
  const dataFieldLabel = defaultValue
    ? `${paramsForType.label} · ${isDefault && defaultValue ? 'Default' : 'Custom'}`
    : paramsForType.label;

  if(editing) {
    if(type === 'impactIssues' || type === 'issuesToCover') {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', ...style }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography variant='h5'>{paramsForType.label}</Typography>
            <FieldButtonToolbar
              onSave={() => save(
                // toodoimpactIssues.slice()
                (type === 'impactIssues')
                  ? impactIssues.map(ii => ({ key: ii?.issue?.key, how: ii?.how }))
                  : issuesToCover.map(ii => ({ key: ii?.issue?.key }))
              )}
              onCancel={() => {
                setEditing(false)
                if(type === 'impactIssues')
                  setImpactIssues((type === 'impactIssues' && value)
                    ? value.map(ii => ({ issue: { key: ii?.key, name: ii?.name }, how: ii.how }))
                    : []
                  )
                else
                  setIssuesToCover((type === 'issuesToCover' && value)
                    ? value.map(ii => ({ issue: { key: ii?.key, name: ii?.name } }))
                    : []
                  )
              }}
              onReset={
                defaultValue
                ? () => save(defaultValue)
                : null
              }
            />
          </div>
          {
            type === 'impactIssues'
            ? <ImpactIssueInput
                onChange={onChangeImpactIssues}
                value={impactIssues}
                />
            : <IssuesToCoverInput
                onChange={onChangeIssuesToCover}
                value={issuesToCover}
              />
          }

        </div>
      )
    }
    if(type === 'districtTypes') {
      return (
        <div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography variant='h5'>{paramsForType.label}</Typography>
            <FieldButtonToolbar
              onSave={() => save(
                districtTypes
              )}
              onCancel={() => {
                setEditing(false)
                setDistrictTypes(type === 'districtTypes' && value ? value : [])
              }}
            />
          </div>
          <div style={{ flex: 1, display: 'flex', flexWrap: 'wrap' }}>
            {
              districtTypes.map((type, i) => (
                <Chip
                  key={i}
                  label={districtTypesForState.find(t => t.type === type)?.typeWritten || type}
                  onDelete={() => {
                    const newTypes = districtTypes.filter(t => t !== type)
                    onChangeDistrictTypes(newTypes)
                  }}
                  style={{ margin: '4px' }}
                />
              ))
            }
            <TypeDropdown
              placeholder={'Add type'}
              value={null}
              options={districtTypesForState.map(({type, typeWritten}) => ({ type, title: typeWritten }))}
              onChange={(newType) => {
                const newTypes = districtTypes.concat(newType)
                onChangeDistrictTypes(newTypes)
              }}
            />
          </div>
        </div>
      )
    }
    return (
      <FieldInlineEdit
        loading={loading}
        onSave={save}
        onCancel={() => setEditing(false)}
        onReset={
          defaultValue
          ? () => save(value)
          : null
        }
        label={paramsForType.label}
        seedValue={value}
        style={style}
        multiline={paramsForType.multiline}
      />
    );
  }

  return (
    <DataField
      title={dataFieldLabel}
      onEdit={
        editable? () => setEditing(true) : null
      }
      style={style}
    >
      {
        type === 'impactIssues' &&
        (
          value?.length > 0
          ? <ImpactIssues
              issues={value}
            />
          : <Typography variant={'body1'}>(No issues specified yet.)</Typography>
        )
      }
      {
        type === 'issuesToCover' &&
        <Typography variant={'body1'}>{
          value?.length > 0
          ? value.map(i => i.name).join(', ')
          : '(No issues specified yet.)'
        }</Typography>
      }
      {
        !['issuesToCover', 'impactIssues', 'districtTypes'].includes(type) &&
        <div style={{ fontWeight: 'normal', fontSize: '16px' }}>
          {
            paramsForType.multiline
              ? <ReactMarkdown children={value ?? '(No information yet.)'}/>
              : (value ?? '(No information yet.)')
          }
        </div>
      }
      {
        type === 'districtTypes' &&
        <Typography variant={'body1'}>{
          value?.length > 0
          ? value.map(type => (districtTypesForState || []).find(t => t.type === type)?.typeWritten || prettyNameForType(type)).join(', ')
          : '(No types specified yet.)'
        }</Typography>
      }
    </DataField>
  );
}

const issueArrayEq = (arr1, arr2) => {
  if(arr1?.length !== arr2?.length) return false;
  if(!arr1.every(ii => arr2.filter(ii2 => ii2.name === ii.name && ii2.how === ii.how).length >= 1)) return false;
  if(!arr2.every(ii => arr1.filter(ii2 => ii2.name === ii.name && ii2.how === ii.how).length >= 1)) return false;
  return true;
}

const prettyNameForType = (type, names) => {
  const prettyName = (names || {})[type]?.prettyName;
  return prettyName || (type.split('-').map(t => t.charAt(0).toUpperCase() + t.slice(1)).join(' ') + ' District')
}