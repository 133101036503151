import React, { useEffect, useState } from 'react';
import { useFeathers } from '../../app/util';
import { SearchableSelectInput } from '../SearchableSelectInput';

const DistrictSelectInput = ({
  value,
  onChange,
  descendantOf = null,
  style = {},
  districtTypes,
  multi = false,
  primaryDistrictId = null,
  seedMultiSearchText = [],
  clearAfterSelect = false
}) => {
  const feathers = useFeathers();

  const [ optionsForField, setOptionsForField ] = useState([]);
  const [ loadingForField, setLoadingForField ] = useState([]);  // an array of timestamps
  const [ loading, setLoading ] = useState(false);
  const [ queuedResultsForField, setQueuedResultsForField ] = useState(null);

  const searchForField = async (searchText) => {
    if(!feathers) return;

    const timestamp = new Date().getTime();
    setLoadingForField(l => [...l, timestamp]);
    setLoading(true)

    const districts = await feathers.getService('districts').find({ query: {
      ...(searchText ? { searchTerm: { $search: searchText } } : {}),
      type: districtTypes,
      ...(descendantOf != null ? { $descendantOf: descendantOf, $nestChildren: false, $includeAncestor: true } : {}),
    }})
    let options = districts.data.map(({ longName, _id, votingType, ...rest }) => ({ text: `${longName}${votingType === 'representative-district' ? ' (at-large)' : ''}`, _id, longName, ...rest }));
    if (multi && primaryDistrictId) {
      options = options.filter(({ _id }) => _id !== primaryDistrictId);
    }

    setQueuedResultsForField({
      timestamp,
      options
    })
  }

  useEffect(() => {
    if(queuedResultsForField) {
      setQueuedResultsForField(null);

      const mostRecentTimestamp = loadingForField[loadingForField.length - 1];
      const isMostRecentResult = mostRecentTimestamp === queuedResultsForField.timestamp;
      if(!isMostRecentResult) return;

      // we do this so that we only acknowledge the most recent timestamp
      setOptionsForField(queuedResultsForField.options);
      setLoading(false);
    }
  }, [queuedResultsForField, loadingForField ])

  return (
    <SearchableSelectInput
      name='district'
      onChangeSearchText={(searchText) => searchForField(searchText)}
      value={value}
      onChange={onChange}
      optionsLoading={loading}
      options={optionsForField|| []}
      style={style}
      multi={multi}
      clearAfterSelect={clearAfterSelect}
      seedMultiSearchText={seedMultiSearchText}
    />
  )
}

export { DistrictSelectInput };
