import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Plus } from './plus.svg';

// This component returns a + button alongside some optional text

const AddButton = ({
  children,
  style={},
  textStyle={},
  color = '#707683',
  onClick
}) => {
  return (
    <Wrapper color={color} onClick={onClick} style={style}>
      <Plus/>
      {
        children &&
        <span style={textStyle}>{children}</span>
      }
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px;
  border-radius: 30px;
  cursor: pointer;

  span {
    color: ${props => props.color};
    margin-left: 9px;
    margin-right: 4px;
    font-size: 16px;
    margin-top: 2.5px;
  }

  svg {
    height: 11px;
    width: 11px;
    fill: ${props => props.color};
  }

  &:hover {
    background-color: ${props => `${props.color}18`};
  }
`;

export { AddButton };
