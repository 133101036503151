import { Typography } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const TabBarContinuous = ({
	sections,
	style,
	className
}) => {
	const [activeTab, setActiveTab] = React.useState(0);
	const [sectionsVisible, setSectionsVisible] = React.useState({});	// a dictionary of index to visible status
	const [firstSectionVisible, setFirstSectionVisible] = React.useState(0);
	const previousFirstSectionVisible = usePrevious(firstSectionVisible);
	const [disableTabSwitch, setDisableTabSwitch] = React.useState(false);
	
	const parentRef = React.createRef();

	useEffect(() => {
		// any time this changes, default to the first section visible
		let first = undefined;
		for (let i = 0; i < sections.length; i++) {
			if (sectionsVisible[`${i}`]) {
				first = i;
				break;
			}
		}
		if(typeof(first) !== 'undefined')
			setFirstSectionVisible(first);
	}, [sectionsVisible])

	useEffect(() => {
		if(disableTabSwitch) return;

		if(typeof(previousFirstSectionVisible) !== 'undefined' && firstSectionVisible === previousFirstSectionVisible) return;

		// scroll to the active tab
		setActiveTab(firstSectionVisible);
	}, [ firstSectionVisible, disableTabSwitch ])

	// create an effect that monitors each sections, and constantly sets which secitons are visible
	useEffect(() => {
		let observers = [];

		console.log('sections', sections)

		sections.forEach(({ ref }, index) => {
			setSectionsVisible((prev) => {
				return {
					...prev,
					[`${index}`]: false
				}
			})
			const observer = new IntersectionObserver(
				([entry]) => {
					setSectionsVisible((prev) => {
						return {
							...prev,
							[`${index}`]: entry.isIntersecting
						}
					})
				}, {
					threshold: 0.3
				}
			);

			if (ref.current) {
				observer.observe(ref.current);
			}
		})

		return () => {
			sections.forEach(({ ref }, index) => {
				if (ref.current && observers[index]) {
					observers[index].unobserve(ref.current);
				}
			})
		};
	}, [sections]);
	

	const focusSection = (index) => {
		setDisableTabSwitch(true)
		setActiveTab(index);
		if(sections[index]?.ref?.current)
			sections[index].ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
		// wait for the scroll to finish then re-enable the tab switch
		setTimeout(() => {
			setDisableTabSwitch(false);
		}, 1500);
	}

	return (
		<Wrapper style={style} className={className} ref={parentRef}>
			<WrapperInner>
				{
					sections.map(({ title }, index) => {
						return (
							<Tab key={title} onClick={() => focusSection(index)} active={activeTab === index ? 1 : 0}>
								<Typography variant='body1' style={{ fontSize: '18px', ...(activeTab === index ? { fontWeight: 'bold' } : {}) }}>
									{title}
								</Typography>
							</Tab>
						)
					})
				}
			</WrapperInner>
		</Wrapper>
	);
}

const Tab = ({
	children,
	active,
	onClick
}) => {
	const ref = React.useRef();
	
	// useEffect(() => {
	// 	if(active) {
	// 		if(ref.current) {
	// 			ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
	// 		}
	// 	}
	// }, [active])

	return (
		<TabDiv active={active} onClick={onClick} ref={ref}>
			{children}
		</TabDiv>
	)
}

const Wrapper = styled.div`
	width: calc(100% - 32px * 2);
	max-width: 900px; 
	overflow-x: scroll;
	top: 0;
	position: sticky;
	background-color: #ffffff;
	z-index: 100;
	border-bottom: 4px solid #eeeeee;
`

const WrapperInner = styled.div`	
	min-width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	gap: 12px;
	padding: 12px 0 0px;
	align-items: center;
`

const TabDiv = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 4px 12px 0;
	border-radius: 4px;
	/* add no word break */
	white-space: nowrap;
	cursor: pointer;
	${props => props.active
		? `
			background-color: #F7F9FD;
		`
		: `
			opacity: 0.8;
			&:hover {
				opacity: 1;
				background-color: #f0f0f0;
			}
		`
	}
`

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}


export { TabBarContinuous }
