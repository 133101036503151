import React, { Component, useEffect, useState, useMemo } from 'react';
import { useFeathers } from '../../app/util';
import {
  useParams,
} from 'react-router-dom';
import {
	DataDetailToolbar
} from '../../components';
import styled from 'styled-components';
import { ReportedError } from '../../components/ReportedError';


const ReportedErrorDetail = () => {
  const feathers = useFeathers();
  const electionKey = useParams().key;
	const reportedErrorId = useParams().id;
	const [election, setElection] = useState(null);

	useEffect(() => {
		if(feathers && electionKey) loadElectionData();
	}, [electionKey, feathers]);

	const loadElectionData = async () => {
		const electionData = await feathers.getService('elections').get(electionKey);
		setElection(electionData);
	}

  const [ issueTitle, setIssueTitle ] = useState(null)

  
  return (
    <Wrapper>
      <DataDetailToolbar
        onBack={{ to: {
            pathname: `/reported-errors`,
          }}}
        navTree={[
          {
            text: 'Errors',
            to: {
              pathname: `/reported-errors`,
            }
          },
          { text: issueTitle || '--' }
        ]}
      />
			<Inner>
				<ReportedError
          reportedErrorId={reportedErrorId}
          onLoadErrorFull={(reportedError) => {
            if(reportedError) {
              const errorType = reportedError?.type
              setIssueTitle(errorType.slice(0, 1).toUpperCase() + errorType.slice(1) + ' error')
            } else {
              setIssueTitle(null)
            }
          }}
        />
			</Inner>
    </Wrapper>
  )
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	padding: 24px 12px 0;
`;

const Inner = styled.div`
	padding: 36px;
	width: calc(100% - 36px * 2);
	max-width: 800px;
`;

export default ReportedErrorDetail;
