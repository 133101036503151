import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useFormInputs, useFeathers } from '../../app/util';
import { SearchableSelectInput } from '../SearchableSelectInput';
import moment from 'moment';

const CFSelectInput = ({
  value,
  onChange,
  style = {},
  candidateId
}) => {
  const feathers = useFeathers();

  const [ optionsForField, setOptionsForField ] = useState([]);
  const [ loadingForField, setLoadingForField ] = useState(false);
  const searchForField = async (searchText) => {
    if(!feathers) return;
    setLoadingForField(true);

    const financeOptions = await feathers.getService('finances').get(
      candidateId,
      searchText ? { query: { candidateName: searchText } } : undefined
    )
    const options = financeOptions.data.map(({ firstname, lastname, officename, process_date, _id, filerid }) => ({
      text: `${firstname} ${lastname}`,
      subText: `${officename} · Last updated ${moment.utc(process_date).format('MMM DD, YYYY')}`,
      filerId: filerid
    }));

    setOptionsForField(options)
    setLoadingForField(false);
  }

  useEffect(() => {
    searchForField()
  }, [])

  // TODO – default rec for candidate name?, integrate options (be sure to include office + name + city), change status and acvtion when it's actually set

  return (
    <SearchableSelectInput
      name='filer'
      onChangeSearchText={(searchText) => searchForField(searchText)}
      value={value}
      onChange={onChange}
      optionsLoading={loadingForField}
      options={optionsForField || []}
      style={style}
    />
  )
}

export { CFSelectInput };
