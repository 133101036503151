import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useFormInputs, useFeathers } from '../../app/util';
import { SearchableSelectInput } from '../SearchableSelectInput';
import moment from 'moment';

const ElectionResultsSelectInput = ({
  value,
  onChange,
  style = {},
  candidateId,
  measureId,
  disabled,
  option            // 'yes' or 'no'... optional
}) => {
  const feathers = useFeathers();

  const [ latestStartedAt, setLatestStartedAt ] = useState(null);
  const [ optionsForField, setOptionsForField ] = useState([]);
  const [ loadingForField, setLoadingForField ] = useState(false);
  const [ optionsForFieldOnDeck, setOptionsForFieldOnDeck ] = useState(null);

  const searchForField = async (searchText) => {
    if(!feathers) return;
    setLoadingForField(true);

    const startedAt = moment().format();
    let options;
    try {
      options = (await feathers.getService('election-results').get(
        measureId || candidateId,
        {
          query: {
            $measure: Boolean(measureId),
            ...(measureId ? { measureTitle: searchText, $measure: true } : { candidateName: searchText } )
          }
        }
      )).data
    } catch(err) {
      options = []
    }
    const optionsMapped = measureId
      ? options.filter(r => option ? r.option.toUpperCase() === option.toUpperCase(): true ).map(({ id, option, title, votes, county }) => ({
        text: `${county}: ${title}`,
        subText: `${option} · ${votes} votes`,
        clarityLookup: {
          clarityId: id,
          measure: true
        }
      }))
      : options.map(({ id, candidate, office, raceParty, results_last_updated, party, county, votes }) => ({
        text: `${candidate}`,
        subText: `${office} (${county}) · ${votes} votes · Last updated ${moment.utc(results_last_updated).format('MMM DD, YYYY')}`,
        clarityLookup: {
          clarityId: id
        }
      }));

    setOptionsForFieldOnDeck({ options: optionsMapped, startedAt })
  }

  useEffect(() => {
    searchForField()
  }, [])

  useEffect(() => {
    if(optionsForFieldOnDeck) {
      setOptionsForFieldOnDeck(null);
      const { options, startedAt } = optionsForFieldOnDeck;
      if(latestStartedAt) {
        if(moment(startedAt).isAfter(moment(latestStartedAt))) {
          setLatestStartedAt(startedAt)
          setOptionsForField(options)
        } else {
          // do nothing
        }
      } else {
        setLatestStartedAt(startedAt)
        setOptionsForField(options)
      }

      setLoadingForField(false);
    }
  }, [ optionsForFieldOnDeck, latestStartedAt ])

  return (
    <SearchableSelectInput
      name='filer'
      onChangeSearchText={(searchText) => searchForField(searchText)}
      value={value}
      onChange={onChange}
      optionsLoading={loadingForField}
      options={optionsForField || []}
      style={style}
      disabled={disabled}
    />
  )
}

export { ElectionResultsSelectInput };
