import React, { useState, useMemo, useEffect } from 'react';
import { useFeathers, useFormInputs, isPermitted, coveragePlanSpecifications, researcherLevelPermissions } from '../../app/util';
import {
  DataField,
  FieldInlineEdit,
  ManualVoteTally,
  CandidateProgressBar,
  Researcher,
  CFSelectInput,
  ElectionResultsSelectInput,
  LoadingSpinner,
  TaskPreviewTile,
  UpdateCandidateRace
} from '../index';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { ReactComponent as WarningIcon } from '../../images/yellow-warning.svg';
import { ReactComponent as GreenCheck } from '../../images/green-check.svg';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import { DatePicker } from '@material-ui/pickers';
import ClockIcon from '@material-ui/icons/AccessTime';
import { Skeleton } from '@material-ui/lab';
import {
  useParams,
  useHistory
} from 'react-router-dom';

const permissionsOrdered = ['researcher', 'reviewer', 'publisher', 'editor', 'admin', 'super-admin'];
const CandidateDataFieldEditor = ({
  candidateId,
  onError,
  race,
  style,
  className
}) => {
  const feathers = useFeathers();

  const [ candidate, setCandidate ] = useState(null);
  const { stagingDraft = {}, qualified, coveragePlan, withdrawn, workflow } = (candidate || {});
  let qualStatus;
  if(qualified === 'yes') qualStatus = 'Qualified';
  else if(qualified === 'no') qualStatus = `Didn't qualify`;
  else qualStatus = 'To be determined';

  const user = useSelector(state => state.user);

  const editPermission = isPermitted(user, ['super-admin', 'editor', 'admin', 'researcher', 'reviewer', 'publisher']);
  const reviewerPermission = isPermitted(user, ['reviewer'])
  const editorPermission = isPermitted(user, ['super-admin', 'editor'])
  const researcherPermission = researcherLevelPermissions[user?.researcherLevel].includes(candidate?.priorityLevel);
  const publishPermission = coveragePlan ? isPermitted(user, permissionsOrdered.slice(permissionsOrdered.indexOf(coveragePlanSpecifications[coveragePlan].minPermissionToPublish))) : false;
  
  const {
    progress,
    synced
  } = stagingDraft;

  const [ saving, setSaving ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ editingLastChecked, setEditingLastChecked ] = useState(false);
  const [ missingInfoChecked, setMissingInfoChecked ] = useState(null);
  const [ savingLastChecked, setSavingLastChecked ] = useState(false);
  const [ tasks, setTasks ] = useState(null);

  const [ qualEdits, onChangeQualEdits ]  = useFormInputs({ qualified });

  const [ editingRace, setEditingRace ] = useState(false);
  const [ editingFinance, setEditingFinance ] = useState(false);
  const [ newFinanceInput, setNewFinanceInput ] = useState(null);
  const [ savingFinance, setSavingFinance ] = useState(false);
  const [ refreshingFinanceData, setRefreshingFinanceData ] = useState(false);
  const [ newClarityInput, setNewClarityInput ] = useState(null);
  const [ savingElectionResults, setSavingElectionResults ] = useState(false);
  const [ editingElectionResults, setEditingElectionResults ] = useState(false);
  const [ refreshingElectionResults, setRefreshingElectionResults ] = useState(false);
  const [ editingQualified, setEditingQualified ] = useState(false);
  const [ editingWithdrawn, setEditingWithdrawn ] = useState(false);
  const [withdrawnStatus, setWithdrawnStatus] = useState(false);
  
  const election = useParams().key;
  const electionData = useSelector(state => state?.elections?.byKey[election])
  const history = useHistory();

  const editsMadeBy = useMemo(() => {
    const drafts = candidate?.previousDrafts || [];
    const usersUnique = [ stagingDraft, ...drafts ].map(d => d?.user || d?.systemUpdateSource).filter(Boolean).reduce((acc, cur) => {
      acc[cur?._id || cur] = cur;
      return acc;
    }, {});
    return Object.values(usersUnique).map(u => u === 'profiler' ? 'Profiler' : u?.name || u?.firstName || u?.email);
  }, [candidate, stagingDraft])

  useEffect(() => {
    if(editingWithdrawn) setWithdrawnStatus(withdrawn)
  }, [ editingWithdrawn ])

  const loadCandidateData = async () => {
    if(loading) return;

    setLoading(true);
    try {
      const res = await feathers.getService('candidates').get(candidateId);
      setCandidate(res);
    } catch(err) {
      console.log(err)
      onError(err)
    } finally {
      setLoading(false)
    }
  }

  const loadTasks = async () => {
    try {
      const res = await feathers.getService('research-tasks').find({
        query: {
          'details.candidate': candidateId,
          $includeCheckedOut: true,
          $limit: 20,
          $sort: { createdAt: -1 }
        }
      });
      setTasks(res.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
    } catch(err) {
      console.log('Error in loading tasks', err)
      setTasks([])
    }
  }

  useEffect(() => {
    if(feathers) {
      loadCandidateData()
      loadTasks()
    }
  }, [ feathers, candidateId ])


  const formattedWorkflowStatus = useMemo(() => {
    // toolTip, title, icon

    // support old candidates:
    if (!workflow?.status) {
      if(synced) {
        return {
          toolTip: 'All changes have been published',
          title: 'Published',
          icon: <GreenCheck />
        }
      } else {
        return {
          toolTip: 'Some unpublished changes',
          title: 'Draft',
          icon: <WarningIcon />
        }
      }
    }
    if (workflow?.status === 'published') {
      return {
        toolTip: 'All changes have been published',
        title: 'Published',
        icon: <GreenCheck />
      }
    }

    // provide descriptions that are dependent on the users' role && workflow status
    if(publishPermission) {
      // offer descriptive insight into where we're at in the process
      if (workflow.status === 'draft' && !workflow.rejectionStatus) {
        return {
          toolTip: 'Candidate is currently in draft.',
          title: 'Draft',
          icon: <ClockIcon />
        }
      }
      if (['draft','researcher-submitted','reviewer-submitted'].includes(workflow.status) && workflow.rejectionStatus) {
        return {
          toolTip: 'Candidate has been rejected by publisher<br/>and awaits edits by ' +
            (workflow.status === 'reviewer-submitted' ? 'the reviewer' : 'the original researcher'),
          title: 'Needs revisions',
          icon: <ClockIcon />
        }
      }
      if (['researcher-submitted'].includes(workflow.status)) {
        return {
          toolTip: 'Candidate has been submitted and is awaiting initial review',
          title: 'In initial review',
          icon: <ClockIcon />
        }
      }

      if (['reviewer-submitted'].includes(workflow.status)) {
        return {
          toolTip: 'Candidate has gone through initial review<br/>and is awaiting your review.',
          title: 'Awaiting review',
          icon: <WarningIcon />
        }
      }

      if (['publisher-submitted'].includes(workflow.status)) {
        return {
          toolTip: 'Candidate has been reviewed and<br/>is waiting to be published.',
          title: 'Ready to publish',
          icon: <WarningIcon />
        }
      }
    } else if(reviewerPermission) {
      if (workflow.status === 'draft' && !workflow.rejectionStatus) {
        return {
          toolTip: 'Candidate is currently in draft.',
          title: 'Draft',
          icon: <ClockIcon />
        }
      }
      if (['draft','researcher-submitted'].includes(workflow.status) && workflow.rejectionStatus) {
        return {
          toolTip: 'Waiting for the original researcher to<br/>address comments from your last revision.',
          title: 'Needs revisions',
          icon: <ClockIcon />
        }
      }
      if (['researcher-submitted'].includes(workflow.status)) {
        return {
          toolTip: 'Candidate has been submitted and<br/> is awaiting your review',
          title: 'Awaiting review',
          icon: <WarningIcon />
        }
      }

      if (['reviewer-submitted'].includes(workflow.status) && workflow.rejectionStatus) {
        return {
          toolTip: 'Your draft was rejected by the publisher.<br/>Please address comments and re-submit for review.',
          title: 'Needs revisions',
          icon: <WarningIcon />
        }
      }

      if (['reviewer-submitted', 'publisher-submitted'].includes(workflow.status)) {
        return {
          toolTip: workflow.status === 'reviewer-submitted'
            ? 'Candidate is awaiting final review.'
            : 'Candidate has been reviewed and<br/>is waiting to be published.',
          title: 'In review',
          icon: <ClockIcon />
        }
      }
    } else if(editPermission) {
      if (workflow.status === 'draft' && !workflow.rejectionStatus) {
        return {
          toolTip: 'Candidate is currently in draft.<br/>Must submit any changes to review.',
          title: 'Draft',
          icon: null
        }
      }
      if (['draft','researcher-submitted'].includes(workflow.status) && workflow.rejectionStatus) {
        return {
          toolTip: 'Your draft was rejected by the reviewer.<br/>Please address comments and re-submit for review.',
          title: 'Needs revisions',
          icon: <WarningIcon />
        }
      }
      if (['researcher-submitted','reviewer-submitted','publisher-submitted'].includes(workflow.status)) {
        return {
          toolTip: workflow.status === 'researcher-submitted'
            ? 'Your draft has been submitted<br/>and is currently awaiting review.'
            : workflow.status === 'reviewer-submitted'
              ? 'Profile is currently awaiting final review.'
              : 'Profile has been fully reviewed.',
          title: 'In review',
          icon: <ClockIcon />
        }
      }
    }

    return {
      toolTip: 'Unknown',
      title: 'Unknown',
      icon: <WarningIcon />
    }
  }, [candidate, workflow, editPermission, publishPermission, reviewerPermission])


  const saveQualificationStatus = async () => {
    if(!editingQualified || saving) return;
    setSaving(true);
    try {
      const update = await feathers.getService('candidates').patch(candidate._id, {
        qualified: qualEdits.qualified
      });
      setEditingQualified(false)
      setCandidate(update);
    } catch(err) {
      console.log(err)
      onError(err)
      setSaving(false)
    }
  }

  const saveWithdrawn = async () => {
    if(!editingWithdrawn || saving) return;
    setSaving(true);
    try {
      const update = await feathers.getService('candidates').patch(candidate._id, {
        withdrawn: withdrawnStatus
      });
      setEditingWithdrawn(false)
      setCandidate(update);
    } catch(err) {
      console.log(err)
      onError(err)
      setSaving(false)
    }
  }

  const onChangeFinance = (e) => setNewFinanceInput(e.target.value)
  const saveFinance = async () => {
    const { filerId } = newFinanceInput;
    if(!filerId) {
      cancelFinance()
      return;
    }
    setSavingFinance(true)
    try {
      const updateRes = await feathers.getService('finances').patch(candidate._id, {
        filerId
      })
      setEditingFinance(false)
      setCandidate(updateRes);
    } catch(err) {
      console.log(err)
      onError(err)
    } finally {
      setSavingFinance(false)
    }
  }
  const cancelFinance = () => setEditingFinance(false)

  const refreshFinanceData = async () => {
    if(!candidate?.finance?.filerId || refreshingFinanceData) return;

    setRefreshingFinanceData(true)
    try {
      const updateRes = await feathers.getService('finances').patch(candidate._id, {
        $refresh: true
      })
      setCandidate(updateRes);
    } catch(err) {
      console.log(err)
      onError(err)
    } finally {
      setRefreshingFinanceData(false)
    }
  }

  // Election results functions
  const saveClarityLookup = async () => {
    const { clarityLookup } = newClarityInput;
    if(!clarityLookup) {
      setEditingElectionResults(false)
      return;
    }

    setSavingElectionResults(true)
    try {
      const updateRes = await feathers.getService('election-results').patch(candidate._id, {
        ...clarityLookup
      })
      setEditingElectionResults(false)
      setCandidate(updateRes);
    } catch(err) {
      console.log(err)
      onError(err)
    } finally {
      setSavingElectionResults(false)
    }
  }

  const refreshElectionResults = async () => {
    if(!(candidate?.result?.autoSources?.length > 0) || refreshingElectionResults) return;

    setRefreshingElectionResults(true)
    try {
      const updateRes = await feathers.getService('election-results').patch(candidate._id, {
        $refresh: true
      })
      setCandidate(updateRes);
    } catch(err) {
      console.log(err)
      onError(err)
    } finally {
      setRefreshingElectionResults(false)
    }
  }


  const saveLastChecked = async () => {
    if(!missingInfoChecked || savingLastChecked) return;

    setSavingLastChecked(true)
    try {
      const updateRes = await feathers.getService('candidates').patch(candidate._id, {
        missingInfoChecked: moment.utc(missingInfoChecked).format('YYYY-MM-DDTHH:mm:SSZ')
      })
      setEditingLastChecked(false)
      setCandidate(updateRes);
    } catch(err) {
      console.log(err)
      onError(err)
    } finally {
      setSavingLastChecked(false)
    }
  }

  if(loading) {
    return <Wrapper>
      <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', padding: '36px'}}>
        <LoadingSpinner />
      </div>
    </Wrapper>
  }

  return (
    <Wrapper>
      <Section>
        <Typography variant='h3'>Progress</Typography>
        <div className='seperator' />
        <DataField title='Progress'>
          <CandidateProgressBar
            candidate={stagingDraft}
            percentage={progress*100}
            style={{ width: '300px', margin: '6px 0 6px' }}
            backgroundColor='#e0e0e0'
            color='#99D266'
          />
        </DataField>
        <DataField title='Status'>
          <SyncContainer data-tip={formattedWorkflowStatus.toolTip}>
            {formattedWorkflowStatus.icon}
            <Typography variant='body1' style={{ marginTop: '2px' }}>{ formattedWorkflowStatus.title }</Typography>
          </SyncContainer>
        </DataField>
        <DataField title='Priority'>
          <SyncContainer data-tip={'Priority of the candidate is based on expected readership.'}>
            <Typography variant='body1' style={{ marginTop: '2px' }}>{candidate?.priorityLevel ? `${candidate.priorityLevel.slice(0,1).toUpperCase()}${candidate.priorityLevel.slice(1).toLowerCase()}` : 'Not set'}</Typography>
          </SyncContainer>
        </DataField>
      </Section>
      <Section>
        <Typography variant='h3'>Ballot display</Typography>
        <div className='seperator' />
        {
          editingQualified
          ?
          <FieldInlineEdit
              inputComponent={
                <Select
                    onChange={onChangeQualEdits}
                    name='qualified'
                    value={qualEdits.qualified}
                    style={{ width: 'calc(100% - 15px)' }}
                >
                  <MenuItem value='tbd'>To be determined</MenuItem>
                  <MenuItem value='yes'>Qualified</MenuItem>
                  <MenuItem value='no'>Didn't qualify</MenuItem>
                </Select>
              }
              onCancel={() => setEditingQualified(false)}
              onSave={saveQualificationStatus}
              label='Qualified'
          />
          :
          <DataField
              title='Qualified'
              onEdit={
                editPermission
                    ? () => setEditingQualified(true)
                    : null
              }
          >
            <Typography variant='body1' style={{}}>{qualStatus}</Typography>
          </DataField>
        }
        {
          editingWithdrawn
          ?
          <FieldInlineEdit
              inputComponent={
                <Select
                    onChange={(e) => setWithdrawnStatus([true, 'true'].includes(e?.target?.value))}
                    name='withdrawn'
                    value={withdrawnStatus}
                    style={{ width: 'calc(100% - 15px)' }}
                >
                  <MenuItem value={false}>No</MenuItem>
                  <MenuItem value={true}>Yes</MenuItem>
                </Select>
              }
              onCancel={() => setEditingWithdrawn(false)}
              onSave={saveWithdrawn}
              label='Withdrawn'
          />
          :
          <DataField
            title='Withdrawn'
            onEdit={
              editorPermission
                ? () => setEditingWithdrawn(true)
                : researcherPermission
                  ? () => setEditingWithdrawn(true)
                  : null
            }
          >
            <Typography variant='body1' style={{}}>{withdrawn ? 'Yes' : 'No'}</Typography>
          </DataField>
        }
        {
          editingLastChecked &&
          <FieldInlineEdit
            inputComponent={
              <DatePicker
                disableToolbar
                variant="inline"
                format="MM/DD/YYYY"
                value={missingInfoChecked}
                onChange={setMissingInfoChecked}
              />
            }
            disabled={savingLastChecked}
            onCancel={() => setEditingLastChecked(false)}
            onSave={saveLastChecked}
            label='Last checked for platform'
          />
        }
        {
          !editingLastChecked &&
          <DataField
            title='Last checked for platform'
            onEdit={
              editPermission
                ? () => setEditingLastChecked(true)
                : null
            }
          >
            <Typography variant='body1' style={{}}>{moment.utc(candidate?.stagingDraft?.missingInfoChecked || candidate?.createdAt).format('MMMM D')}</Typography>
          </DataField>
        }
        {
          editingRace 
          ?
            <UpdateCandidateRace
              candidate={candidate}
              election={electionData}
              redirectOnFinish={true}
            />
          :
            <DataField 
              title='Race'
              onEdit={
                (editorPermission || researcherPermission)
                  ? () => setEditingRace(true)
                  : null
              }
            >
              <Typography variant='body1' style={{}}>{race?.longName || '--'}</Typography>
            </DataField>
        }
      </Section>
      <Section>
        <Typography variant='h3'>Assignments</Typography>
        <div className='seperator' />
        <DataField title='Researcher'>
          <Researcher user={workflow?.assignments?.researcher} />
        </DataField>
        <DataField title='Reviewer'>
          <Researcher user={workflow?.assignments?.reviewer} />
        </DataField>
        <DataField title='Edited by'>
          {
            editsMadeBy.length > 0
            ? <div style={{ display: 'flex', flexDirection: 'column', gap: '4px'}}>
              {editsMadeBy.map((u, i) => <Typography key={i} variant='body1' style={{}}>{u}</Typography>)}
            </div>
            : <Typography variant='body1' style={{}}>--</Typography>
          }
        </DataField>
      </Section>
      <Section>
        <Typography variant='h3'>Tasks</Typography>
        <div className='seperator' />
        {
          !Array.isArray(tasks) && 
          <Skeleton variant='rect' width='100%' height={40} />
        }
        {
          Array.isArray(tasks) && tasks.map((task, i) => (
            <TaskPreviewTile key={i} task={task} />
          ))
        }
      </Section>
      <Section>
        <Typography variant='h3'>Campaign finance</Typography>
        <div className='seperator' />
        {
          editingFinance
          ?
          <FieldInlineEdit
              inputComponent={
                <CFSelectInput
                    onChange={onChangeFinance}
                    value={newFinanceInput}
                    candidateId={candidateId}
                    style={{ width: 'calc(100% - 15px)' }}
                />
              }
              loading={savingFinance}
              style={{ width: 'calc(33% - 15px)', marginBottom: '20px'}}
              onCancel={cancelFinance}
              onSave={saveFinance}
              label='Finance Filer ID'
          />
          :
          <DataField
              title='Finance Filer ID'
              style={{marginBottom: '20px'}}
              onEdit={
                editPermission
                    ? () => setEditingFinance(true)
                    : null
              }
          >
            {
              candidate?.finance?.filerId
                  ? (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <GreenCheck data-tip='Candidate has been linked to campaign finance disclosure reports.'/>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', flex: 1, marginLeft: '8px' }}>
                          <Typography variant='body1' style={{ fontSize: '14px' }}>{candidate?.finance?.filerId}</Typography>
                          <Typography variant='subtitle2' style={{ fontSize: '12px' }}>
                            Last updated {moment.utc(candidate?.finance?.updatedAt).format('MMM DD, YYYY')} · {` `}
                            {
                              refreshingFinanceData
                                  ? `Refreshing...`
                                  : <u style={{ cursor: 'pointer', fontSize: '12px' }} onClick={() => refreshFinanceData()}>Refresh</u>
                            }
                          </Typography>
                        </div>
                      </div>
                  ) : (
                      <Typography variant='body1' style={{ fontSize: '14px' }}>No campaign finance record linked.</Typography>
                  )
            }
          </DataField>
        }
      </Section>
      <Section>
        <Typography variant='h3'>Election results</Typography>
        <div className='seperator' />
          { editingElectionResults
              ?
              <FieldInlineEdit
                  inputComponent={
                    <ElectionResultsSelectInput
                        onChange={(e) => setNewClarityInput(e.target.value)}
                        value={newClarityInput}
                        candidateId={candidateId}
                        style={{ width: 'calc(100%' }}
                    />
                  }
                  loading={savingElectionResults}
                  style={{ width: 'calc(100% - 15px)'}}
                  onCancel={() => setEditingElectionResults(false)}
                  onSave={saveClarityLookup}
                  label='Election Results Link'
              />
              :
              <DataField
                  title='Election Results Link'
                  onEdit={
                    editPermission
                        ? () => setEditingElectionResults(true)
                        : null
                  }
              >
                {
                  candidate?.result?.autoSources?.length > 0
                      ? (
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <GreenCheck data-tip='Candidate has been linked to Clarity election results system.'/>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', flex: 1, marginLeft: '8px' }}>
                              <Typography variant='body1' style={{ fontWeight: 'bold', fontSize: '14px' }}>Won {candidate.result.autoSources[0].totalVotes} votes</Typography>
                              <Typography variant='subtitle2' style={{ fontSize: '12px' }}>
                                Last updated {moment.utc(candidate.result.autoSources[0].updatedAt).fromNow()} · {` `}
                                {
                                  refreshingElectionResults
                                      ? `Refreshing...`
                                      : <u style={{ cursor: 'pointer', fontSize: '12px' }} onClick={() => refreshElectionResults()}>Refresh</u>
                                }
                              </Typography>
                            </div>
                          </div>
                      ) : (
                          <Typography variant='body1' style={{ fontSize: '14px' }}>No election results linked.</Typography>
                      )
                }
              </DataField>
          }
          <ManualVoteTally
            candidateId={candidateId}
            resultManual={candidate?.result?.manual}
            onSave={(res) => setCandidate(res)}
          />
      </Section>
    </Wrapper>
  )
}


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 48px;
`

const SyncContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    width: 34px;
    margin-right: 6px;
  }
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-left: 16px;
  gap: 16px;

  h3 {
    margin-left: -16px;
    margin-bottom: -8px;
    font-size: 16px;
  }

  .seperator {
    height: 1px;
    width: 100%;
    background-color: #DDDDDD;
    margin: 0px 0 0px -16px;
  }
`

export { CandidateDataFieldEditor };