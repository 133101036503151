import React, { useState } from 'react';
import { TextInput } from '../TextInput';
import styled from 'styled-components';
import { FieldInlineEdit } from '../FieldInlineEdit';
import { DataField } from '../DataField';
import Typography from '@material-ui/core/Typography';
import {useFormInputs} from "../../app/util";
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';


// This is an InputList component in which each item is viewable as a static link, by default
// however, you can also edit each component
function hostnameFromUrl(url) {
  if(!url) return undefined;
  let urlRef = url;
  if (!/^https?:\/\//i.test(url)) {
    urlRef = 'https://' + url;
  }

  return (new URL(urlRef)).hostname;
}

const StaticEditableSource = (link, i, {
  name,
  disabled,
  style,
  existingLinks,
  onSetElement,
  onDeleteElement = null , // type null or function
}) => {

  const [ editing, setEditing ] = useState(false);
  const [ formInputs, onChangeFormInputs ] = useFormInputs({
    url: link?.url || '',
    title: link?.title || ''
  });

  const dualEdit = link?.mediaType === 'other';

  let displayNameOfSource;
  if(link?.mediaType === 'website') displayNameOfSource = link?.url;
  else if(link?.mediaType === 'questionnaire') displayNameOfSource = link.title || 'Questionnaire';
  else if(link?.title) displayNameOfSource = link.title;
  else displayNameOfSource = 'Other source';

  const linkUsedNTimes = (existingLinks || []).filter(l => (
    link?.url 
    ? (
      l?.url 
      ? (link?.mediaType === 'website'
        ? (hostnameFromUrl(link?.url) === hostnameFromUrl(l?.url))
        : l?.url === link?.url)     // need exact matches for non-website links
      : false
    )
    : false
  )).length;

  if(editing) {
    return (
      <Row style={style || {}}>
        <FieldInlineEdit
          style={{ width: 'calc(100% - 20px)' }}
          onSave={() => {
            const { title, ...inputsNoTitle } = formInputs;
            onSetElement({
              target: {
                name: `${name}-${i}`,
                value: {
                  ...link,
                  ...inputsNoTitle,
                  ...(dualEdit ? { title } : {})
                }
              }
            })
            setEditing(false);
          }}
          seedValue={link?.url}
          onCancel={() => {
            setEditing(false)
            onChangeFormInputs({
              target: {
                name: 'url',
                value: link?.url
              }
            })
            onChangeFormInputs({
              target: {
                name: 'title',
                value: link?.title
              }
            })
          }}
          inputComponent={
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {
                dualEdit &&
                <TextInput
                  onChange={onChangeFormInputs}
                  value={formInputs.title}
                  name={'title'}
                  placeholder='Article Title'
                  mode='condensed'
                  style={{
                    fontSize: '16px',
                    flexGrow: 1,
                    marginRight: '6px'
                  }}
                />
              }
              <TextInput
                onChange={onChangeFormInputs}
                value={formInputs.url}
                name={'url'}
                placeholder='URL (https://example.com)'
                mode='condensed'
                style={{
                  fontSize: '16px',
                  flexGrow: 1
                }}
              />
            </div>
          }
        />

      </Row>
    );
  } else {
    return (
      <Row style={style || {}}>
        <DataWrapper>
          <DataField
            onEdit={() => setEditing(true)}
            style={{ width: 'calc(100% - 40px)', marginTop: '-4px' }}
          >
            {
              link?.url
              ? <a href={link?.url} target={'_blank'}>
                  <Typography variant={'body1'}>{displayNameOfSource}</Typography>
                </a>
              : <Typography variant={'body1'} style={{ opacity: 0.6 }}>(missing url)</Typography>
            }
          </DataField>
          {
            onDeleteElement &&
            <IconButton
              size='small'
              className={'icon-button'}
              onClick={onDeleteElement}
              style={{ marginRight: '10px', marginTop: '-12px' }}
            >
              <CancelIcon />
            </IconButton>
          }
        </DataWrapper>
        <UsedNTimes n={linkUsedNTimes} />
      </Row>
    )
  }
}

const UsedNTimes = ({ n }) => {
  return <Typography variant={'subtitle1'} style={{ opacity: 0.6, fontSize: '14px', marginTop: '-4px' }}>used {n} times</Typography>
}

export default StaticEditableSource;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0px 0 12px;
  width: calc(100%);
`

const DataWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
