import { useState, useEffect, useMemo } from 'react';
import { useFeathers } from '../util';
import { useSelector } from 'react-redux';

/*
  This hook provides the active timer for user activity.
  Parameters are optional
    seedTask (task object) - in a situation in which a task being revisited, we pass in the initial task to seed in time inputs.
    disableTimeTracker - set to true to disable
    resetTimeTracker - set to true to initiate restarting timer,
    inactivityLimitMin - set to the number of minutes to wait before the timer stops accumulating. defaults to 3
    mouseInWindow - state variable to monitor whether the mouse is in window or not to track idle time

  use as so: 
    const { timeElapsed, activeTime, isActive } = useTaskTimeTracker({
      seedTask: task,
      disableTimeTracker: false,
      resetTimeTracker: false,
    });

  It returns values of:
    timeElapsed (num) - timer in seconds correlating to user active time in site
    isActive (bool)  - whether the user is actively engaging in page
    activeTime (obj) - dictionary of users active time in research task
    idleTime (obj)   - dictionary of users' idle time in research task

  Quick overview of how this works:
    - every second the elapsed time is updated (unless the user is inactive)
    - every time the user is active (mouse or keydown), the last active time is updated
    - every 10 seconds, a "ping" is sent to check for inactivity
    - every 30 seconds, the ping patches the server task with the updated time
*/

export function useTaskTimeTracker({
  seedTask,
  disableTimeTracker,
  inactivityLimitMin = 5,
  updateServerIntervalSec = 15
}) {
  const feathers = useFeathers();
  const [ secondPassed, setSecondPassed ] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [inWindow, setInWindow] = useState(true);
  const [idleTimeElapsed, setIdleTimeElapsed] = useState(0);
  const [activeTimeElapsed, setActiveTimeElapsed] = useState(0);
  const [lastActiveTime, setLastActiveTime] = useState(Date.now());
  const [ping, setPing] = useState(0);          // an int that gets incremeneted every 10 seconds, triggers patch when it gets to 3
  const [task, setTask] = useState(seedTask);
  const [saving, setSaving] = useState(false);
  const user = useSelector((state) => state.user);
  const pingUpdateInterval = 5000;
  const userId = user._id;
  const [ inactiveDuration, setInactiveDuration ] = useState(0);
  const timeLimit = inactivityLimitMin * 60; // 5 minutes of inactivity triggers the timer to stop accumulating

  const resetTimeTracker = () => {
    setIdleTimeElapsed(0);
    setActiveTimeElapsed(0);
    setTask(null);
  }
  
  useEffect(() => {
    if(secondPassed) {
      setSecondPassed(false);
      // run your logic here
      if (isActive && !disableTimeTracker && task) {
        if (inWindow) {
          setActiveTimeElapsed((time) => time + 1);
        } else {
          setIdleTimeElapsed((time) => time + 1)
        }
      }
    }
  }, [ secondPassed, isActive, disableTimeTracker, task, inWindow ])

  // set task when it becomes available
  useEffect(() => {
    if (disableTimeTracker) return;

    const seedTimes = (timer, stateUpdate) => {
      if (seedTask) {
        //update timeElapsed to whatever is original task once it is seeded
        if (seedTask?.[timer]) {
          if (seedTask?.[timer][userId])
            stateUpdate(seedTask[timer][userId]);
          else 
            stateUpdate(0);
        } else {
          stateUpdate(0);
        }
      } else {
        stateUpdate(0);
      }
    }
    seedTimes('activeTime', setActiveTimeElapsed)
    seedTimes('idleTime', setIdleTimeElapsed)

    setTask(seedTask);
  }, [seedTask, userId, disableTimeTracker]);

  const patchUpdatedActivity = async () => {
    setPing(0);
    if (!task) return;
    if (disableTimeTracker) return;

    if (saving) return;

    let activeTime = task?.activeTime || task?.trackedTimes;
    let idleTime = task?.idleTime;

    const initiateTimeDict = (dict, seedTime) => {
      if (!dict) {
        // if dict is not on task create one
        dict = { [userId]: seedTime };  // initiate time dict
      } else if (!dict[userId]) {
        // if user is not on trackedTimes obj add them and their time elapsed
        dict[userId] = seedTime;        // add user to time dict
      } else {
        // if user is on the dictionary and their time is different update
        if (dict[userId] !== seedTime) {
          dict[userId] = seedTime;      // update time dict with new time
        } else {
          return dict;                       // return if no new changes
        }
      }
      return dict
    }

    activeTime = initiateTimeDict(activeTime, activeTimeElapsed);
    idleTime = initiateTimeDict(idleTime, idleTimeElapsed);

    setTask(t => ({
      ...t,
      activeTime,
      idleTime
    }));

    try {
      const res = await feathers
        .getService('research-tasks')
        .patch(task._id, {
          activeTime,
          idleTime
        });
      
      setSaving(false);
      console.log(`Task time updated successfully`, res) 

    } catch (e) {
      setSaving(false);
      return;
    }
  };

  // handles event listeners activity maintains / starts timer while ping checks for inactiveDuration
  const handleActivity = (e) => {
    switch (e.type) {
      case 'mousemove':
      case 'mousedown':
      case 'touchstart':
      case 'keydown':
      case 'focus':
        setIsActive(true);
        setSaving(false);
        setLastActiveTime(Date.now());
        setInactiveDuration(0);
        if(e.type !== 'focus') {
          setInWindow(true)
        }
        break;
      case 'visibilitychange':
        if (window.document.hidden) {
          setInWindow(false)
        } 
        break
      case 'ping':
        setInactiveDuration((e.timestamp - e.lastActiveTime) / 1000);
        break;
      case 'beforeunload':
        // send patch request if window is closed
        setStageBeforeUnload(true);
        break;
      case 'mouseout':
        const isOutsideOfWindow = e.clientY <= 0 || e.clientX <= 0 || (e.clientX >= window.innerWidth || e.clientY >= window.innerHeight);
        if(isOutsideOfWindow) {
          setInWindow(false)
        }
        break;
      default:
        console.log('doing something on the default', e.type)
        // setInactiveDuration((Date.now() - lastActiveTime) / 1000);
        break
    }
  };

  const [ stageBeforeUnload, setStageBeforeUnload ] = useState(false);
  useEffect(() => {
    if(stageBeforeUnload) {
      setStageBeforeUnload(false);
      patchUpdatedActivity();
    }
  }, [ stageBeforeUnload, task, disableTimeTracker ])

  useEffect(() => {
    if (inactiveDuration > timeLimit) {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
  }, [inactiveDuration, timeLimit]);

  // set keep alive ping to keep the timer going while screen is idle and pause after inactive duration is met
  // and have a ping threshold to adjust when to send patch request
  const pingThreshold = (updateServerIntervalSec * 1000) / pingUpdateInterval;

  const [stagePingActivity, setStagePingActivity] = useState(false);
  useEffect(() => {
    if(stagePingActivity) {
      setStagePingActivity(false) 
      handleActivity({
        type: 'ping',
        timestamp: Date.now(),
        lastActiveTime,
      });
    }
  }, [ lastActiveTime, stagePingActivity ])

  useEffect(() => {
    // this use effect gets called every pingUpdateInterval;
    if (disableTimeTracker || !task) return;
    if(ping === 0) return;
    setStagePingActivity(true);

    if (ping > pingThreshold) {
      // pings occur on 10sec interval so attempt patch after 30secs
      setPing(0)
      patchUpdatedActivity();
    }
  }, [ ping, disableTimeTracker, task ]);

  useEffect(() => {
    if(disableTimeTracker) return;
    
    const pingInterval = setInterval(() => {
      setPing((ping) => ping + 1);
    }, pingUpdateInterval);

    return () => {
      clearInterval(pingInterval);
    }
  }, [])


  useEffect(() => {
    if(disableTimeTracker) return;
    const interval = setInterval(() => {
      setSecondPassed(true);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [])

  useEffect(() => {
    if(disableTimeTracker) return;
    // activity event to handle within handle activity
    const activityEvents = [
      'mousemove',
      'keydown',
      'mousedown',
      'touchstart',
      'blur',
      'focus',
      'visibilitychange',
      'beforeunload',
      'mouseout'
    ];

    activityEvents.forEach((event) => {
      window.addEventListener(event, handleActivity);
    });

    // document.body.addEventListener("mouseleave", function(event){
    //   if(event.clientY <= 0 || event.clientX <= 0 || (event.clientX >= window.innerWidth || event.clientY >= window.innerHeight))
    //   {
    
    //      console.log("I'm out");
    
    //   }
    // });

    return () => {
      activityEvents.forEach((event) => {
        window.removeEventListener(event, handleActivity);
      });
    };
  }, []);

  const timeElapsed = useMemo(() => {
    return activeTimeElapsed + idleTimeElapsed;
  }, [activeTimeElapsed, idleTimeElapsed]);

  return { timeElapsed, isActive, resetTimeTracker, activeTime: activeTimeElapsed, idleTime: idleTimeElapsed };
}
