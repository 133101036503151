import React from 'react';
import { ReactComponent as CheckBoxSelected } from './checkbox-selected.svg';
import styled from 'styled-components';

const Checkbox = ({
  checked,
  disabled,
  midChecked,
  onClick
}) => {
  const onClickMod = onClick && !disabled ? onClick : null;
  if(checked) {
    return (<Checked onClick={onClickMod}/>)
  } else if(midChecked) {
    return (<Midchecked onClick={onClickMod}><div className={'inner'}/> </Midchecked>)
  } else {
    return (<Unchecked onClick={onClickMod} className='check-box'/>)
  }
}

const Unchecked = styled.div`
  height: 20px;
  width: 20px;
  min-height: 20px;
  min-width: 20px;
  border-radius: 2px;
  border: 1px solid #D8D8D8;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  background-color: #FFFFFF;
`;

const Midchecked = styled.div`
  height: 20px;
  width: 20px;
  min-height: 20px;
  min-width: 20px;
  border-radius: 2px;
  border: 1px solid #D8D8D8;
  background-color: #453DB7;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  .inner {
    background-color: #FFFFFF;
    width: 60%;
    height: 3px;
  }
`;

const Checked = styled(CheckBoxSelected)`
  height: 22px;
  width: 22px;
  min-height: 22px;
  min-width: 22px;
  border-radius: 2px;
  cursor: pointer;
`;

export { Checkbox };
