import React from 'react';
import { TextInput } from '../TextInput';
import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin: 6px 0 6px;
  width: 100%;
`

export default function CandidateLink(link, i, props) {
  const { name, onChange, disabled } = props;
  return (
    <Row>
      <TextInput
        disabled={disabled}
        name={`url-${i}`}
        value={link.url}
        onChange={onChange}
        placeholder='http://www.facebook.com/janeforga'
        mode='condensed'
        style={{
          fontSize: '16px',
          flexGrow: 1
        }}
      />
    </Row>
  );
}
