import React, { useEffect } from 'react';
import styled from 'styled-components';
import * as d3 from 'd3';
import moment from 'moment';

/* data should be formatted like: 
  [
    { category: 'A', segments: [{ value: 30, color: 'red' }, { value: 70, color: 'blue' }] },
    { category: 'B', segments: [{ value: 50, color: 'green' }, { value: 50, color: 'yellow' }] },
    { category: 'C', segments: [{ value: 20, color: 'purple' }, { value: 80, color: 'orange' }] }
  ];
*/
const BarGraph = ({
  data: dataGiven, 
  width: widthGiven,  // defaults to 960
  height: heightGiven,  // defaults to 500
  units = 'profiles',
  className,
  style
}) => {

  const createGraph = async () => {
    // read data from csv and format variables
    let data = dataGiven.slice();

    // set the dimensions and margins of the graph
    var margin = { top: 10, right: 40, bottom: 20, left: 25 },
    width = (widthGiven ?? 960) - margin.left - margin.right,
    height = (heightGiven ?? 500) - margin.top - margin.bottom;

    // append the svg object to the body of the page
    var svg = d3.select("#bargraph-viz").append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    const maxValue = d3.max(data, d => d.segments.reduce((acc, segment) => acc + segment.value, 0));

    // Create scales
    const x = d3.scaleBand()
      .domain(data.map(d => d.category))
      .range([0, width])
      .padding(0.4);
    
    const y = d3.scaleLinear()
      .domain([0, maxValue])
      .nice()
      .range([height, 0]);
    
    // Add axes
    svg.append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(x));
    
    svg.append("g")
      .attr("class", "y-axis")
      .call(d3.axisLeft(y));

    // const tooltip = d3.select("#bargraph-tooltip");
    const tooltip = d3.select("body").append("div")   
      .attr("style", `position: fixed;
      display: none;
      background-color: white;
      border: 1px solid black;
      padding: 5px;
      pointer-events: none;`)               
    
    // Render bars
    data.forEach(d => {
      let cumulative = 0;
      const totalValue = d.segments.reduce((acc, segment) => acc + segment.value, 0);
      d.segments.forEach(segment => {
        svg.append("rect")
          .attr("x", x(d.category))
          .attr("y", y(cumulative + segment.value))
          .attr("height", y(cumulative) - y(cumulative + segment.value))
          .attr("width", x.bandwidth())
          .attr("fill", segment.color)
          .on("mouseover", function(event) {
            tooltip
              .html(`<div style="display: flex; flex-direction: column; gap: 4px;">
                <span><b>${d.category}</b></span>
                ${d.segments.map(segment => `<span><b>${segment.label}</b>: ${segment.value} ${units}</span>`).join('')}
                <span><b>Total</b>: ${totalValue} ${units}</span>
              </div>`)
              .style("display", "block")
              .style("left", event.pageX  + "px")
              .style("top", event.pageY + "px");
          })
          .on("mousemove", (event) => {
            tooltip
              .style("left", event.pageX  + "px")
              .style("top", event.pageY + "px");
          })
          .on("mouseout", () => {
            tooltip.style("display", "none");
          });
        cumulative += segment.value;
      });
    });
  }
  
  useEffect(() => {
    if(dataGiven.length > 0 ) createGraph();
  }, [ dataGiven ]);

  return (
    <Wrapper className={className} style={{ ...style, height: `${heightGiven ?? 500}px`, width: `${widthGiven ?? 960}px` }}>
      <div id="bargraph-viz" style={style}></div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  #bargraph-tooltip {
    position: fixed;
    display: none;
    background-color: white;
    border: 1px solid black;
    padding: 5px;
    pointer-events: none;
  }
`;

export { BarGraph };