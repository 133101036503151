import React from 'react';
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography';

const ColorTag = ({
  children,
  themeColor = 'blue',        // red, blue, green, yellow, black
  style = {},
  className,
  onClick
}) => {

  let colorStyles;
  if(themeColor === 'red') colorStyles = { color: '#972922', backgroundColor: '#FF9A9A' }
  else if(themeColor === 'blue') colorStyles = { color: '#453DB7', backgroundColor: '#A9C9FF' }
  else if(themeColor === 'green') colorStyles = { color: '#0A584C', backgroundColor: '#99d266' }
  else if(themeColor === 'yellow') colorStyles = { color: '#7A733C', backgroundColor: '#F2F4C4' }
  else colorStyles = { color: '#333333', backgroundColor: '#EEEEEE' }

  return (
    <Wrapper
      style={{ backgroundColor: colorStyles.backgroundColor, ...style }}
      className={className}
      {...(onClick ? { onClick } : {})}
      clickable={onClick ? 1 : 0}
    >
      <Typography variant='body1' style={{
          color: colorStyles.color,
          fontSize: '12px',
          letterSpacing: '1px',
          fontWeight: 'bold',
      }}>
        {children}
      </Typography>
    </Wrapper>);
}

const Wrapper = styled.div`
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: bold;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 5px 6px 2px;
  ${props => props.clickable ? `cursor: pointer;` : ``}

`

export { ColorTag };
