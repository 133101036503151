import React from 'react';
import styled from 'styled-components';
import Level1Badge from './BranchLogo_DarkPurple-tier-1.png';
import Level2Badge from './BranchLogo_DarkPurple-tier-2.png';
import Level3Badge from './BranchLogo_DarkPurple-tier-3.png';
import Level4Badge from './BranchLogo_DarkPurple-tier-4.png';

const ResearcherLevelBadge = ({ level, style = {}, className = '' }) => {
  // level must be a number between 1 and 4
	let badge;
	if(level === 1) badge = Level1Badge;
	else if(level === 2) badge = Level2Badge;
	else if(level === 3) badge = Level3Badge;
	else if(level === 4) badge = Level4Badge;
	else return null;

	return <Badge src={badge} style={style} className={className} />

}

const Badge = styled.img`
	width: 40px;
	height: 40px;
	object-fit: contain;
`

export { ResearcherLevelBadge };