import React from 'react';
import styles from './LoadingSpinner.css';

const LoadingSpinner = ({
  width = 80,
  style={}
}) => {
  const scale = width/80;
  const translate = (width - 80 ) / 2;
  return (
    <div style={{
      width: `${width}px`,
      height: `${width}px`
    }}>
      <div
        className="lds-roller"
        style={{
          transform: `translate(${translate}px, ${translate}px) scale(${scale})`,
          ...style
        }}>
        <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
      </div>
    </div>
  );
}

export { LoadingSpinner };
