import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

const colors = [
  '#ADF9EC',
  '#94ACF9',
  '#453DB7',
]

const BarPieGraph = ({
  style,
  className,
  segments = [],             // objects with format { name, percentage (as a whole number out of 100), color (optional), subText (optional) }
}) => {
  const segmentsMapped = (segments || []).map(s => ({
    ...s,
    percentage: Math.round(s?.percentage * 100)/100         //round to 2 decimal spots
  }))
  const otherPerc = 100 - segmentsMapped.reduce((total, seg) => seg.percentage + total);

  const segmentsTotal = otherPerc > 0.2
    ? [].concat(segmentsMapped, [{
        name: 'other sources',
        color: '#aaaaaa',
        percentage: otherPerc
      }])
    : segmentsMapped.slice();

  return (
    <Wrapper style={style} className={className}>
      <Bar>
        {segmentsTotal.map((s, i) => {
          if(s?.percentage > 0) {
            return (
              <Segment
                key={i}
                style={{
                  width: `${s.percentage}%`,
                  backgroundColor: s.color || colors[i % colors.length]

                }}
              />
            )
          } else {
            return null;
          }
        })}
      </Bar>
      {segmentsTotal.map((s, i) => (
        <Source key={i}>
          <Swatch
            style={{
              backgroundColor: s.color || colors[i % colors.length]
            }}
          />
          <div className='descriptor'>
            <Typography variant='body1' className='descriptor-main'>
              {s.name}
            </Typography>
            {
              s.subText &&
              <Typography variant='body1' className='descriptor-subtext'>
                {s.subText}
              </Typography>
            }
          </div>
          <Typography variant='body1' className='percentage'>
            {s.percentage}%
          </Typography>
        </Source>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Bar = styled.div`
  width: 100%;
  height: 18px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  overflow: hidden;
`

const Segment = styled.div`
  height: 100%;
`
const Source = styled.div`
  width: 100%;
  margin-top: 14px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .descriptor {
    flex: 1;

    .descriptor-main {
      font-weight: bold;
      line-height: 1.1;
      font-size: 15px;
    }

    .descriptor-subtext {
      line-height: 1.0;
      font-size: 12px;
      margin-top: 2px;
    }
  }

  .percentage {
    white-space: nowrap;
    margin-left: 12px;
    font-size: 15px;
  }
`

const Swatch = styled.div`
  height: 14px;
  width: 14px;
  border-radius: 2px;
  margin-right: 12px;
  margin-bottom: 2px;
`

export { BarPieGraph };
