import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CloseSVG } from './close_small.svg';
import { Link } from 'react-router-dom';

const CloseButton = ({
  onClick,
  style
}) => {
  if(onClick && typeof(onClick) === 'object' && onClick.to) {
    return (
      <Link to={onClick.to} style={style}>
        <Button style={{ width: style?.width, height: style?.height }}/>
      </Link>
    )
  } else {
    return (
      <Button style={style} onClick={onClick}/>
    )
  }

}

const Button = styled(CloseSVG)`
  cursor: pointer;
  height: 24px;
  width: auto;
  border-radius: 16px;

  &:hover {
    background-color: #f8f8f8;
  }
`;

export { CloseButton };
