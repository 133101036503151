import { useState, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { useFeathers } from '../util'

export function useCandidateTimeTracker(candidateId, role = 'researcher') {
  const { loggedIn } = useSelector((state) => state.user)
  const [startTime, setStartTime] = useState(null)
  const feathers = useFeathers()

  const startEditTracker = useCallback(() => {
    if (!loggedIn) return
    setStartTime(moment.utc())
  }, [loggedIn])

  const endEditTracker = useCallback(async () => {
    if (!startTime || !loggedIn || !candidateId) return
    const endTime = moment.utc()
    const trackObj = {
      candidate: candidateId,
      role,
      trackStart: startTime.toISOString(),
      trackEnd: endTime.toISOString(),
    }
    try {
      const time = await feathers.getService('time-tracker/edit-candidates').create(trackObj)
      console.log('Tracked time for edits', time)
    } catch (err) {
      console.error('Error on saving tracking:', err)
    } finally {
      setStartTime(null)
    }
  }, [startTime, loggedIn, candidateId, feathers, setStartTime, role])

  useEffect(() => {
    return () => {
      setStartTime(null)
    }
  }, [candidateId])

  return { startEditTracker, endEditTracker }
}
