import React from 'react';
import styled from 'styled-components';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import { onClickOrLink } from '../../app/util';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const DataField = ({
  style,
  children,
  title,
  onEdit = null, // type function or null
  onClick,
  ...propsRest
}) => {
  const dataTip = propsRest['data-tip']
  return (
    <Wrapper style={style} data-tip={dataTip}>
      <DataFieldStyled editable={(onEdit && !onClick) ? 1 : 0} onClick={(onEdit && !onClick) ? onEdit : undefined}>
        <TitleWrapper>
          <TitleText>{title}</TitleText>
          {
            (onEdit) &&
            <IconButton
              size='small'
              className={'icon-button'}
              onClick={onEdit}
              style={{
                position: 'absolute',
                left: '100%',
                top: '-6px',
              }}
            >
              <EditIcon style={{ width: '18px', height: '18px' }}/>
            </IconButton>
          }
        </TitleWrapper>
        <Main {...(onClick ? onClickOrLink(onClick) : {})}>
          <MainInner clickable={onClick ? 1 : 0}>
            {children}
          </MainInner>
          {
            onClick && false &&
            <OpenInNewIcon color='secondary' />
          }
        </Main>
      </DataFieldStyled>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
const DataFieldStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  width: 100%;

  ${props => props.editable
    ? `cursor: pointer;
      div > .icon-button {
        visibility: hidden;
      }
      &:hover > div > .icon-button {
        visibility: visible;
      }
    `
    : ''
  }
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 4px;
`

const TitleText = styled.div`
  opacity: 0.7;
  font-size: 14px;
  ${props => props.theme.font.normal}
`

const Main = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  svg {
    margin-left: 4px;
  }
`;

const MainInner = styled.div`
  font-size: 20px;
  ${props => props.theme.font.bold}
  color: ${props => props.clickable ? props.theme.colors.indigo : props.theme.colors.darkPurple};
  ${props => props.clickable ? `text-decoration: underline;` : ``}
`

export { DataField };
