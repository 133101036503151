import React from 'react';
import styles from './CandidatePhoto.css';
import CandidateNoImage from './candidate-icon.png';
import FileUpload from '../FileUpload';

const CandidatePhoto = ({
  editable = false,
  src,
  className,
  style,
  onChange
}) => {
  const attlClasses = (editable) ? ` cd-photo editable` : ` cd-photo`;

  let imgSrc;
  if(src) {
    imgSrc = src.includes('https') ? src : `https://branch-production-bucket.s3.amazonaws.com/images/candidates/${src}`
  }
  return (
    <div className={(className || '') + attlClasses} style={style}>
      <img src={imgSrc || CandidateNoImage}/>
      {
        editable &&
        <div className='upload-wrapper'>
          <FileUpload fileType='image' onUploadSuccess={onChange}/>
        </div>
      }
    </div>
  );
}

export { CandidatePhoto };
