import React, { useRef, useEffect, forwardRef } from 'react';
import styled from 'styled-components';

const TextInput = forwardRef( function TextInput({
  hasError,
  errorMessage,
  name,
  disabled = false,
  value,
  onChange = () => {},
  placeholder,
  type,
  style,
  mode = 'full',           // `full` takes up more space, allows for extra padding, and fills the container
                          // `condensed` takes up less space
  onLayout = () => {},
  onFocus = () => {},
  onBlur = () => {},
  onKeyDown = () => {},

}, refContainer = null) {

  const height = refContainer?.current?.clientHeight;
  const width = refContainer?.current?.clientWidth;
  useEffect(() => {
    if(onLayout) {
      onLayout({ height, width })
    }
  }, [ height, width ])

  const onKeyDownInner = (e) => {
    if(e.keyCode === 13){
      // pressed enter key
      if(false) { // if multiline {

      } else {
        e.target.blur(); 
      }
    }

    if(onKeyDown) onKeyDown(e);
 }

  return (
    <Wrapper>
      <Text
        error={hasError}
        disabled={disabled}
        type={type || 'text'}
        name={name}
        value={value}
        placeholder={placeholder || ''}
        onChange={onChange}
        style={style}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        mode={mode}
        ref={refContainer}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={onKeyDownInner}
      />
      {hasError && errorMessage && <ErrorLabel>{errorMessage}</ErrorLabel>}
    </Wrapper>
  )
})

const Text = styled.input`
  -webkit-appearance: none;
  appearance: none;

  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  border: 1px solid #EBEBEB;
  color: rgb(0, 0, 0);
  width: ${({ mode }) => mode === 'full' ? `calc(100% - 14px - 14px)` : `250px`};
  font-family: Houschka;
  font-size: ${({ mode }) => mode === 'full' ? `16pt` : `14pt`};
  padding: ${({ mode }) => mode === 'full' ? `14px` : `6px 14px 6px`};
  margin: ${({ mode }) => mode === 'full' ? `8px 0` : `0`};
  text-align: start;
  ${ props => props.error ? `border: 1px solid #F71735;` : ``}

  &:disabled {
    background-color: #eeeeee;
    cursor: none;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ErrorLabel = styled.label`
  color: red;
`

export { TextInput };
