import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Arrow } from './Arrow.svg';
import { onClickOrLink } from '../../app/util';

const BackButton = ({
  onClick,
  style
}) => {
  return (
    <Button {...onClickOrLink(onClick)}>
      <Arrow />
    </Button>
  );
}

const Button = styled.div`
  padding: 4px 12px 0px;
  background-color: #FFFFFF;
  border: solid 1px #EEEEEE;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: #F6F6F6;
  }
`

export { BackButton };
