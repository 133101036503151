import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

const StyledMarkdown = ({
  style,
  className,
  children
}) => {
  return (
    <TextWrapper style={style} className={className}>
      <ReactMarkdown children={children}/>
    </TextWrapper>
  )
}

const TextWrapper = styled.div`
  ul, ol, p, li, span {
    font-size: 14px;
    color: ${props => props.theme.palette.textPrimary};
  }
  ul, ol {
    margin: 0;
    padding: 0 0 0 16px;
  }

  li {
    margin: 0 0 4px;
    padding: 0;
  }

  p {
    margin: 0 0 4px;
  }
`

export { StyledMarkdown };
