import React from 'react';
import styled from 'styled-components';
import InputList from '../InputList';
import { IssueSelectInput } from '../IssueSelectInput';

/* this is an input component for editing the impact issues of an office or race.
*  this is built on top of the InputList component */

const IssuesToCoverInput = ({
  onChange,
  disabled,
  fieldName = 'text',
  value = []                // expected to be an array of objects, each with a issue & how
}) => {

  return (
    <Wrapper>
      <InputList
        enableReorder={true}
        disabled={disabled}
        name='issuesToCover'
        addText={'Add issue'}
        onChange={onChange}
        value={value}
        fieldName={fieldName}
        renderItem={IssueToCover}
        emptyItem={{ name: '' }}
      />
    </Wrapper>
  )
}

const IssueToCover = (value, i, props) => {
  const { name, onChange, disabled } = props;

  const valueMapped = value.issue?.key ? { ...value.issue, text: value.issue.name } : undefined;
  return (
    <Wrapper>
      <div style={{ width: '300px', marginBottom: '6px' }}>
        <IssueSelectInput
          candidateIssue={true}
          disabled={disabled}
          name={`issue-${i}`}
          value={valueMapped}
          onChange={onChange}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 12px 0 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

export { IssuesToCoverInput };
