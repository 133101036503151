import React from 'react';
import styles from './SelectorInput.css';
import {Checkbox} from '../Checkbox';

class SelectorInput extends React.Component {
  render() {
    return (
      <div className='input-selector' style={this.props.style} onClick={(e) => {
        const { name, value } = this.props;
        const target = { name, value };
        e.target = target;
        this.props.onClick(e)
      }}>
        <div className='input-content-wrapper'>
          {this.props.children}
        </div>
        <Checkbox checked={this.props.selected} />
      </div>
    )
  }
}

export { SelectorInput };
