import React, { useState } from 'react';

import Text from '@material-ui/core/Typography';
import styled, { useTheme } from 'styled-components';

import ChevronDown from '@material-ui/icons/KeyboardArrowDown';
import ChevronUp from '@material-ui/icons/KeyboardArrowUp';
import { IconForIssue } from '../IconForIssue';

export const ImpactIssues = ({
  mode = 'desktop',
  issues = [],
  style={}
}) => {
  const [ openIssueName, setOpenIssueName ] = useState(null);
  const theme = useTheme();

  if(mode === 'mobile') {
    return (
      <Wrapper style={style}>
        {
          issues.map((issue, i) => {
            const issueOpen = issue.name === openIssueName;
            return (
              <ImpactIssueRow key={i} onClick={issueOpen ? () => setOpenIssueName(null) : () => setOpenIssueName(issue.name)}>
                <div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IssueSVG style={{ color: theme.palette.accent }}/>
                    <Text variant='h4' color='primary'>{issue.name}</Text>
                  </div>
                  {
                    issueOpen
                    ? <ChevronUp color='secondary' />
                    : <ChevronDown color='secondary' />
                  }
                </div>
                {
                  issueOpen &&
                  <Text variant='body1' color='primary' style={{ marginBottom: '8px' }}>{issue.how}</Text>
                }
              </ImpactIssueRow>
            );
          })
        }
      </Wrapper>
    )
  } else {
    return (
      <Wrapper style={style}>
        {
          issues.map((issue, i) => (
            <Row style={{ marginTop: '16px' }} key={i}>
              <IconForIssue issue={issue.name} color={theme.palette.accent} style={{
                width: '100%',
                maxWidth: '20px',
                maxHeight: '20px',
                marginRight: '8px',
              }}/>
              <div>
                <Text variant='body1' color='secondary' style={{ fontSize: '15px' }}>
                  {issue.name}
                </Text>
                <Text variant='body1' color='primary' style={{ fontSize: '15px', marginTop: '4px' }}>
                  {issue.how}
                </Text>
              </div>
            </Row>
          ))
        }
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

const ImpactIssueRow = styled.div`
  border-top: solid 1px #c4c4c4;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0 8px;
  }

  div > svg {
    max-width: 24px;
  }
`

const IssueSVG = styled.div`

`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: no-wrap;
`
