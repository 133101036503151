import React from 'react';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { CandidateProgressBar } from '../ProgressBar';

const DraftPreview = ({
    draft,
    isSelected,
    onClick,
    showProgressBar = true
}) => {
    return (
        <div onClick={onClick} style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer', borderBottom: 'solid 1px #EEEEEE', padding: '24px 12px', gap: '8px', ...(isSelected ? { backgroundColor: '#AAAAFF18' } : {}) }}>
            <Typography variant={'body1'} style={{ fontWeight: 'bold'}}><b>{`Version ${draft.version}`}</b>, by {draft.user?.name || draft.user?.firstName || draft.user?.email}</Typography>
            {
                showProgressBar &&
                <CandidateProgressBar
                    candidate={draft}
                    percentage={draft.progress*100}
                    style={{ width: '300px', margin: '6px 0 6px' }}
                    backgroundColor='#e0e0e0'
                    color='#99D266'
                />
            }
            <Typography variant={'body2'} style={{ opacity: 0.7 }}>{moment(draft.createdAt).format('MMMM D')} at {moment(draft.createdAt).format('HH:mm:ssa')}</Typography>
        </div>
    );
}

export { DraftPreview };