import React from 'react';
import styled from 'styled-components';
import ChevronDoubleLeft from './chevron-double-left.png';
import ChevronDoubleRight from './chevron-double-right.png';
import ChevronLeft from './chevron-left.png';
import ChevronRight from './chevron-right.png';

const PaginationControl = ({
  activePage = 1,
  items = 0,
  itemsPerPage = 10,
  onChange
}) => {
  const numberOfPages = Math.ceil(items / itemsPerPage);
  const jumpToEndLeft = activePage > 2;
  const jumpLeft = activePage > 1;
  const jumpToEndRight = activePage < numberOfPages - 1;
  const jumpRight = activePage < numberOfPages;

  return (
    <Wrapper>
      {jumpToEndLeft && <LinkImage as='img' onClick={() => onChange(1)} src={ChevronDoubleLeft}/> }
      {jumpLeft && <Link onClick={() => onChange(activePage-1)}>{activePage - 1}</Link> }
      <Item>{activePage}</Item>
      {jumpRight && <Link onClick={() => onChange(activePage+1)}>{activePage + 1}</Link> }
      {jumpToEndRight && <LinkImage as='img' onClick={() => onChange(numberOfPages)} src={ChevronDoubleRight}/> }
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Item = styled.span`
  color: #453DB7;
  margin: 0 8px 0;
  font-size: 18px;
`;

const Link = styled(Item)`
  &:hover {
    cursor: pointer;
    border-bottom: 1px solid #453DB7;
  }
`

const LinkImage = styled(Link)`
  height: 10px;
  padding-bottom: 4px;
`;



export { PaginationControl };
