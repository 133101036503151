import React from 'react';
import styled from 'styled-components';
import { ChevronRightButton } from '../ChevronRightButton';
import { Link } from 'react-router-dom';
import { onClickOrLink } from '../../../app/util';

/* A generic white tile wrapper component */

const Tile = ({
  onClick,
  children,
  style,
  className,
  chevronEnabled = true,
  shadowEnabled = false
}) => {
  return (
    <Wrapper as={onClick ? Link : 'div'}
      style={style}
      className={className}
      {...(
        Boolean(onClick) ? onClickOrLink(onClick) : {}
      )}
      shadowenabled={shadowEnabled ? 1 : 0}
      clickable={Boolean(onClick) ? 1 : 0}
    >
      <Inner>
        {children}
      </Inner>
      {
        chevronEnabled &&
        <ChevronRightButton style={{ alignSelf: 'center' }}/>
      }
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding: 12px;
  border: 1px solid #EEEEEE;
  border-radius: 8px;
  text-decoration: none;
  ${props => props.shadowenabled ? `box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);` : ''}

  ${props => props.clickable
  ? `
    &:hover {
      background-color: #F6F6F6;
      cursor: pointer;
    }
    ` : ``
  }
`;

const Inner = styled.div`
  flex: 1;
  margin-right: 8px;
`;

export { Tile };
