import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import useKeypress from 'react-use-keypress';
import MapIcon from '@material-ui/icons/Map';
import { DistrictSelectInput } from '../DistrictSelectInput';
import { Chip } from '@material-ui/core';

const DistrictFilter = ({
	onChange,				// accepts the district object with properties (text and _id)
	value,					// expects back the same object given by onChange
	withinState,
	allowTypes = ['city', 'county']
}) => {
  const [ selecting, setSelecting ] = useState(false);

	useKeypress('Escape', () => {
		setSelecting(false)
	})

	if(value) {
		return <Chip
			style={{ color: '#FFFFFF' }}
			color='primary'
			label={<Typography style={{ color: '#FFFFFF', fontWeight: 'bold' }}>{value?.text}</Typography>}
			onDelete={() => onChange(null)}
		/>
	} else if(selecting) {
		return (
			<DistrictSelectInput
				onChange={(e) => {
					if(onChange) onChange(e?.target?.value)
					setSelecting(false)
				}}
				descendantOf={withinState}
				districtTypes={allowTypes}
			/>
		);
	} else {
		return (
			<Button startIcon={<MapIcon />} onClick={() => setSelecting(true)} color='primary'>
				District
			</Button>
		);
	}
}

export { DistrictFilter };