import React, { useState, useMemo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useFeathers, isPermitted } from '../../app/util';
import {
  DataField,
  FieldInlineEdit,
  LoadingSpinner,
  DistrictSelectInput,
  PartySelect,
  UpdateRaceElection
} from '../index';
import InputList from '../InputList';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { useSelector } from 'react-redux';
import { ReactComponent as WarningIcon } from '../../images/yellow-warning.svg';
import { ReactComponent as GreenCheck } from '../../images/green-check.svg';
import { Tooltip, Typography } from '@material-ui/core';
import styled from 'styled-components';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
// info icon

const permissionsOrdered = ['researcher', 'reviewer', 'publisher', 'editor', 'admin', 'super-admin'];
const RaceDataFieldEditor = ({
  raceId,
  onError,
  style,
  className
}) => {
  const feathers = useFeathers();
  const user = useSelector(state => state.user);
  const elections = useSelector(state => state.elections.byKey);
  const [ raceData, setRaceData ] = useState(null);
  const [ error, setError ] = useState(null);

  const electionKey = raceData?.election?.key || raceData?.election;
  const electionData = electionKey ? elections[electionKey] : null;
  const electionOver = electionData?.status === 'results-certified';

  const descendantOf = useMemo(
    () => electionData?.districts?.map((d) => d._id),
    [electionData]
  )

  const [ saving, setSaving ] = useState(false);
  const [ loading, setLoading ] = useState(true);


  const [ editingDistrict, setEditingDistrict ] = useState(false);
  const [ newDistrictInput, setNewDistrictInput ] = useState(null);
  
  const [ editingElection, setEditingElection ] = useState(false)
  const [ newAdditionalDistrictsInput, setNewAdditionalDistrictsInput ] = useState(null);
  const [ editingAdditionalDistricts, setEditingAdditionalDistricts ] = useState(false);

  const [ entityMatchInputs, setEntityMatchInputs ] = useState([]);
  const [ editingEntityMatchInputs, setEditingEntityMatchInputs ] = useState(false);
  const saveEntityMatchInputs = async () => {
    try {
      const updateRes = await feathers.getService('races').patch(raceId, {
        entityMatchInputs: entityMatchInputs,
      })
      setRaceData(updateRes);
      setEditingEntityMatchInputs(false)
      loadDataForRace()
    } catch(err) {
      console.log(err)
      setError(err)
    }
  }

  const appearsOnBallotAs = useMemo(() => {
    if (!raceData) return ''
    const { entityMatchInputs } = raceData;
    if (!entityMatchInputs?.length) {
      return ''
    } else if (entityMatchInputs.length === 1) {
      return `${entityMatchInputs[0]}`
    } else {
      return entityMatchInputs.slice(0, -1).join(', ') + ' or ' + entityMatchInputs.slice(-1)
    }
  }, [raceData])

  const onChangeDistrict = (e) => setNewDistrictInput(e.target.value)
  const onChangeAdditionalDistricts = e => setNewAdditionalDistrictsInput(e.target.value)
  const saveDistrict = async () => {
    // only save the inputs being edited
    const requestBody = {}
    if (newDistrictInput && newDistrictInput._id) {
      requestBody.district = newDistrictInput._id
      cancelDistrict()
    } else if (newAdditionalDistrictsInput) {
      requestBody.additionalDistricts = newAdditionalDistrictsInput.map(d => d._id)
      cancelAdditionalDistricts()
    } else {
      return
    }

    try {
      const updateRes = await feathers.getService('races').patch(raceId, requestBody)
      setRaceData(updateRes);
      setEditingDistrict(false)
      setEditingAdditionalDistricts(false)
      loadDataForRace()
    } catch(err) {
      console.log(err)
      setError(err)
    }
  }

  const cancelDistrict = () => {
    setEditingDistrict(false)
    setNewDistrictInput(null)
  }
  const cancelAdditionalDistricts = () => {
    setEditingAdditionalDistricts(false)
    setNewAdditionalDistrictsInput(null)
  }

  const [ editingRetention, setEditingRetention ] = useState(false);
  const [ retentionActive, setRetentionActive ] = useState(raceData?.retention)
  const saveRetention = async () => {
    try {
      const updateRes = await feathers.getService('races').patch(raceId, {
        retention: retentionActive
      })
      setRaceData(updateRes);
      setEditingRetention(false)
      loadDataForRace()
    } catch(err) {
      console.log(err)
      setError(err)
    }
  }


  const [ editingParty, setEditingParty ] = useState(false);
  const [ partyActive, setPartyActive ] = useState(raceData?.party)

  const saveParty = async () => {
    let $suppress409 = false;
    try {
      const updateRes = await feathers.getService('races').patch(raceId, {
        party: partyActive
      })
      setRaceData(updateRes);
      setEditingParty(false)
      loadDataForRace()
      return;
    } catch(err) {
      console.log(err.code)
      if(err.code === 409) {
        $suppress409 = true;
      } else {
        setError(err)
      }
    }

    if($suppress409) {
      // try again with $suppress409
      try {
        const updateRes = await feathers.getService('races').patch(raceId, {
          party: partyActive,
          $suppress409: true
        })
        setRaceData(updateRes);
        setEditingParty(false)
        loadDataForRace()
        return;
      } catch(err) {
        console.log(err)
        setError(err)
      }
    } 
  }

  const [ editingMaxChoices, setEditingMaxChoices ] = useState(false);
  const [ maxChoicesActive, setMaxChoicesActive ] = useState(1);
  const cancelMaxChoices = () => {
    setEditingMaxChoices(false)
    setMaxChoicesActive(raceData.maxChoices)
  }

  const saveMaxChoices = async () => {
    try {
      const updateRes = await feathers.getService('races').patch(raceId, {
        maxChoices: maxChoicesActive
      })
      loadDataForRace()
      setEditingMaxChoices(false);
    } catch(err) {
      console.log(err)
      setError(err)
    }
  }


  const [ editingCoveragePlan, setEditingCoveragePlan ] = useState(false);
  const [ coveragePlanActive, setCoveragePlanActive ] = useState(null);
  const cancelCoveragePlan = () => {
    setEditingCoveragePlan(false)
    setCoveragePlanActive(raceData.coveragePlan)
  }

  const saveCoveragePlan = async () => {
    try {
      const updateRes = await feathers.getService('races').patch(raceId, {
        coveragePlan: coveragePlanActive
      })
      loadDataForRace()
      setEditingCoveragePlan(false);
    } catch(err) {
      console.log(err)
    }
  }

  const loadDataForRace = async () => {
    setLoading(true)
    setRaceData(null);
    try {
      const race = await feathers.getService('races').get(raceId);
      setRaceData(race);
      setMaxChoicesActive(race.maxChoices)
      setCoveragePlanActive(race.coveragePlan);
      setPartyActive(race.party)
      setLoading(false);
    } catch (err) {
      console.log(`Error in retrieving race: `, err)
    } finally {
    }
  }

  const getNewDistrictInput = useMemo(() => {
    if (raceData?.originalDistrict) {
      return { text: raceData?.originalDistrict?.longName, ...raceData?.originalDistrict }
    }
    return { text: raceData?.district?.longName, ...raceData?.district }
    }, [raceData?.originalDistrict, raceData?.district])

  useEffect(() => {
    if(feathers) {
      loadDataForRace()
    }
  }, [ feathers, raceId ])

  const errorMessage = error ? (error?.message || 'An error occurred. Please try again later.') : null;

  if(loading) {
    return <Wrapper>
      <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', padding: '36px'}}>
        <LoadingSpinner />
      </div>
    </Wrapper>
  }

  return (
    <Wrapper>
      <Section>
        <Typography variant='h3'>Ballot display</Typography>
        <div className='seperator' />
        <DataField title='Office'>
          <Link to={`/offices/${raceData.office?.key}`} target='_blank'>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
              <Typography variant='body1'>{raceData.office?.name || '--'}</Typography>
              <OpenInNewIcon style={{ width: '15px', height: '15px' }}/>
            </div>
          </Link>
        </DataField>
        {
          editingMaxChoices
          ?
            <FieldInlineEdit
              inputComponent={
                <Select
                  value={maxChoicesActive}
                  onChange={e => setMaxChoicesActive(e.target.value)}
                >
                  {
                    [...Array(10).keys()].map(v => (
                      <MenuItem key={v} value={v+1}>{v+1}</MenuItem>
                    ))
                  }
                </Select>
              }
              onCancel={cancelMaxChoices}
              onSave={saveMaxChoices}
              label='Plurality'
            />
          :
            <DataField
              title='Plurality'
              onEdit={
                isPermitted(user, ['editor', 'super-admin', 'publisher', 'reviewer'])
                ?
                  () => {
                    setEditingMaxChoices(true)
                  }
                : null
              }
            >
              <div>
                <Typography variant={'body1'}>
                  {raceData.maxChoices} candidates
                </Typography>
              </div>
            </DataField>
        }
        {
          raceData.maxChoices === 1 && 
          <>
            {
              editingRetention
              ?
                <FieldInlineEdit
                  inputComponent={
                    <Select
                      value={retentionActive}
                      onChange={e => setRetentionActive(e.target.value)}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  }
                  onCancel={() => setEditingRetention(false)}
                  onSave={saveRetention}
                  label='Retention election'
                />
              :
                <DataField
                  title='Retention election'
                  onEdit={
                    isPermitted(user, ['editor', 'super-admin', 'publisher', 'reviewer'])
                    ?
                      () => {
                        setEditingRetention(true)
                      }
                    : null
                  }
                >
                  <div>
                    <Typography variant={'body1'}>
                      {raceData.retention ? 'Yes' : 'No'}
                    </Typography>
                  </div>
                </DataField>
            }
          </>
        }
        {
          raceData?.party && (
            <>
            {
              editingParty
                ?
                  <FieldInlineEdit
                    inputComponent={
                      <PartySelect
                        value={partyActive}
                        onChange={setPartyActive}
                      />
                    }
                    onCancel={() => setEditingParty(false)}
                    onSave={saveParty}
                    label='Party'
                  />
                :
                  <DataField
                    title='Party'
                    onEdit={
                      isPermitted(user, ['editor', 'super-admin', 'publisher', 'reviewer', 'admin'])
                      ?
                        () => {
                          setEditingParty(true)
                        }
                      : null
                    }
                  >
                    <div>
                      <Typography variant={'body1'}>
                        {raceData.party}
                      </Typography>
                    </div>
                  </DataField>
              }
            </>
          )
        }
        {
          editingDistrict
          ?
            <FieldInlineEdit
              inputComponent={
                <DistrictSelectInput
                  onChange={onChangeDistrict}
                  value={newDistrictInput}
                  districtTypes={raceData.office.districtTypes}
                  style={{ width: 'calc(100% - 15px)' }}
                  descendantOf={descendantOf}
                />
              }
              onCancel={cancelDistrict}
              onSave={saveDistrict}
              label='District'
            />
          :
            <DataField
              title='District'
              onEdit={
                isPermitted(user, electionOver ? ['super-admin', 'admin', 'editor'] : ['editor', 'super-admin', 'researcher', 'admin', 'reviewer', 'publisher'])
                && editingAdditionalDistricts === false
                ?
                  () => {
                    setNewDistrictInput(getNewDistrictInput)
                    setEditingDistrict(true)
                  }
                : null
              }
            >
              <div>
                <Typography variant={'body1'}>
                  { raceData.originalDistrict?.longName || raceData.district?.longName}
                </Typography>
              </div>
            </DataField>
        }
        {
          editingAdditionalDistricts
          ?
            <FieldInlineEdit
              inputComponent={
                <DistrictSelectInput
                  onChange={onChangeAdditionalDistricts}
                  value={newAdditionalDistrictsInput}
                  districtTypes={raceData.office.districtTypes}
                  style={{ width: 'calc(100% - 15px)' }}
                  descendantOf={descendantOf}
                  multi={true}
                  seedMultiSearchText={newAdditionalDistrictsInput}
                  primaryDistrictId={raceData.district?._id}
                />
              }
              onCancel={cancelAdditionalDistricts}
              onSave={saveDistrict}
              label='Additional Districts'
            />
          :
            <DataField
              title='Additional Districts'
              onEdit={
                isPermitted(user, electionOver ? ['super-admin', 'admin', 'editor'] : ['editor', 'super-admin', 'researcher', 'admin', 'reviewer', 'publisher'])
                && editingDistrict === false
                ?
                  () => {
                    setNewAdditionalDistrictsInput(raceData.additionalDistricts?.map(d => ({ text: d.longName, ...d })))
                    setEditingAdditionalDistricts(true)
                  }
                : null
              }
            >
              <div>
                {
                  (raceData.additionalDistricts || []).map((district, i) => {
                    return (
                      <Typography variant={'body1'} key={district?._id}>
                        {district?.longName}
                      </Typography>
                    )
                  })
                }
                {
                  (raceData.additionalDistricts || []).length === 0 && (
                    <Typography variant={'body1'}>
                      None
                    </Typography>
                  )
                }
              </div>
            </DataField>
        }
        {
          editingEntityMatchInputs
          ?
            <FieldInlineEdit
              inputComponent={
              <InputList
                value={entityMatchInputs}
                name={'entityMatchInputs'}
                renderItem={(_item, i, ) => (
                <TextField
                  value={entityMatchInputs[i]}
                  onChange={(e) => {
                    const newInputs = entityMatchInputs.slice()
                    newInputs[i] = e.target.value
                    setEntityMatchInputs(newInputs)
                  }}
                  variant='outlined'
                  size='small'
                  style={{margin: '2px 0px', width: 'calc(100% - 16px)'}}
                />
              )}
               onChange={(e) =>{
                 setEntityMatchInputs(e.value || [])
                }}
                onAdd = {e => setEntityMatchInputs(em => [...em, ''])}
               />
              }
              onSave={saveEntityMatchInputs}
              label='Appears on ballot as'
            />
          :
            <DataField
              onEdit={
                isPermitted(user, electionOver ? ['super-admin', 'admin', 'editor'] : ['editor', 'super-admin', 'researcher', 'admin', 'reviewer', 'publisher'])
                ?
                  () => {
                    setEntityMatchInputs(raceData?.entityMatchInputs || [])
                    setEditingEntityMatchInputs(true)
                  }
                : null
              }
              title='Appears on ballot as'
            >
              <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', paddingTop: '4px'}}>
                {
                  (raceData?.entityMatchInputs || [])?.length > 0 && raceData?.entityMatchInputs.map((input, i) => (
                    <Typography variant={'body1'} style={{ lineHeight: '1.1'}}>
                      {input}
                    </Typography>
                  ))
                }
                {
                  (raceData?.entityMatchInputs || [])?.length === 0 && (
                    <Typography variant={'body1'}>
                      --
                    </Typography>
                  )
                }
              </div>
            </DataField>
        }
        {
          editingElection
          ?
            <UpdateRaceElection
              onSuccess={(newRace) => {
                loadDataForRace()
                setEditingElection(false)
              }}
              redirectOnFinish
              populateOnStart={true}
              race={raceData}
            />
          :
            <DataField
              title='Election'
              onEdit={
                (isPermitted(user, ['super-admin', 'admin', 'editor']) && raceData?.election?.partisan)
                ? () => setEditingElection(true)
                : null
              }
            >
              <Typography variant='body1'>
                {raceData.election?.name || '--'}
              </Typography>
            </DataField>
        }
      </Section>
      <Section>
        <Typography variant='h3'>Status</Typography>
        <div className='seperator' />
        {
          editingCoveragePlan
          ? (
            <FieldInlineEdit
              inputComponent={
                <Select
                  value={coveragePlanActive}
                  onChange={e => setCoveragePlanActive(e.target.value)}
                >
                  <MenuItem value={'issues-coverage'}>Detailed platforms</MenuItem>
                  <MenuItem value={'basic-coverage'}>Basic info</MenuItem>
                  <MenuItem value={'no-coverage'}>No coverage</MenuItem>
                </Select>
              }
              onCancel={cancelCoveragePlan}
              onSave={saveCoveragePlan}
              label='Coverage Plan'
            />
          ) : (
            <DataField title='Coverage Plan' onEdit={isPermitted(user, ['editor','super-admin']) ? () => setEditingCoveragePlan(true) : null}>
              <Typography variant='body1'>
              {
                raceData?.coveragePlan === 'no-coverage'
                ? 'No coverage'
                : (raceData?.coveragePlan === 'basic-coverage'
                  ? 'Basic info'
                  : 'Detailed platforms'
                )
              }
              </Typography>
            </DataField>
          )
        }
        <DataField title='Priority'>
          <Tooltip title={
            raceData?.priorityLevel === 'high'
            ? 'This candidate is a high priority level, and will be viewed by many users.'
            : (raceData?.priorityLevel === 'medium'
              ? 'This candidate is a medium priority level, and will be viewed by some users.'
              : 'This candidate is a low priority level, and will be viewed by few users.'
            )
          }>
            <Typography variant='body1'>
              {
                raceData?.priorityLevel === 'high'
                ? 'High'
                : (raceData?.priorityLevel === 'medium'
                  ? 'Medium'
                  : 'Low'
                )
              }
            </Typography>
          </Tooltip>
        </DataField>
        <DataField title='Status'>
          <SyncContainer>
            {
              raceData?.synced
              ? <Tooltip title='All changes made to the draft have been published to the Branch platform.'>
                  <GreenCheck />
                </Tooltip>
              : <Tooltip title='Some changes made to the draft have not been published to the Branch platform.'>
                <WarningIcon/>
              </Tooltip>
            }
            <div style={{ marginTop: '2px' }}>
              <Typography variant={'body1'}>
                {raceData?.synced ? 'Draft published' : 'Unpublished changes'}
              </Typography>
            </div>
          </SyncContainer>
        </DataField>
      </Section>
      <Section>
        <Typography variant='h3'>Analytics</Typography>
        <div className='seperator' />
        <DataField title='Expected readers'>
          <Typography variant='body1'>{raceData?.expectedReaders ? `${Math.round(raceData.expectedReaders).toLocaleString()}` : '--'}</Typography>
        </DataField>
        <DataField title='Number of readers'>
          <Typography variant='body1'>{typeof(raceData?.analytics?.views) !== 'undefined' ? `${Math.round(raceData.analytics?.views).toLocaleString()}` : '--'}</Typography>
        </DataField>
        <DataField title='Number of users'>
          <Tooltip title='The number of users that have this race on their ballot'>
            <Typography variant='body1'>{typeof(raceData?.analytics?.onBallot) !== 'undefined' ? `${Math.round(raceData.analytics?.onBallot).toLocaleString()}` : '--'}</Typography>
          </Tooltip>
        </DataField>
      </Section>
      {/*set up a  material ui snackbar popup for error messages*/}
      <Snackbar
        open={error != null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        autoHideDuration={6000}
        style={{ position: 'absolute' }}
        onClose={() => setError(undefined)}
      >
        <Alert
          severity="error"
          onClose={() => setError(undefined)}
          elevation={6}
          variant="filled"
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      
    </Wrapper>
  )
}


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 48px;
`

const SyncContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    width: 34px;
    margin-right: 6px;
  }
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-left: 16px;
  gap: 20px;

  h3 {
    margin-left: -16px;
    margin-bottom: -8px;
    font-size: 16px;
  }

  .seperator {
    height: 1px;
    width: 100%;
    background-color: #DDDDDD;
    margin: 0px 0 0px -16px;
  }
`

export { RaceDataFieldEditor };