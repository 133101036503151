import { Typography, Box } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useTaskTimeTracker } from '../../app/hooks/useTaskTimeTracker';
import { coveragePlanSpecifications } from '../../app/config';

const TimeTracker = ({
  // timeElapsed,
  // isActive,
  benchmarkMode = 'research',
  seedTask,
  disableServerLogging,
  priorityLevel,
  isReferendum,
  style,
  coveragePlan = 'auto-coverage'
}) => {
  const { timeElapsed, isActive, resetTimeTracker, activeTime, idleTime } = useTaskTimeTracker({
    seedTask,
    disableTimeTracker: disableServerLogging,
  });
  const fieldsForCoveragePlan = coveragePlanSpecifications[coveragePlan].progressArrayForCandidate({});
  const biosEnabled = (fieldsForCoveragePlan || []).some(spec => spec.field.toLowerCase().includes('background'));
  const issuesEnabled = (fieldsForCoveragePlan || []).some(spec => spec.field.toLowerCase().includes('issue: '));

  const timeEstimate = useMemo(() => {
    if(isReferendum) { 
      return benchmarkMode === 'review' ? '05:00' : '15:00';
    } else if(biosEnabled) {
      switch (priorityLevel) {
        case 'low':
          return benchmarkMode === 'review' ? '01:00' : '05:00';
        case 'medium':
          return benchmarkMode === 'review' ? '05:00' : '20:00';
        case 'high':
          return benchmarkMode === 'review' ? '10:00' : '40:00';
        default:
          return '--';
      }
    } else {
      return '1:00';
    }
  }, [priorityLevel, biosEnabled, isReferendum ])

  const fieldDescription = useMemo(() => {
    if(biosEnabled && issuesEnabled) {
      return 'background information and issue summaries';
    } else if(biosEnabled) {
      return 'background information';
    } else if(issuesEnabled) {
      return 'issue summaries';
    } else {
      return 'just a photo and website links';
    }
  }, [biosEnabled, issuesEnabled])

  const displayTime = useMemo(() => {
    const minutes = Math.floor(timeElapsed / 60);
    const seconds = timeElapsed % 60;
    return `${ minutes.toString().padStart(2 , '0')}:${seconds.toString().padStart(2, '0')}`
  }, [timeElapsed]) 

  return (
    <Wrapper style={style}>
      {
        isReferendum
        ? <Typography variant="body1" >This is a referendum.</Typography>
        : <Typography variant="body1" >This candidate is a <Box component="span" fontWeight='fontWeightBold'>{priorityLevel || '--'} </Box>priority level that requires {fieldDescription}.</Typography>
      }
      <Row>
        <Typography variant='body1' style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '-5px', letterSpacing: '1.2px', opacity: isActive ? 1 : 0.7 }}>{ displayTime }</Typography>
        <Typography variant='body2' style={{ fontWeight: 'bold', opacity: 0.7 }}> of {timeEstimate} minutes</Typography>
      </Row>
      {
        false &&
        <Row>
          <Typography variant='body2' style={{ }}>Active: {activeTime} seconds</Typography>
          <Typography variant='body2' style={{ }}>Idle: {idleTime} seconds</Typography>
        </Row>
      }
      {
        !isActive &&
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '6px' }}>
          <Warning />
          <Typography variant='body2' style={{ }}>Time is paused due to inactivity.</Typography>
        </div>
      }
    </Wrapper>

  )
}

const Row = styled.div`
  display: flex;
  gap: 6px;
  align-items: flex-end;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export { TimeTracker };
