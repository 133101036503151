import React from 'react';
import styles from './MeasureAsOnBallot.css';
import { StyledMarkdown } from '../lower-order';

export default function(props) {
  return (
    <div className='white-tile measure-as-on-ballot'>
      <h2>How it appears on your ballot</h2>
      {
        props.ballotText &&
        (
          <StyledMarkdown>
            {`"${props.ballotText}"`}
          </StyledMarkdown>
        )
      }
      {
        props.actNumbers &&
        (<p className='act-numbers'>{props.actNumbers}</p>)
      }
    </div>
  )
}
