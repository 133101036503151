
const styles = {
  wrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh'
  },
  backgroundBlob: {
    position: 'absolute',
    width: '100vw',
    height: 'auto',
    left: 0,
    top: '-100px',
  },
  branchLogo: {
    height: '50px',
    paddingBottom: '40px'
  },
  center: {
    width: 'calc(100% - 24px - 24px)',
    maxWidth: '500px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #EBEBEB',
    borderRadius: '12px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    padding: '24px',
  },
  // errorText {
  //   color: #F71735,
  //   margin: 5px 0px 5px,
  // },
  heading: {
    fontSize: '24px',
    margin: 0,
  },
  p: {
    fontSize: '16px',
  }
}

export default styles;
