import React from 'react';
import { AddButton } from '../AddButton';
import styled from 'styled-components';
import Chip from '@material-ui/core/Chip';
import ReactTooltip from 'react-tooltip';

/* Expected Props
* disabled - true or false
* name - the name of the form element (also it's key within the input state manager)
* value - the value of the form element
* onChange - the function to call when the state should be updated
*/

const InputListCondensed = ({
  name,
  disabled,
  onChange,
  value = [],
  addComponent = null,
  labelForItem = item => item?.sourceType,
  detailForItem = item => {
    return item?.url
  }
}) => {

  const addGroupElement = (element) => {
    let newValue = value.slice();
    newValue.push(element);

    onChange({ target: {
      name,
      value: newValue
    }});

    ReactTooltip.hide()
    ReactTooltip.rebuild();
  }

  const removeGroupElementAtIndex = (index) => {
    let newValue = value.slice();
    newValue.splice(index, 1);

    onChange({ target: {
      name,
      value: newValue
    }});

    ReactTooltip.hide()
    ReactTooltip.rebuild();
  }

  return (
    <Wrapper>
      {
        value.map((item, i) => (
          <Chip
            {
              ...(detailForItem(item)
                ? { 'data-tip': detailForItem(item), 'data-place': 'top' }
                : {}
              )
            }
            key={`${name}-${i}`}
            label={labelForItem(item)}
            onDelete={() => removeGroupElementAtIndex(i)}
            variant='outlined'
            size='small'
            style={{ margin: '2px' }}
          />
        ))
      }
      {
        addComponent &&
        addComponent(addGroupElement)
      }
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  max-width: 100%;
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
`;

export { InputListCondensed };
