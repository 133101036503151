import React, { Component, useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFeathers, isPermitted } from '../../app/util';
import { Link, Redirect, useRouteMatch, useHistory } from 'react-router-dom';
import { setElections } from '../../app/actions';
import {
  CTAButton,
  Modal,
  LoadingSpinner,
  ElectionPreviewTile
 } from '../../components';
import CreateElection from '../CreateElection';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: calc(100% - 2 * 24px);
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: stretch;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;

  h1 {
    font-size: 28px;
    margin: 0;
  }

  h2 {
    font-size: 18px;
    opacity: 0.6;
    letter-spacing: 2px;
    margin: 0;
  }
`

const LoadingTile = styled.div`
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;

  span {
    marginBottom: 20px;
  }
`;

const ElectionTileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  margin-bottom: 30px;
`


const Elections = () => {
  const feathers = useFeathers();
  const route = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector(state => state.user);
  const elections = useSelector(state => state.elections.list.map(key => state.elections.byKey[key])) || [];
  const electionsLive = elections.filter(el => ['prep', 'live','results-counting'].includes(el.status));
  const electionsOld = elections.filter(el => ['results-certified', 'results-tentative'].includes(el.status));

  const [ error, setError ] = useState(null);
  const [ loading, setLoading ] = useState(false);
  const modalOpen = route.path === '/elections/new';

  useEffect(() => {
    if(feathers) getElections()
  }, [ feathers ])

  useEffect(() => {
    if(!modalOpen) getElections()
  }, [ modalOpen ])

  const getElections = useCallback(
    async () => {
      if(modalOpen || loading) return;
      setLoading(true)
      setError(null);

      try {
        const res = await feathers.getService('elections').find({ query: {
          $limit: 20,
          name: { $ne: 'Test Election' },
          $sort: { date: -1 }
        }})

        const data = res.data;
        const keyList = data.map((election) => election.key);
        const electionsByKey = data.reduce((acc, election) => {
          const elObj = {};
          elObj[election.key] = election;
          return {
            ...acc,
            ...elObj
          };
        }, {});


        dispatch(setElections({ list: keyList, byKey: electionsByKey, default: keyList[0] }))
      } catch(error) {
        console.log(error)
        setError(error)
      } finally {
        setLoading(false)
      }
    },
    [ modalOpen, loading, feathers ]
  )

  return (
    <Wrapper>
      {
        modalOpen &&
        (
          <Modal onClose={() => history.goBack()}>
            <CreateElection
              onClose={() => history.goBack()}
              onSuccess={() => history.goBack()}
            />
          </Modal>
        )
      }
      <Row>
        <h1>Elections</h1>
        {
          isPermitted(user, ['editor', 'super-admin'])
          ? <CTAButton onClick={{ to: '/elections/new' }} value='New election' mode='pill'/>
          : <div/>
        }
      </Row>
      <ElectionTileWrapper>
        {
          loading
          ?
            <LoadingTile>
              <LoadingSpinner scale={0.8}/>
            </LoadingTile>
          :
            electionsLive.map((election, idx) => (
              <ElectionPreviewTile
                key={election.key}
                onClick={{ to: `/elections/${election.key}` }}
                election={election}
                style={{ marginBottom: '14px', marginRight: '14px' }}
              />
            ))
        }
      </ElectionTileWrapper>
      <Row>
        <h2>{`Previous Elections`.toUpperCase()}</h2>
      </Row>
      <ElectionTileWrapper>
        {
          loading
          ?
            <LoadingTile>
              <LoadingSpinner scale={0.8}/>
            </LoadingTile>
          :
            electionsOld.map((election, idx) => (
              <ElectionPreviewTile
                condensed
                key={election.key}
                onClick={{ to: `/elections/${election.key}` }}
                election={election}
                style={{ marginBottom: '6px', marginRight: '14px' }}
              />
            ))
        }
      </ElectionTileWrapper>
    </Wrapper>
  )
}

export default Elections;
