import React, {useEffect, useState} from 'react';
import {isPermitted, useFeathers, usePrevious} from "../../app/util";
import styled from 'styled-components';
import {
  CTAButton,
  DataDetailToolbar,
  LoadingSpinner,
  AssignmentOptions,
  DataField, TextInput, PaginationStatus, PaginationControl
} from "../../components";
import {useHistory, useLocation, useParams, useRouteMatch} from "react-router-dom";
import {useSelector} from "react-redux";
import Typography from '@material-ui/core/Typography'
import moment from 'moment';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import qs from 'qs';

const CreateAssignment = ({
 }) => {
  const feathers = useFeathers();
  const { key: electionKey, userId } = useParams();
  const route = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const election = useSelector(state => state?.elections?.byKey[electionKey]);
  const [ user, setUser ] = useState(null);
  const [ races, setRaces ] = useState(null);
  const [ saving, setSaving ] = useState(false);
  const [ error, setError ] = useState(null);

  const [ numRaces, setNumRaces ] = useState(0);
  const [ searchTerm, setSearchTerm ] = useState('');
  const searchTermPrev = usePrevious(searchTerm);
  const [ page, setPage ] = useState(0);
  const pagePrev = usePrevious(page);
  const numRacesPerPage = 20;

  const usersName = !user?.name && (user?.firstName || user?.lastName) ? (
    (user?.firstName && user?.lastName )
      ? `${user?.firstName} ${user?.lastName}`
      : (user?.firstName || user?.lastName)
  ) : user?.name;

  const roleGiven = qs.parse(location.search, { ignoreQueryPrefix: true });
  const assignmentRole = roleGiven?.role || 'researcher';

  /* inputs */
  const [ dueByDate, setDueByDate ] = useState(null);
  const [ assignedCandidates, setAssignedCandidates ] = useState([]);
  const [ batchLength, setBatchLength ] = useState(5);
  const readyToCreate = dueByDate && assignedCandidates.length > 0;

  useEffect(() => {
    if(isNaN(parseInt(batchLength))) return;
    const dueDate = moment().add(parseInt(batchLength), 'd');
    dueDate.set('hour', 23)
    dueDate.set('minute', 59);
    dueDate.set('second', 59);
    setDueByDate(dueDate);
  }, [ batchLength ])

  const resetBatchLength = () => {
    if(isNaN(parseInt(batchLength))) setBatchLength(5)
  }

  const loadUser = async () => {
    const userInfo = await feathers.getService('users').get(userId);
    setUser(userInfo)
  }

  const createAssignment = async () => {
    if(saving || !readyToCreate) return;

    setSaving(true)
    try {
      const newAssignment = await feathers.getService('batch-assignments').create({
        user: userId,
        dueBy: moment.utc(dueByDate).format('YYYY-MM-DDTHH:mm:ss') + 'Z',
        candidates: assignedCandidates,
        role: assignmentRole
      })
      history.push(`/elections/${electionKey}/team`)
    } catch(err) {
      console.error(err)
      setError(err)
    } finally {
      setSaving(false)
    }
  }

  const loadRaces = async (page, searchFor) => {
    setRaces(null)
    let term = searchFor || searchTerm;
    const query = {
      election: electionKey,
      willCover: true,
      ...(assignmentRole === 'researcher'
        ? { 'assigned.researcher': { $ne: 'every' } }
        : { 'assigned.reviewer': { $ne: 'every' } }
      ),
      ...(assignmentRole === 'researcher'
        ? {
          $or: [
            { 'workflows.draft': 'ready' },
            { 'workflows.researcherSubmitted': 'ready' },
            { 'workflows.researcherSubmitted': 'rejected' }
          ]
        }
        : {
          $or: [
            { 'workflows.researcherSubmitted': 'ready' },
            { 'workflows.researcherSubmitted': 'rejected' },
            { 'workflows.reviewerSubmitted': 'ready' },
            { 'workflows.reviewerSubmitted': 'rejected' }
          ]
        }
      ),
      $skip: page*numRacesPerPage,
      $limit: numRacesPerPage,
      ...(term
          ? { $or: [ { candidatesSearchTerm: { $search: term } }, { officeSearchTerm: { $search: term } } ] }
          : {}
      ),
    }

    const races = await feathers.getService('races').find({
      query
    });

    setNumRaces(races?.total);
    setRaces(races.data)
  }

  useEffect(() => {
    if(feathers) {
      if(!user) loadUser()

      if(
        !races ||
        page !== pagePrev ||
        searchTerm !== searchTermPrev
      ) {
        loadRaces(page, searchTerm)
      }
    }
  }, [ feathers, searchTerm, searchTermPrev, page, pagePrev ])

  return (
    <Wrapper>
      <DataDetailToolbar
        onBack={{ to: {
            pathname: `/elections/${electionKey}`
          }}}
        navTree={[
          {
            text: 'Elections',
            to: {
              pathname: `/elections`,
            }
          },
          {
            text: election?.name || 'Election',
            to: {
              pathname: `/elections/${electionKey}`,
            }
          },
          {
            text: 'Assignments',
            to: {
              pathname: `/elections/${electionKey}/team`
            }
          },
          {
            text: usersName || '--',
            to: {
              pathname: `/elections/${electionKey}/team/${userId}`
            }
          },
          {
            text: 'New assignment'
          }
        ]}
        actionButtonsComponent={
          <div style={{ display: 'flex' }}>
            <Button
              variant={'contained'}
              color={'primary'}
              disabled={saving || !readyToCreate}
              onClick={createAssignment}
            >
              Assign
            </Button>
          </div>
        }
      />
      <WrapperInner>
        {
          Boolean(user) &&
          <>
            <Typography variant={'h2'}>Assign work to {usersName || user?.email}</Typography>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr'}}>
              <DataField title='Days to complete'>
                <TextField
                  value={batchLength}
                  onChange={(e) => setBatchLength(e.target.value)}
                  size={'small'}
                  variant={'outlined'}
                  onBlur={resetBatchLength}
                />
              </DataField>
              <DataField title='Due by'>
                <Typography variant={'body1'} style={{ marginTop: '4px' }}>
                  {dueByDate.format('MMMM DD')} at {dueByDate.format('h:mm A')}
                </Typography>
              </DataField>
              <DataField title='# Profiles'>
                <Typography variant={'body1'} style={{ marginTop: '4px' }}>
                  {assignedCandidates.length} profiles
                </Typography>
              </DataField>
            </div>
            <TextInput
              mode='condensed'
              onChange={(e) => setSearchTerm(e.target.value)}
              name='search'
              value={searchTerm || ''}
              placeholder='Search...'
              style={{ alignSelf: 'flex-end' }}
            />
            {
              races?.length > 0 &&
              <>
                <AssignmentOptions
                  races={races}
                  assignedCandidates={assignedCandidates}
                  onChange={setAssignedCandidates}
                  role={assignmentRole}
                />
              </>
            }
            {
              races?.length === 0 &&
              <EmptyStateWrapper>
                <Typography>No races to assign</Typography>
              </EmptyStateWrapper>
            }
            {
              !races &&
              <EmptyStateWrapper>
                <LoadingSpinner />
              </EmptyStateWrapper>
            }
            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
              <PaginationStatus
                activePage={page + 1}
                items={numRaces}
                itemsPerPage={numRacesPerPage}
                itemName='race'
              />
              <PaginationControl
                activePage={page + 1}
                items={numRaces}
                itemsPerPage={numRacesPerPage}
                onChange={(pg) => setPage(pg - 1)}
              />
            </div>
          </>
        }
        {
          !Boolean(user) &&
          <LoadingSpinner/>
        }
      </WrapperInner>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: calc(100% - 2 * 24px);
  min-height: calc(100% - 24px * 2);
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: stretch;
`

const WrapperInner = styled.div`
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: stretch;
  gap: 12px;
`

const EmptyStateWrapper = styled.div`
  width: 100%;
  min-height: 80px;
  margin: 8px 0 0;
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  border-radius: 8px;
  border: solid 1px #EEEEEE;
  span {
    ${({ theme }) => theme.font.normal};
    opacity: 0.8;
    font-size: 16px;
  }
`

export default CreateAssignment;