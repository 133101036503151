import React, { Component, useEffect, useState } from 'react';
import { useFeathers } from '../../app/util';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { Researcher, Tile } from '../../components';
import {
  Modal,
  AssignedTeamsModal,
  ResearcherLevelBadge
 } from '../../components'
import styled from 'styled-components';
import SendInvitationModal from '../../components/PermissionsModal/SendInvitationModal';
import EditPermissionsModal from '../../components/PermissionsModal/EditPermissionsModal';
import Chip from '@material-ui/core/Chip';
import EditIcon from '@material-ui/icons/Edit';
import EmailIcon from '@material-ui/icons/Email'

import CancelIcon from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText';
import Tooltip from '@material-ui/core/Tooltip';
// import ChevronRightLine from '@material-ui/icons/ChevronRight';
// import ChevronLeftLine from '@material-ui/icons/ChevronLeft';


const UserManagement = () => {
  const feathers = useFeathers();
  const [ users, setUsers ] = useState([])
  const [ showSendInvitationModal, setShowSendInvitationModal ] = useState(false)
  const [ invitationUser, setInvitationUser ] = useState(null)

  const [ editPermissionsModal, setEditPermissionsModal ] = useState(null)
  const [ editPermissionsUser , setEditPermissionsUser ] = useState(null)
  const [ removePermissionsUser, setRemovePermissionsUser ] = useState(null)
  const [ removePermissionsModal, setRemovePermissionsModal ] = useState(null)
  const [ selectedUser, setSelectedUser ] = useState(null)

  // TODO: implement sorting and pages for users/invites
  // const [ activePage, setActivePage ] = useState(0)
  // const [ usersPerPage, setUsersPerPage ] = useState(25)

  const getInvitations = async() => {
    const data = await feathers?.getService('invite-links').find(
      { query: {
        type: 'data-editor',
        $sort: { createdAt: -1 },
        $limit: 200,
        valid: true
      }});
    const userInvites = data?.data || []
    return userInvites
  }

  const getUsers = async() => {
    const data = await feathers?.getService('users').find({ query: {
        $and: [
          { permissions: { $in: ['reviewer', 'publisher', 'researcher', 'editor', 'admin', 'super-admin', 'auditor'] } },
          { permissions: { $nin: ['test'] } }
        ],
        $sort: { createdAt: -1 },
        $limit: 200
        // $limit: usersPerPage,
        // $skip: activePage * usersPerPage
      }});
    let userData = data?.data || []
    // get user invitations in addition to users and add them to users if they are not already in there, with data formatted to match users
    const userInvites = await getInvitations()
    userInvites.forEach(userInvite => {
      if (!userData.find(user => user.email === userInvite.email)) {
        const { starterPermissions, ...rest } = userInvite
        const pendingUser = { permissions: starterPermissions, pending: true, ...rest }
        userData.push(pendingUser)
      }
    })
    setUsers(userData)
  }

  const cancelInvitation = async( invitation ) => {
    await feathers.getService('invite-links').patch(
      invitation._id,
      { valid: false,
      },
      { query: {
        email: invitation.email,
        type: 'data-editor',
      }}
    )
    getUsers()
  }

  const removePermissions = async( user ) => {
    await feathers.getService('users').patch(user._id,
      { permissions: [] }
    )
    getUsers()
  }

  const editPermissionsForUser = (user, event) => {
    event.stopPropagation()
    setEditPermissionsUser(user)
    setEditPermissionsModal(true)
  }

  useEffect(() => {
    getUsers();
  }, [ feathers ]);

  const ActionCell = ( { user }) => {
    let editable;
    if ( // only a super-admin can edit admin/super-admin/editor/user-managements permissions
        !user?.permissions.some(permission => [
          'super-admin'
        ].includes(permission))
      && user.pending !== true
    ) {
      editable = true
    } else {
      editable = false
    }
    return (
      <ActionCellWrapper>
        {
          editable &&
          <Tooltip title="Edit User" placement="top">
            <IconButton
              size='small'
              onClick={e => editPermissionsForUser(user, e)}
            >
              <EditIcon></EditIcon>
            </IconButton>
          </Tooltip>
        }
        {
          user.pending &&
            <Tooltip title="Resend Invitation" placement="top">
              <IconButton
                size='small'
                onClick={(e) => {
                  e.stopPropagation()
                  setInvitationUser(user)
                  setShowSendInvitationModal(true)
                }}
                data-tooltip-id="my-tooltip"
              >
                <EmailIcon></EmailIcon>
              </IconButton>
            </Tooltip>
        }
        {
        (editable || user.pending)  &&
          <Tooltip title="Remove User" placement="top" style={{color: 'red'}}>
            <IconButton 
              size='small'
              onClick={(e) => {
                e.stopPropagation()
                setRemovePermissionsUser(user)
                setRemovePermissionsModal(true)
              }}
            >
              <CancelIcon></CancelIcon>
            </IconButton>
          </Tooltip>
        }
      </ActionCellWrapper>
    )
  }

  const UserRow = ({ user }) => (
    <DataRow
      isUserSelection={ user._id === selectedUser?._id}
      onClick={(e) => editPermissionsForUser(user, e)}
    >
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '4px'}}>
        <Typography variant='body1' color='textPrimary'>
          {user?.name ?? (user.firstName + user.lastName ? `${user.firstName} ${user.lastName}` : '')}
        </Typography>
        <a href={`mailto:${user.email}`}>
          <Typography variant='body1' color='textPrimary'>{user.email}</Typography>
        </a>
        {user.pending && <Chip label='Pending' size='small' style={{ backgroundColor: '#F2F4C4', color: '#7A733C' }}/> }
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '4px' }}>
        {
          user.permissions.map(permission => (
            <Chip
              label={permission}
              key={`${user.email}-${permission}`}
              style={{ backgroundColor: '#A9C9FF', marginBottom: '4px' }}
              size='small'
            ></Chip>
          ))
        }
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '4px' }}>
        {
          typeof(user.researcherLevel) === 'number' &&
          <>
            <ResearcherLevelBadge style={{ height: '20px', width: '20px' }} level={user.researcherLevel} />
            <Typography variant='body1' color='textPrimary'>Level {user.researcherLevel}</Typography>
          </>
        }
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '4px' }}>
        {
          typeof(user.publisherLevel) === 'number' &&
          <>
            <Typography variant='body1' color='textPrimary'>Level {user.publisherLevel}</Typography>
          </>
        }
      </div>
      <ActionCell user={user}></ActionCell>
    </DataRow>
  )

  return (
    <Wrapper>
      {
        editPermissionsModal &&
        <Modal
          onClose={() => {
            setEditPermissionsModal(false)
            setEditPermissionsUser(null)
          }}
          style={{overflowY: 'auto'}}
        >
          <EditPermissionsModal
            onClose={() => {
              setEditPermissionsModal(false)
              setEditPermissionsUser(null)}}
            user={editPermissionsUser}
            refreshUsers={() => getUsers()}
          />
        </Modal>
      }
      {
        showSendInvitationModal &&
        <Modal
          onClose={() => {
            setShowSendInvitationModal(false)
            setInvitationUser(null)
          }}
          style={{overflowY: 'auto'}}
        >
          <SendInvitationModal
            invitationUser={invitationUser}
            refreshUsers={() => getUsers()}
          />
        </Modal>
      }
      {
        removePermissionsModal &&
        <Dialog
          open={removePermissionsModal}
          onClose={() => setRemovePermissionsModal(false)}
        >
          <DialogTitle id="alert-dialog-title">
            Are you sure you want to remove {removePermissionsUser.email}?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Selecting 'Remove User' will remove {removePermissionsUser.email} from the research portal.  You will need to send them another invitation in order for them to return.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              setRemovePermissionsModal(false)
              setRemovePermissionsUser(null)
            }} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                removePermissionsUser.pending ? cancelInvitation(removePermissionsUser) : removePermissions(removePermissionsUser)
                setRemovePermissionsModal(false)
              }}
              color="primary"
            >
              Remove User
            </Button>
          </DialogActions>
        </Dialog>
      }
      {
        selectedUser &&
        <AssignedTeamsModal
          user={selectedUser}
          onClose={() => setSelectedUser(null)}
        />
      }
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Typography variant="h1">User Management</Typography>
        <Button
          variant='contained'
          color='primary'
          style={{ width: '15vw' }}
          onClick={() => {
            setShowSendInvitationModal(true)
          }}
        >Invite user</Button>
      </div>
      <TableHeader>
        <Typography variant='h5'>User</Typography>
        <Typography variant='h5'>Permissions</Typography>
        <Typography variant='h5'>Researcher level</Typography>
        <Typography variant='h5'>Publishing level</Typography>
        <Typography variant='h5'></Typography>
      </TableHeader>
      <Table>
        <div>
          {
            users.map(user => (
              <UserRow user={user} key={user._id}></UserRow>
            ))
          }
        </div>
        {/* <PageTurnerWrapper>
          <IconButton onClick={() => {if (activePage > 1) setActivePage(activePage - 1)}}>
            <ChevronLeftLine></ChevronLeftLine>
          </IconButton>
          <IconButton onClick={() => setActivePage(activePage + 1)}>
            <ChevronRightLine></ChevronRightLine>
          </IconButton>
        </PageTurnerWrapper> */}
      </Table>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: calc(100% - 24px - 36px);
  display: flex;
  flex-direction: column;
  padding: 36px 36px 36px 24px;
  align-items: stretch;
  min-width: 1000px;
`;


const ActionCellWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const DataRow = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1fr 60px;
  padding: 12px 24px;
  border-bottom: 1px solid #D3D3D3;
  grid-column-gap: 20px;
  cursor: pointer;
  align-items: center;
`

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1fr 60px;
  grid-column-gap: 36px;
  padding: 0 24px 8px;
  margin-top: 30px;
`

const Table = styled.div`
  border: 1px solid #D3D3D3;
  border-radius: 3px;
`

// const PageTurnerWrapper = styled.div`
//   display: flex;
//   justify-content: space-between;
//   padding: 4px 10px 4px 10px;
// `

export default UserManagement;
