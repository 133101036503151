import React, { useEffect, useState } from 'react';
import { useFeathers } from '../../app/util';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {
  LoadingSpinner,
  HintTile,
  PhotoSelectFromMulti
 } from '../../components'
import styled from 'styled-components';
import { Skeleton } from '@material-ui/lab';
import { batch } from 'react-redux';

const numCells = 5;

const PhotoOptionsHookups = ({ electionKey, style, className }) => {
  const feathers = useFeathers();

  const [ totalIncomplete, setTotalIncomplete ] = useState(undefined);
  const [ totalCandidates, setTotalCandidates ] = useState(undefined);
  const [ error, setError ] = useState(null);
  const [ loading, setLoading ] = useState(true);
  const [ candidateCountPreviewText, setCandidateCountPreviewText ] = useState('');
  const [ activeCandidatesByCell, setActiveCandidatesByCell ] = useState([...Array(numCells).keys()]);

  const loadCount = async () => {
    setLoading(true)
    try {
      const candidatesTotal = await identifyCandidates(feathers, electionKey, false, 0);
      const candidatesIncomplete = await identifyCandidates(feathers, electionKey, true, 0);
      setTotalCandidates(candidatesTotal)
      setTotalIncomplete(candidatesIncomplete)
    } catch(error) {
      setError(error);
    } finally {
      setLoading(false)
    }
  }

  const setActiveCandidateIdForCell = (cellNumber, candidateId) => {
    let arrayNew = activeCandidatesByCell.slice();
    arrayNew[cellNumber] = candidateId;
    setActiveCandidatesByCell(arrayNew);
  }

  useEffect(() => {
    if(feathers) loadCount()
  }, [ feathers ])

  useEffect(() => {
    if(typeof(totalIncomplete) !== 'undefined' && typeof(totalCandidates) !== 'undefined') {
      setCandidateCountPreviewText(`${totalCandidates - totalIncomplete} candidates out of ${totalCandidates} candidates complete`)
    }
  }, [ totalIncomplete, totalCandidates ])

  const previewText = () => (
    candidateCountPreviewText
      ? <Typography variant='body1' color='textPrimary'>{candidateCountPreviewText}</Typography>
      : <Skeleton variant="text" width={200}/>
  );

  if(loading) {
    return (
      <div style={style} className={className}>
        {previewText()}
        <Wrapper>
          <div style={{ padding: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <LoadingSpinner />
          </div>
        </Wrapper>
      </div>
    );
  }

  return (
    <div style={style} className={className}>
      {previewText()}
      <Wrapper>
        {
          error &&
          <HintTile>
            {error.message}
          </HintTile>
        }
        <WrapperInner>
          <HeaderCell>
            <Typography variant='body' color='textPrimary'><b>Candidate</b></Typography>
          </HeaderCell>
          <HeaderCell>
            <Typography variant='body' color='textPrimary'><b>Photo options</b></Typography>
          </HeaderCell>
          { 
            [...Array(numCells).keys()].map(cellNum => <PhotoSelectCell
              key={cellNum}
              electionKey={electionKey}
              setActiveCandidateIdForCell={setActiveCandidateIdForCell}
              activeCandidatesByCell={activeCandidatesByCell}
              cellNumber={cellNum}
              setNumIncomplete={setTotalIncomplete}
            />)
          }
        </WrapperInner>
      </Wrapper>
    </div>
  );
}

const PhotoSelectCell = ({ cellNumber, activeCandidatesByCell, electionKey, setActiveCandidateIdForCell, setNumIncomplete }) => {
  const [ candidate, setCandidate ] = useState(null);
  const [ loading, setLoading ] = useState(true);
  const [ saving, setSaving ] = useState(false);
  const [ batch, setBatch ] = useState(null)
  const feathers = useFeathers();

  const loadCandidate = async () => {
    setLoading(true);
    setBatch(null);
    setCandidate(null);
    setSaving(false)
    try {
      const { data, total } = await identifyCandidates(feathers, electionKey, true, numCells, cellNumber);
      setBatch(data)
      setNumIncomplete(total)
    } catch(err) {

    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if(batch && !candidate) {
      const activeCandidateIdsExceptThisCell = activeCandidatesByCell.map((id, idx) => idx === cellNumber ? undefined : id).filter(Boolean);
      let cdSet;
      for(const candidate of batch) {
        if(!cdSet && !activeCandidateIdsExceptThisCell.includes(candidate._id)) {
          setCandidate(candidate);
          cdSet = true;
        }
      }
    }
  }, [ batch, activeCandidatesByCell, candidate ])

  const setPath = async (path) => {
    if(saving) return;
    setSaving(true)

    try {
      const update = await feathers.getService('candidates').patch(candidate._id, {
        photoPathFace: path
      })
      console.log('updated candidate', update)
      loadCandidate()
    } catch(err) {
      setSaving(false)
      console.log(err)
    }
  }

  useEffect(() => {
    setActiveCandidateIdForCell(cellNumber, candidate?._id)
  }, [ candidate ])

  useEffect(() => {
    if(feathers) loadCandidate()
  }, [ feathers ])

  if(loading) {
    return <>
    <Cell>
      <Skeleton variant="text" width={150}/>
      <Skeleton variant="text" width={200}/>
      <Skeleton variant="text" width={100}/>
    </Cell>
    <Cell>
      <div style={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
        <Skeleton variant="circle" width={65} height={65}/>
        <Skeleton variant="circle" width={65} height={65}/>
        <Skeleton variant="circle" width={65} height={65}/>
        <Skeleton variant="circle" width={65} height={65}/>
      </div>
      
    </Cell>
    </>
  }

  if(!loading && !candidate) return null;

  const website = (candidate?.links || []).find(l => l.mediaType === 'website')

  return (
    <>
      <Cell>
        <Typography variant='body1' style={{ fontWeight: 'bold' }}>{candidate.name}</Typography>
        <Typography variant='body1' style={{ fontSize: '13px' }}>
          {candidate?.race?.officeName} • {candidate?.race?.district?.longName}
        </Typography>
        {
          website &&
          <a href={website?.url} target='_blank'>
            <Typography variant='body1' style={{ fontSize: '13px' }}>
              {website?.url}
            </Typography>
          </a>
        }
        <a href={`https://research.branch.vote/candidates/${candidate?._id}`} target='_blank'>
          <Typography variant='body1' style={{ fontSize: '13px' }}>
            Profile
          </Typography>
        </a>
      </Cell>
      {
        saving
        ? <Cell >
            <LoadingSpinner />
          </Cell>
        : <Cell style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', width: '800px', overflowX: 'scroll' }}>
            <PhotoSelectFromMulti
              photoOptions={candidate.photoOptions}
              onSelect={setPath}
              style={{ width: '800px' }}
            />
        </Cell>
      }
    </>
  )
}


/* A component for identifying the number of candidates yet to be completed */
const PhotoOptionsCount = ({
  electionKey,
  onLoadData      // accepts a structured object, with two properties: numCandidates, numCandidatesCompleted
}) => {
  const feathers = useFeathers();

  const loadCounts = async () => {
    const candidatesAll = await identifyCandidates(feathers, electionKey, false, 0);
    const candidatesIncomplete = await identifyCandidates(feathers, electionKey, true, 0);
    if(onLoadData)
      onLoadData({
        numCandidates: candidatesAll,
        numCandidatesCompleted: candidatesAll - candidatesIncomplete
      })
  }

  useEffect(() => {
    if(feathers) {
      loadCounts()
    }
  }, [ feathers, electionKey ])
  return <div/>
}

const identifyCandidates = async (feathers, electionKey, incomplete = true, $limit = 0, $skip = 0) => {
  const result = await feathers.getService('candidates').find({
    query: {
      election: electionKey,
      ...(
        incomplete 
        ? { $needsPhotoSelection: true } 
        : { 'photoOptions.0': { $exists: true } }
      ),
      $limit: $limit,
      $skip,
      $sort: {
        ballotOrder: 1
      }
    }
  });

  if($limit === 0) return result.total;
  else return result;
}

const Wrapper = styled.div`
  background-color: #FFFFFF;
  border: solid 1px #EEEEEE;
  border-radius: 8px;
  margin-top: 24px;
`


const WrapperInner = styled.div`
  border: solid 1px #EEEEEE;
  border-radius: 8px;
  background-color: #FFFFFF;
  display: grid;
  grid-template-columns: 1fr 800px;
`

const HeaderCell = styled.div`
  background-color: #EEEEEE;
  border-bottom: solid 1px #AAAAAA;
  padding: 8px 12px;
  display: flex;
  justify-content: center;
`

const Cell = styled.div`
  padding: 16px 12px;
  height: 90px;
`

export { PhotoOptionsHookups, PhotoOptionsCount };
