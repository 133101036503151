import { useFeathers } from '../util'
import { useState, useCallback, useEffect } from 'react'
import { asyncPoolAll } from '../util';

export const useLoadPaginatedFrontend = (service, query, dependencies = [], pageLimit = 10) => {
  const [entity, setEntity] = useState(null)
  const feathers = useFeathers();

  const findEntity = async () => {
    try {
      const adjustedQuery = { ...query, $limit: pageLimit }
      const response = await feathers.getService(service).find({ query: adjustedQuery })

      if (response.total <= pageLimit) {
        setEntity(response.data)
      } else {
        const total = response.total
        const pages = Math.ceil(total / pageLimit)
        const array =  [...Array(pages).keys()]
        const results = await asyncPoolAll(10, array, async ( i) => {
        const adjustedQuery = { ...query, $skip: (i) * pageLimit, $limit: pageLimit }
        const { data } =  await feathers.getService(service).find({ query: adjustedQuery })
        return data
        })

        setEntity(results.flat())
      }
    } catch (err) {
      console.log('useLoadPaginated error: ', err)
    }

  };

  useEffect(() => {
    if (!feathers || !query) return;
    findEntity()
  }, [feathers, ...dependencies]);

  return { entity }
};