import React, {
  Component,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import styled from 'styled-components';
import {
  Redirect,
  Link,
  useParams,
  useHistory,
  Prompt,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useFeathers, isPermitted, usePrevious, mergeReferencesWithDraft, researcherLevelPermissions } from '../../app/util';
import EditIcon from '@material-ui/icons/Edit';
import PreviewIcon from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';
import ReactTooltip from 'react-tooltip';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import EditNoteIcon from '@material-ui/icons/Edit';

import {
  LoadingSpinner,
  DataDetailToolbar,
  TabControl,
  Tab,
  CandidateSummary,
  DraftPreview,
  CreateProfileFeedback,
  CirclePhoto,
  PartyTag,
  TranslationPairDownload,
  CampaignFinance,
  newDraftAfterError,
  ReferenceEditor,
  Modal,
  ResearchCandidate,
  StyledMarkdown,
  SidePane,
  CandidateDataFieldEditor,
  EditCandidateProfile,
  mapDraftToServerInputs,
  TimeTracker,
} from '../../components';

import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import LinkList from '../../components/LinkList/LinkList';
import { useCandidateTimeTracker } from '../../app/hooks/useCandidateTimeTracker';
import { HistoryOutlined } from '@material-ui/icons';

const CandidateDetail = () => {
  const feathers = useFeathers();
  const elections = useSelector((state) => state.elections.byKey);
  const user = useSelector((state) => state.user);
  const history = useHistory();
  const racesSearch = history?.location?.state?.racesSearch;
  const onBack = history?.location?.state?.onBack;

  // Declare state variables
  // For keeping track of the candidate and underlying race object
  const { id, raceid: raceId } = useParams();
  const [candidate, setCandidate] = useState(null);
  const [race, setRace] = useState(null);
  const {
    stagingDraft = {},
    photoOptions,
    coveragePlan,
  } = candidate || {};
  const [election, setElection] = useState(null);
  const {
    name,
    synced,
    issues = [],
    bioPersonal,
    bioProfessional,
    bioPolitical,
  } = stagingDraft;
  const someContent =
    issues?.length || bioPersonal || bioProfessional || bioPolitical;
  const officeName = race?.officeName;

  // For managing form inputs
  const [editing, setEditing] = useState(false);
  const [restoring, setRestoring] = useState(false);
  const [reviewMode, setReviewMode] = useState(false);
  const [referenceStage, setReferenceStage] = useState(false);
  const [referencesInput, setReferencesInput] = useState(null);
  const [candidateDraft, setCandidateDraft] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(undefined);
  const [publishing, setPublishing] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [activeTab, setActiveTab] = useState(0);
  const [moreButtonClicked, setMoreButtonClicked] = useState(null);
  const [reviewNotesOnGoing, setReviewNotesOnGoing] = useState(null);
  const [showGiveFeedbackModal, setShowGiveFeedbackModal] =
    useState(false);

  const [activeLanguage, setActiveLanguage] = useState('en');
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [showReturnToDraftModal, setShowReturnToDraftModal] =
    useState(false);

  const [stagedUpdatesToReferences, setStagedUpdatesToReferences] =
    useState(null);

  useEffect(() => {
    if (stagedUpdatesToReferences && candidateDraft) {
      setStagedUpdatesToReferences(null);
      const merged = mergeReferencesWithDraft(
        stagedUpdatesToReferences,
        candidateDraft
      )
      setCandidateDraft(merged);
    }
  }, [candidateDraft, stagedUpdatesToReferences]);

  // Determine whether to present the publish option, which allows the user to
  // sync changes from the stagingDraft to the candidate object
  const editingEnabled = candidate?.stagingDraft.issues?.length > 0 ? candidate?.stagingDraft.issues.some(i => i.stances) : true;
  const electionOver = elections[candidate?.election] && moment().isAfter(moment.utc(elections[candidate?.election].date))
  const electionCertified = candidate?.election && elections[candidate?.election] && elections[candidate?.election].status === 'results-certified';
  
 const editPermission = activeLanguage === 'en'
    && isPermitted(user, electionCertified ? ['super-admin', 'editor', 'admin'] : ['super-admin', 'editor', 'admin', 'researcher', 'reviewer', 'publisher'])
    && (
      candidate?.priorityLevel 
      ? researcherLevelPermissions[user?.researcherLevel]?.includes(candidate?.priorityLevel)
      : true
    );
  const reviewerPermission = isPermitted(user, ['reviewer']) && !electionCertified;
  const editorPermission = isPermitted(user, electionCertified ? ['super-admin'] : ['super-admin', 'editor']);
  const publishPermission = isPermitted(user, electionCertified
    ? ['super-admin', 'editor', 'admin']
    :
    (coveragePlan === 'basic-coverage' || researcherLevelPermissions[user?.publisherLevel]?.includes(candidate?.priorityLevel)
      ? ['researcher', 'reviewer', 'publisher', 'editor', 'super-admin']
      : ['super-admin', 'editor', 'publisher']
    )
  );
  const createdOver24HoursAgo = moment().diff(moment(candidate?.createdAt), 'hours') > 24;
  const deletePermission = electionCertified
    ? false
    : isPermitted(user, createdOver24HoursAgo ? ['super-admin', 'editor'] : ['super-admin', 'editor', 'admin', 'reviewer', 'publisher']);
  const translationPermission = isPermitted(user, [
    'super-admin',
    'translations',
  ]);
  const publishable = publishPermission && !synced;

  // user tier is the highest level of permissions that the user has.
  const userTier = publishPermission
    ? 'publisher'
    : reviewerPermission
    ? 'reviewer'
    : 'researcher';
  // the user's role for this page
  const userRoleForPage = useMemo(() => {
    const assignedReviewer =
      candidate?.workflow?.assignments?.reviewer?._id ||
      candidate?.workflow?.assignments?.reviewer;
    const assignedResearcher =
      candidate?.workflow?.assignments?.researcher?._id ||
      candidate?.workflow?.assignments?.researcher;
    if (editorPermission) return 'editor';
    else if (publishPermission) return 'publisher';
    else if (reviewerPermission && user._id === assignedReviewer)
      return 'reviewer';
    else if (editPermission && user._id === assignedResearcher)
      return 'researcher';
  }, [user, candidate]);

  // setup for tracking edit times
  const { startEditTracker, endEditTracker } =
    useCandidateTimeTracker(candidate?._id, userRoleForPage);

  // if workflow version does not match staging draft version.
  const [showReviewButton, setShowReviewButton] = useState(false);
  const [showSubmitForReviewButton, setShowSubmitForReviewButton] =
    useState(false);

  const revertToPreviousVersion = () => {
    setCandidateDraft(candidate.stagingDraft);
    setRestoring(true);
  };

  const determineReviewButton = () => {
    // set review button if the candidate edits are ready for the user to review
    if (userTier === 'publisher') {
      if (
        ['researcher-submitted', 'reviewer-submitted'].includes(
          candidate?.workflow.status
        ) &&
        !candidate?.workflow?.rejectionStatus
      ) {
        setShowReviewButton(true);
      } else {
        setShowReviewButton(false);
      }
    } else if (userTier === 'reviewer') {
      if (
        candidate?.workflow?.status === 'researcher-submitted' &&
        !candidate?.workflow?.rejectionStatus
      ) {
        setShowReviewButton(true);
      } else {
        setShowReviewButton(false);
      }
    } else {
      setShowReviewButton(false);
    }
  };

  // determines when to display the submit for review button
  const determineSubmitForReviewButton = () => {
    // show submit for review button if user has made edits that require a review
    // researchers can submit for review drafts with changes or when they make changes after getting a rejection
    if (!synced && userTier === 'researcher') {
      if (candidate?.workflow.status === 'draft') {
        setShowSubmitForReviewButton(true);
      } else if (
        candidate?.workflow.status === 'researcher-submitted' &&
        candidate?.workflow.rejectionStatus
      ) {
        // if there is an edit to the draft after rejection-- draft user will be this researcher
        const savedStagingDraft =
          user._id === candidate.stagingDraft.user ? true : false;
        setShowSubmitForReviewButton(savedStagingDraft);
      } else {
        setShowSubmitForReviewButton(false);
      }
      // reviewers can submit for review something in draft with change or something that is in reviewer-submitted wth a rejection status of true
    } else if (!synced && userTier === 'reviewer') {
      if (candidate?.workflow.status === 'draft') {
        setShowSubmitForReviewButton(true);
      } else if (
        candidate?.workflow.status === 'reviewer-submitted' &&
        candidate?.workflow.rejectionStatus
      ) {
        // show submit for review button only after reviewer has saved an edit to the draft
        const savedStagingDraft =
          user._id === candidate.stagingDraft.user ? true : false;
        setShowSubmitForReviewButton(savedStagingDraft);
      } else {
        setShowSubmitForReviewButton(false);
      }
    } else {
      setShowSubmitForReviewButton(false);
    }
  };

  useEffect(() => {
    if (!candidate?.workflow) return;
    // run logic to deterine which buttons to display, based on the user permissions and where the workflow is at
    determineReviewButton();
    determineSubmitForReviewButton();
  }, [
    candidate?.workflow,
    publishPermission,
    editPermission,
    reviewerPermission,
  ]);

  const errorMessage = useMemo(() => {
    if (typeof error === 'string') return error;
    if (typeof error === 'object') {
      if (error.type === 'FeathersError') {
        let messageToShow;
        if (error?.errors && Object.values(error.errors).length > 0) {
          messageToShow = Object.values(error.errors).join(' ');
        } else {
          messageToShow = error?.message;
        }
        switch (error.code) {
          case 400:
            if (messageToShow) return messageToShow;
            return 'There was a problem with the data you submitted. Please check the form and try again.';
          case 401:
          case 403:
            if (messageToShow) return messageToShow;
            return 'You are not authorized to perform this action.';
          default:
            return messageToShow;
        }
      } else return error.message;
    }

    return 'There was an error when saving. Please try again!';
  }, [error]);

  // Declare functions
  const loadDataForCandidate = async (ln = 'en') => {
    setCandidate(null);
    setEditing(false);
    setRestoring(false);
    setReviewMode(false);
    setReviewNotesOnGoing(null);
    setSaving(false);
    setPublishing(false);
    try {
      const candidate = await feathers
        .getService('candidates')
        .get(id, {
          query: { ln },
        });
      setCandidate(candidate);
      const race = await feathers.getService('races').get(raceId);
      setRace(race);
      setElection(race.election?.key ?? race.election);
    } catch (err) {
      console.log(`Error in retrieving candidate: `, candidate);
      setError(err);
    }
  };

  const beginEdit = (e, inReviewMode = false) => {
    // if not in review mode and not already showing the return to draft modal, check if the workflow status is above the users tier.
    // the modal should pop up if the user is editing something above their tier, and return before going to edit screen
    if (!showReturnToDraftModal && !inReviewMode) {
      if (userTier === 'researcher') {
        if (
          ['publisher-submitted', 'reviewer-submitted'].includes(
            candidate.workflow.status
          )
        ) {
          setShowReturnToDraftModal(true);
          return;
        } else if (
          candidate.workflow.status === 'researcher-submitted' &&
          !candidate.workflow.rejectionStatus
        ) {
          setShowReturnToDraftModal(true);
          return;
        }
      }
      if (userTier === 'reviewer') {
        if (
          ['publisher-submitted'].includes(candidate.workflow.status)
        ) {
          setShowReturnToDraftModal(true);
          return;
        } else if (
          candidate.workflow.status === 'reviewer-submitted' &&
          !candidate.workflow.rejectionStatus
        ) {
          setShowReturnToDraftModal(true);
          return;
        }
      }
    }

    setShowReturnToDraftModal(false);
    startEditTracker();
    // resetTimeTracker();

    const referenceStage = !Boolean(
      candidate?.stagingDraft.references.checked
    );
    if (referenceStage) {
      setReferenceStage(true);
      setReferencesInput(candidate.stagingDraft.references);
    } else {
      setReferenceStage(false);
      setCandidateDraft(candidate.stagingDraft);
    }

    setEditing(true);
  };

  const cancelEdits = () => {
    startEditTracker();
    setEditing(false);
    endEditTracker();
  };

  const saveEdits = async () => {
    if (saving || !editing) return;
    setSaving(true);
    setError(undefined);
    const candidateUpdates = mapDraftToServerInputs(candidateDraft);

    try {
      const update = await feathers
        .getService('candidates')
        .patch(candidate._id, candidateUpdates);
      loadDataForCandidate();
    } catch (err) {
      console.error(err);
      setError(err);
      const newDraft = newDraftAfterError(candidateDraft, err)
      setCandidateDraft(newDraft);
      setSaving(false);
    } finally {
      endEditTracker();
    }
  };

  const finishRestore = async () => {
    if (saving || !restoring) return;
    setSaving(true);
    setError(undefined);

    try {
      const update = await feathers
        .getService('candidates')
        .patch(candidate._id, {
          $restoreToPreviousDraft: candidateDraft._id,
        });
      loadDataForCandidate();
    } catch (err) {
      console.error(err);
      setError(err);
      setSaving(false);
    }
  };

  // when we save references, we can either keep editing or not
  const saveReferences = async (keepEditing) => {
    if (saving || !editing || !referenceStage) return;
    setSaving(true);
    setError(undefined);

    try {
      const update = await feathers
        .getService('candidates')
        .patch(candidate._id, {
          references: referencesInput,
        });
      loadDataForCandidate();
    } catch (err) {
      console.error(err);
      setError(err);
      setSaving(false);
    }
  };

  // Handles a new candidate photo
  const deleteCandidate = async () => {
    try {
      const res = await feathers.getService('candidates').remove(id);
      history.push(`/elections/${election}/races/${raceId}`);
    } catch (err) {
      console.error(`Could not delete candidate`, err);
    }
  };

  const publish = async () => {
    if (!publishable || publishing) return;
    setPublishing(true);

    try {
      const update = await feathers
        .getService('candidates')
        .patch(candidate._id, {
          $publish: true,
        });
      loadDataForCandidate();
    } catch (err) {
      setPublishing(false);
      setError(err);
    }
  };

  // this is called when a researcher is ready for their draft to be reviewed by a reviewer
  const submitForReview = async () => {
    try {
      setSaving(true);
      await feathers.getService('candidates').patch(candidate._id, {
        $workflowChange: 'promote',
      });
      loadDataForCandidate();
    } catch (err) {
      setError(err);
    } finally {
      setSaving(false);
    }
  };

  // when submit from modal in review mode
  const submitReview = async (approved, notes) => {
    // submit or reject, include note and edits to candidate
    if (saving) return;
    setSaving(true);
    setError(undefined);
    const candidateUpdates = null; //candidateFromFormInputs(formInputs);
    throw new Error('Not implemented');

    try {
      await feathers.getService('candidates').patch(candidate._id, {
        $workflowChange: approved ? 'promote' : 'reject',
        note:
          notes && notes.trim().length > 0 ? notes.trim() : undefined,
        references: referencesInput,
        ...candidateUpdates,
      });
      loadDataForCandidate();
      setShowReviewModal(false);
      setEditing(false);
    } catch (err) {
      setError(err);
    } finally {
      endEditTracker();
      setSaving(false);
    }
  };

  const candidateDraftWithIssues = useMemo(() => {
    const issuesTitlesByKey = candidate?.issues?.reduce(
      (acc, issue) => {
        acc[issue.key] = issue.title;
        return acc;
      },
      {}
    );
    return {
      ...candidateDraft,
      issues: candidateDraft?.issues?.map((issue) => ({
        title: issue?.key ? issuesTitlesByKey?.[issue?.key] : null,
        ...issue,
      })),
    };
  }, [candidateDraft, candidate]);

  // On the initial component load...
  useEffect(() => {
    if (feathers && raceId) {
      // load data for the candidate
      loadDataForCandidate();
    }
  }, [feathers, raceId]);
  useEffect(() => {
    if (feathers) loadDataForCandidate(activeLanguage);
  }, [activeLanguage]);

  useEffect(() => {
    ReactTooltip.hide();
    ReactTooltip.rebuild();
  }, [editing, publishing]);

  useEffect(() => {
    if (editing) {
      window.onbeforeunload = function () {
        return 'Are you sure you want to navigate away? Any unsaved changes will be lost.';
      };

      return () => {
        window.onbeforeunload = null;
      };
    } else {
      window.onbeforeunload = null;
    }
  }, [editing]);

  const candidatePreviousVersions = useMemo(() => {
    return [
      stagingDraft,
      ...(candidate?.previousDrafts || []),
    ].filter(Boolean);
  }, [candidate?.previousDrafts, stagingDraft]);

  if (!candidate) {
    return <LoadingSpinner />;
  }

  const liveLink = window?.location?.href.includes('branch.vote') ? `https://www.branch.vote/races/${race?.raceKey}/candidates/${candidate?.official}` : `http://localhost:3030/races/${race?.raceKey}/candidates/${candidate?.official}`;

  const candidatePhotoPreview = () => (
    <Top>
      <TopInner>
        <CirclePhoto
          size="large"
          photoPath={
            (restoring ? candidateDraft : candidate?.stagingDraft)
              ?.photoPathFace
          }
        />
        <TopText>
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Typography variant="h2" color="textPrimary">
              {candidate?.stagingDraft?.name}
            </Typography>
            {candidate?.party && race && race?.party !== 'N' && (
              <PartyTag
                party={candidate.party}
                style={{ marginLeft: '16px' }}
              />
            )}
          </div>
          <Typography
            variant="body1"
            color="textPrimary"
            style={{ marginTop: '8px', marginBottom: '8px' }}
          >
            {candidate?.name} is running for {race?.officeName} in{' '}
            {race?.district?.longName}.
          </Typography>
          <LinkList
            links={
              (restoring ? candidateDraft : candidate?.stagingDraft)
                ?.links || []
            }
            align="flex-start"
          />
        </TopText>
      </TopInner>
      <TabWrapper>
        <TabControl
          onChange={(i) => setActiveTab(i)}
          activeTab={activeTab}
          tabStyle={{ marginBottom: '-2px' }}
          align="center"
        >
          <Tab>ON THE ISSUES</Tab>
          <Tab>CAMPAIGN FINANCE</Tab>
          {electionOver && <Tab>ELECTION RESULTS</Tab>}
        </TabControl>
      </TabWrapper>
    </Top>
  );

  return (
    <Wrapper>
      <Prompt
        when={Boolean(editing)}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <Dialog
        open={showReturnToDraftModal}
        onClose={() => setShowReturnToDraftModal(false)}
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to continue?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This profile is currently awaiting review by the next
            person. If you edit the profile, it will remove it from
            review. You will need to submit for review again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowReturnToDraftModal(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              beginEdit();
              setShowReturnToDraftModal(false);
            }}
            color="primary"
          >
            Continue editing
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={error != null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        autoHideDuration={6000}
        style={{ position: 'absolute' }}
        onClose={() => setError(undefined)}
      >
        <Alert
          severity="error"
          onClose={() => setError(undefined)}
          elevation={6}
          variant="filled"
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <DataDetailToolbar
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: 'calc( 100% - 36px * 2 )',
          padding: '16px 36px 16px',
          height: '72px',
        }}
        onBack={{
          to: {
            pathname:
              onBack || `/elections/${election}/races/${raceId}`,
            state: { racesSearch },
          },
        }}
        navTree={[
          {
            text:
              election && elections[election]?.name
                ? elections[election].name
                : '--',
            to: {
              pathname: `/elections/${election}/races`,
              search: racesSearch,
            },
          },
          {
            text: officeName || '--',
            to: {
              pathname: `/elections/${election}/races/${raceId}`,
              state: { racesSearch },
            },
          },
          { text: name || '' },
        ]}
        actionButtonsComponent={
          <>
            {editing && (
              <ButtonContainer>
                <Button
                  variant={'text'}
                  color={'secondary'}
                  data-tip="Cancel Draft"
                  onClick={cancelEdits}
                  disabled={saving}
                >
                  {reviewMode ? 'Cancel' : 'Cancel'}
                </Button>
                {!reviewMode && (
                  <Button
                    variant={'contained'}
                    color={'secondary'}
                    style={{
                      fontColor: '#FFFFFF',
                      marginLeft: '12px',
                    }}
                    disabled={saving}
                    data-tip="Save Draft"
                    onClick={
                      referenceStage
                        ? () => saveReferences(true)
                        : saveEdits
                    }
                  >
                    Save
                  </Button>
                )}
                {reviewMode && (
                  <Button
                    variant={'contained'}
                    color={'secondary'}
                    style={{
                      fontColor: '#FFFFFF',
                      marginLeft: '12px',
                    }}
                    data-tip="Approve or reject the current version"
                    onClick={() => setShowReviewModal(true)}
                  >
                    Submit review
                  </Button>
                )}
              </ButtonContainer>
            )}
            {restoring && (
              <ButtonContainer>
                <Button
                  variant={'text'}
                  color={'secondary'}
                  data-tip="Cancel"
                  disabled={saving}
                  onClick={() => setRestoring(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant={'contained'}
                  color={'secondary'}
                  style={{
                    fontColor: '#FFFFFF',
                    marginLeft: '12px',
                  }}
                  disabled={saving}
                  data-tip="Restore"
                  onClick={finishRestore}
                >
                  Restore
                </Button>
              </ButtonContainer>
            )}
            {!editing && !restoring && (
              <ButtonContainer>
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={(e) =>
                    setMoreButtonClicked(e.currentTarget)
                  }
                >
                  <MoreVertIcon />
                </IconButton>
                {editPermission && editingEnabled && (
                  <IconButton
                    data-tip="Edit Draft"
                    onClick={beginEdit}
                  >
                    <EditIcon />
                  </IconButton>
                )}
                {publishable && (
                  <Button
                    onClick={publish}
                    variant={'contained'}
                    color={'secondary'}
                    disabled={publishing}
                    style={{
                      paddingTop: '10px',
                      marginLeft: '6px',
                    }}
                    data-tip="Publish Draft"
                  >
                    {publishing ? 'Publishing...' : 'Publish'}
                  </Button>
                )}
                {showSubmitForReviewButton && (
                  <Button
                    onClick={submitForReview}
                    variant={'contained'}
                    disabled={saving}
                    color={'secondary'}
                    style={{
                      paddingTop: '10px',
                      marginLeft: '6px',
                    }}
                    data-tip="Submit for Review"
                  >
                    Submit for Review
                  </Button>
                )}
                {showReviewButton && (
                  <Button
                    onClick={(e) => {
                      setReviewMode(true);
                      beginEdit(e, true);
                    }}
                    variant={'contained'}
                    color={'secondary'}
                    style={{
                      paddingTop: '10px',
                      marginLeft: '6px',
                    }}
                    data-tip="Begin Review"
                  >
                    {candidate?.stagingDraft.user === user._id
                      ? 'Continue Review'
                      : 'Begin Review'}
                  </Button>
                )}
              </ButtonContainer>
            )}
          </>
        }
      />
      <WrapperInner>
        {!editing && (
          <Main>
            <CandidateInfoWrapper editing={0}>
              {candidatePhotoPreview()}
              {activeTab === 0 &&
                (someContent ? (
                  <CandidateSummary
                    style={{ margin: '-36px 0 0' }}
                    detailMode={true}
                    showArchivedIssues={true}
                    candidate={
                      restoring
                        ? candidateDraftWithIssues
                        : candidate.stagingDraft
                    }
                  />
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '36px',
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: 'bold',
                        letterSpacing: '2px',
                        opacity: 0.5,
                      }}
                    >
                      NO INFORMATION FOR THIS CANDIDATE YET
                    </Typography>
                  </div>
                ))}
              {activeTab === 1 && (
                <>
                  {candidate?.finance && (
                    <CampaignFinance finance={candidate?.finance} />
                  )}
                </>
              )}
            </CandidateInfoWrapper>
          </Main>
        )}
        {editing && (
          <Main>
            <CandidateInfoWrapper editing={1}>
              {referenceStage ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    alignItems: 'stretch',
                    maxWidth: '500px',
                    marginTop: '24px',
                  }}
                >
                  <Typography
                    variant={'h3'}
                    style={{ marginBottom: '16px' }}
                  >
                    Information sources
                  </Typography>
                  <Typography
                    variant={'body1'}
                    style={{ marginBottom: '32px' }}
                  >
                    First, collect the sources that you'll use to
                    research the candidate.
                  </Typography>
                  <ReferenceEditor
                    priorityLevel={candidate?.priorityLevel}
                    references={referencesInput}
                    onChangeReferences={setReferencesInput}
                    onProgressPastReferenceStage={() =>
                      saveReferences(true)
                    }
                    initialEditMode
                    disabled={saving}
                    candidateName={name}
                  />
                </div>
              ) : (
                <EditCandidateProfile
                  coveragePlan={coveragePlan}
                  value={candidateDraft}
                  onChange={setCandidateDraft}
                  onChangeIssue={(newIssue, issueKey) => {
                    setCandidateDraft(existingDraft => {
                      const existingDraftIssues = existingDraft.issues || [];
                      const newIssues = existingDraftIssues.map((issue, i) => {
                        if(issue.key === issueKey) {
                          return newIssue
                        }
                        return issue
                      })

                      return {
                        ...existingDraft,
                        issues: newIssues
                      }
                    })
                  }}
                  election={election}
                  references={candidateDraft?.references}
                  race={race}
                  photoOptions={candidate?.photoOptions}
                  candidateId={candidate?._id}
                  disabled={saving}
                />
              )}
            </CandidateInfoWrapper>
          </Main>
        )}
        {(referenceStage && editing ? false : true) && (
          <SidePane
            sectionTitles={
              editing
                ? [
                    candidate?.priorityLevel ? 'Priority' : null,
                    reviewMode ? 'Your review notes' : null,
                    'Information sources',
                    candidate?.workflow?.notes?.length > 0
                      ? 'Reviewer notes'
                      : undefined,
                  ].filter(Boolean)
                : null
            }
            sectionsInitiallyExpanded={
              editing
                ? [
                    candidate?.priorityLevel ? true : null,
                    reviewMode ? true : null,
                    true,
                    candidate?.workflow?.notes?.length > 0
                      ? true
                      : null,
                  ].filter((v) => (v === null ? false : true))
                : null
            }
          >
            {editing && candidate?.priorityLevel && (
              <TimeTracker
                coveragePlan={coveragePlan}
                priorityLevel={candidate?.priorityLevel}
              />
            )}
            {editing && (
              <>
                {reviewMode && (
                  <ResearchCandidate
                    onChangeNotes={setReviewNotesOnGoing}
                    onFinishReview={() => setShowReviewModal(true)}
                  />
                )}
                <ReferenceEditor
                  priorityLevel={candidate?.priorityLevel}
                  references={candidateDraft?.references}
                  onChangeReferences={setStagedUpdatesToReferences}
                  disabled={saving}
                  activeDraft={candidateDraft}
                  newsEnabled
                />
                {candidate?.workflow?.notes?.length > 0 && (
                  <ReviewerContentWrapper>
                    {(candidate?.workflow?.notes || [])?.map(
                      (note) => (
                        <Note key={note._id}>
                          <StyledMarkdown>{note.text}</StyledMarkdown>
                          <p className="date">
                            {note.createdAt
                              ? moment.utc(note.createdAt).fromNow()
                              : ''}
                          </p>
                        </Note>
                      )
                    )}
                  </ReviewerContentWrapper>
                )}
              </>
            )}
            {restoring && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  alignItems: 'stretch',
                  maxWidth: '400px',
                  marginTop: '12px',
                }}
              >
                <Typography
                  variant={'h3'}
                  style={{ marginBottom: '16px' }}
                >
                  Restore previous version
                </Typography>
                {candidatePreviousVersions.map((draft, i) => (
                  <DraftPreview
                    draft={draft}
                    showProgressBar
                    onClick={() => setCandidateDraft(draft)}
                    isSelected={candidateDraft?._id === draft._id}
                  />
                ))}
              </div>
            )}
            {!editing && !restoring && (
              <CandidateDataFieldEditor
                candidateId={id}
                race={race}
                onError={(err) => setError(err)}
              />
            )}
          </SidePane>
        )}
      </WrapperInner>
      <Dialog
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
      >
        <DialogTitle id="alert-dialog-title">
          {'Are you sure you want to delete the candidate?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            If you delete the candidate after the election has been
            published, it could have negative consequences for voters.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDeleteModalOpen(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setDeleteModalOpen(false);
              deleteCandidate();
            }}
            color="primary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {Boolean(showReviewModal) && (
        <Modal onClose={() => setShowReviewModal(false)}>
          <Typography variant="h3" style={{ marginBottom: '24px' }}>
            Submit review
          </Typography>
          <ResearchCandidate
            notesInitial={reviewNotesOnGoing}
            onSubmit={submitReview}
          />
        </Modal>
      )}
      <ReactTooltip
        place="left"
        effect="solid"
        type="light"
        multiline
      />
      <Menu
        id="long-menu"
        anchorEl={moreButtonClicked}
        keepMounted
        open={Boolean(moreButtonClicked)}
        onClose={() => setMoreButtonClicked(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            setMoreButtonClicked(null);
          }}
        >
          <a
            href={liveLink}
            target="_blank"
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              textDecoration: 'none',
            }}
            rel="noreferrer"
          >
            <ListItemIcon>
              <PreviewIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>View live</ListItemText>
          </a>
        </MenuItem>
        {
          translationPermission &&
          <MenuItem
            onClick={() => {
              setMoreButtonClicked(null);
            }}
          >
            <TranslationPairDownload serviceName='candidates' objectId={candidate._id}/>
          </MenuItem>
        }
        <MenuItem
          onClick={() => {
            setMoreButtonClicked(null);
            setShowGiveFeedbackModal(true);
          }}
        >
          <ListItemIcon>
            <EditNoteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Give feedback</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            revertToPreviousVersion();
            setMoreButtonClicked(null);
          }}
        >
          <ListItemIcon>
            <HistoryOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText>Restore previous version</ListItemText>
        </MenuItem>
        {deletePermission && (
          <MenuItem
            onClick={() => {
              setDeleteModalOpen(true);
              setMoreButtonClicked(null);
            }}
          >
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Delete candidate</ListItemText>
          </MenuItem>
        )}
        {Boolean(showGiveFeedbackModal) && (
          <Modal onClose={() => setShowGiveFeedbackModal(false)}>
            <CreateProfileFeedback
              candidateId={candidate._id}
              drafts={candidatePreviousVersions}
              onClose={() => setShowGiveFeedbackModal(false)}
              onCreate={(feedback) => {
                history.push(
                  `/elections/${election}/races/${raceId}/candidates/${id}/feedback/${feedback._id}?confirm=true`
                );
              }}
            />
          </Modal>
        )}
        {/* <LanguageSelectButton
          selected={activeLanguage}
          onSelect={setActiveLanguage}
        /> */}
      </Menu>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: calc(100%);
  height: calc(100vh);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const WrapperInner = styled.div`
  padding: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: calc(100% - 72px);
  max-height: calc(100% - 72px);
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: -10px;

  div {
    margin: 0 0 0 0;
  }
`;

const CandidateInfoWrapper = styled.div`
  ${props => props.editing ? `` : `width: calc(700px);
  max-width: 700px;`}
  padding: 32px 0 32px 64px;
  align-self: start;

  display: flex;
  flex-direction: column;
`;

const ReviewerContentWrapper = styled.div``;

const Note = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  font-size: 12px;
  .date {
    font-size: 10px;
    color: ${(props) => props.theme.colors.subtleGrey};
    align-self: flex-end;
  }
`;

const Top = styled.div`
  padding: 24px;
  width: calc(100% - 24px * 2);

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const TopInner = styled.div`
  width: 100%;
  max-width: 700px;
  align-self: center;

  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  @media (min-width: 500px) {
    align-items: center;
  }
`;

const TopText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  margin-left: 12px;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-left: -24px;
  padding: 0 0 0;
  border-top: 1px solid #eeeeee;
  width: calc(100% + 24px * 2);
  background-color: #ffffff;
  overflow-y: scroll;
`;

const TabWrapper = styled.div`
  width: 100%;
  margin-top: 8px;
  margin-bottom: calc(-24px + 2px);
`;

export default CandidateDetail;
