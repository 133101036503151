import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Redirect, useParams } from 'react-router-dom';
import { useFeathers } from '../../app/util';
import { useSelector } from 'react-redux';
import {
  CTAButton,
  SearchableSelectInput,
  CloseButton,
  SelectorInput,
  TextInput
} from '../';
import superagent from 'superagent';
import { TextField } from '@material-ui/core';


const CreateMeasureModal = ({
  restrictToDistrict,
  redirectOnFinish,
  onClose,
  election,       //full election
  onFinish
}) => {
  const feathers = useFeathers();
  const electionData = election;
  const electionKey = electionData?.key;
  const [ formInputs, setFormInputs ] = useState({
    title: '',
    district: null,
    ballotText: '',
    coverage: null
  })
  const submittable = formInputs?.district && formInputs?.coverage && formInputs?.title?.length > 0;

  const stateCode = electionData?.stateCode ? electionData.stateCode.toLowerCase() : null;

  const [ districtTypeConfig, setDistrictTypeConfig ] = useState(null);

  useEffect(() => {
    if(feathers && stateCode) fetchDistrictTypes()
  }, [ stateCode, feathers ])

  const fetchDistrictTypes = async () => {
    // use superagent to request 
    try {
      const response = await superagent.get('https://districts-microservice.herokuapp.com/api/v1/district-configurations/'+ stateCode);
      const { body } = response;
      setDistrictTypeConfig(body);
    } catch(err) {
      console.log(err)
    }
  }

  const headerTitle = useMemo(() => {
    if (!restrictToDistrict?._id) {
      return 'New Referendum'
    } else {
      return `New Referendum for ${restrictToDistrict?.longName}`
    }
  }, [restrictToDistrict])

  const descendantOf = useMemo(
    () => (restrictToDistrict
      ? (restrictToDistrict?._id || restrictToDistrict)
      : (electionData?.districts?.map((d) => d._id))),
    [electionData, restrictToDistrict]
  )

  const onChange = e => {
    const { name, value } = e.target;
    const update = {};
    update[name] = value;
    let newInputs = {
      ...formInputs,
      ...update
    };

    // Maintain the workflow for the inputs
    setFormInputs(newInputs)
  }

  /* manage searching options for selection, based on search text */
  const [ optionsForField, setOptionsForField ] = useState({});
  const [ loadingForField, setLoadingForField ] = useState({});
  const searchForField = async (field, searchText) => {
    if(!feathers) return;

    const loadingUpdate = {};
    loadingUpdate[field] = true;
    setLoadingForField({ ...loadingForField, ...loadingUpdate });

    let options = [];
    if(field === 'election') {
      const elections = await feathers.getService('elections').find({ query: {
        ...(searchText ? { name: { $search: searchText } } : {}),
        $sort: { date: -1 }
      }})
      options = elections.data.map(({ name, key }) => ({ text: name, key }));
    } else if(field === 'district') {
      console.log('istrictTypeConfig?.measureType', districtTypeConfig?.measureTypes)
      const districts = await feathers.getService('districts').find({ query: {
        ...(searchText ? { searchTerm: { $search: searchText } } : {}),
        ...(districtTypeConfig?.measureTypes ? { type: districtTypeConfig?.measureTypes || [] } : {}),
        ...(descendantOf ? { $descendantOf: descendantOf, $nestChildren: false, $includeAncestor: true } : {}),
      }})
      options = districts.data.map(({ longName, _id }) => ({ text: longName, _id }));
    }

    const updateForOptions = {};
    updateForOptions[field] = options;
    setOptionsForField({ ...optionsForField, ...updateForOptions })

    const loadingUpdate2 = {};
    loadingUpdate2[field] = false;
    setLoadingForField({ ...loadingForField, ...loadingUpdate2 });
  }

  const [ submitting, setSubmitting ] = useState(false);
  const [ measureCreated, setMeasureCreated ] = useState(false);
  const [ error, setError ] = useState(false);
  const submit = async () => {
    if(!submittable || submitting) return;

    setSubmitting(true);
    setError(false);
    const mappedInputs = {
      election: electionKey,
      title: formInputs?.title,
      district: formInputs?.district?._id,
      coverageStatus: formInputs?.coverage,
      ballotText: formInputs?.ballotText
    }

    try {
      const measure = await feathers.getService('measures').create(mappedInputs);
      setMeasureCreated({
        id: measure._id,
        election: measure.election
      })
      if(onFinish) onFinish(measure);
    } catch(err) {
      // Is error code a duplicate error?
      console.log(err)
      const errorAsJson = err.toJSON()
      setError(errorAsJson)
      setSubmitting(false);
    }
  }

  if(measureCreated && redirectOnFinish) {
    return <Redirect to={`/elections/${measureCreated.election?.key || measureCreated.election}/measures/${measureCreated.id}`} />
  }
  return (
    <Wrapper>
      <CloseButton
        onClick={onClose}
        style={{
          position: 'absolute',
          height: '36px',
          right: '-20px',
          top: '-20px'
        }}
      />
      <Top>
        <h1>{headerTitle}</h1>
        <Row>
          <div className='label'>Title</div>
          <TextInput
            name='title'
            value={formInputs?.title}
            onChange={onChange}
            mode='condensed'
            style={{ border: 'solid 1px #BBBBBB', flex: 1 }}
          />
        </Row>
        <Row>
          <div className='label'>District</div>
          <SearchableSelectInput
            name='district'
            onChangeSearchText={(searchText) => searchForField('district', searchText)}
            value={formInputs.district}
            onChange={onChange}
            disabled={!districtTypeConfig}
            optionsLoading={loadingForField.district}
            options={optionsForField?.district || []}
            style={{ flexGrow: 1 }}
          />
        </Row>
        <Row>
          <div className='label'>Ballot text</div>
          <TextField
            name='ballotText'
            value={formInputs?.ballotText}
            onChange={onChange}
            variant='outlined'
            style={{ flexGrow: 1, }}
            multiline
            minRows={5}
            size='small'
            maxRows={5}
          />
        </Row>
        <Row>
          <div className='label'>Coverage plan</div>
          <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'row' }}>
            <SelectorInput style={selectorStyle} name='coverage' value='future-coverage' onClick={onChange} selected={formInputs.coverage === 'future-coverage'}>
              <SelectorLabel>Will Cover</SelectorLabel>
            </SelectorInput>
            <SelectorInput style={selectorStyle} name='coverage' value='no-coverage' onClick={onChange} selected={formInputs.coverage === 'no-coverage'}>
              <SelectorLabel>Won't Cover</SelectorLabel>
            </SelectorInput>
          </div>
        </Row>
      </Top>
      <CTAButton
        value={submitting ? `Creating...` : `Create`}
        disabled={!submittable || submitting}
        onClick={submit}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 500px;
  min-height: 200px;
`
const Top = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 28px;
    margin: 0 0 26px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 28px;

  div.label {
    font-size: 18px;
    margin: 0 14px 0 0;
    opacity: 0.7;
    width: 100px;
  }
`;

const selectorStyle = {
  borderRadius: '8px',
  backgroundColor: '#FFFFFF',
  border: 'solid 1px #EEEEEE',
  margin: '8px',
  padding: '12px'
}

const SelectorLabel = styled.span`
  font-family: HouschkaRoundedAlt;
  padding-right: 12px;
`

export { CreateMeasureModal };
