import React from 'react';
import Typography from '@material-ui/core/Typography';
import { ModalInner } from '../lower-order';
import styled from 'styled-components';
import { languageConfig } from '../../language.config';

const LanguageSelectModal = ({
  onClose,
  onSelect,
  selected
}) => {
  return (
    <ModalInner onClose={onClose}>
      <Typography variant='h3' style={{ marginBottom: '22px' }}>Select a language</Typography>
      <Wrapper>
        {languageConfig.map((ln) => (
          <Tile key={ln.code} onClick={() => onSelect(ln.code)} selected={selected === ln.code ? 1 : 0}>
            <Typography
              variant='body1'
              style={{ fontWeight: 'bolder', fontSize: '16px', marginBottom: '-2px' }}
            >
              {ln.nameInLanguage}
            </Typography>
            <Typography
              variant='subtitle1'
              style={{ opacity: 0.7, fontSize: '14px' }}
            >
              {ln.name}
            </Typography>
          </Tile>
        ))}
      </Wrapper>
    </ModalInner>
  )
}

const Wrapper = styled.div`
  display: grid;
  column-gap: 16px;
  row-gap: 16px;
  justify-items: stretch;
  align-items: stretch;

  @media(max-width: 480px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(${Math.ceil(languageConfig.length / 2)}, 1fr);
  }

  @media(min-width: 481px) {
    grid-template-columns: repeat(${Math.ceil(languageConfig.length / 3)}, 1fr);
    grid-template-rows: 1fr 1fr 1fr;
  }
`

const Tile = styled.div`
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  border-radius: 8px;
  &:hover {
    background-color: ${props => props.theme.palette.primary}0A;
  }

  ${props => props.selected ? `border: 1px solid ${props.theme.palette.primary}` : ``};
`

export { LanguageSelectModal };
