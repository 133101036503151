const { Forbidden, NotFound } = require('@feathersjs/errors');

export const USER_LOGIN = 'USER_LOGIN';
export function userLogin(response) {
  return { type: USER_LOGIN, response };
}
export const USER_LOGOUT = 'USER_LOGOUT';
export function userLogout() {
  return{ type: USER_LOGOUT };
}

export const GET_ELECTIONS = 'GET_ELECTIONS';
export function setElections(payload) {
  return { type: GET_ELECTIONS, payload }
}
