import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ClockIcon from '@material-ui/icons/AccessTime';
import { ReactComponent as GreenCheck } from '../../images/green-check.svg';
import moment from 'moment';

const Inner = ({
  submitExtension,
  extensionReason,
  setExtensionReason,
  assignment: { dueBy, batchLengthDays, _id: id },
}) => {
  return (
    <InnerStyles>
        <Typography
          style={{ width: '100%', marginBottom: '24px' }}
          variant='h3'
        >
          Request Extension
        </Typography>
      <Typography
        style={{ width: '100%', marginBottom: '24px' }}
        variant='h4'
      >
        {`Extend your due date until:
       ${moment(dueBy).add(batchLengthDays < 3 ? 1 : 2, 'days').format('MMMM DD, YYYY')}`}
      </Typography>
      <Typography
        style={{ width: '100%', marginBottom: '5px' }}
        variant='body1'
      >
        Please provide your reason for requesting an extension
      </Typography>
      <TextField
        variant='outlined'
        name={`notes`}
        value={extensionReason}
        onChange={ e => setExtensionReason(e.target.value)}
        multiline
        minRows={2}
        style={{ overflowY: 'auto', marginBottom: '24px' }}
      />
      <div className="buttonWrapper">
        <Button
        startIcon={<ClockIcon style={{ color: '#FFFFFF' }}/>}
          onClick={submitExtension}
          variant='contained'
          color="primary"
          disabled={!extensionReason}
        >Submit</Button>
      </div>
    </InnerStyles>
  )
}

const InnerStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-width: 400px;

  .buttonWrapper {
    align-self: center;
  }
`

const SavedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 150px;

  .title {
    display: flex;
    justify-content: center;
    gap: 24px;
  }
`

const RequestExtensionModal = (props) => {
  return (
    <>
    {
      props.assignment.extensionReason ?
      <SavedWrapper>
        <div className="title">
          <Typography variant='h3'>Due Date Extended</Typography>
          <GreenCheck />
        </div>
        <Typography variant='h4'>
          {`Your assignment is now due by: ${moment(props.assignment.extensionDueBy).format('MMMM DD, YYYY')}`}
        </Typography>
      </SavedWrapper>
      :
      <Inner { ...props } />
    }
    </>
  )
}

export { RequestExtensionModal};
