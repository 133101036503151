import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import useKeypress from 'react-use-keypress';
import { Chip } from '@material-ui/core';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

const PriorityFilter = ({
	onChange,				// accepts 'high' or 'low'
	value,					// expects back the same object given by onChange
}) => {
  const [ selecting, setSelecting ] = useState(false);
	const priorityLevels = {
		'high': 'High priority',
		'low': 'Low priority'
	}

	useKeypress('Escape', () => {
		setSelecting(false)
	})

	if(selecting) {
		return (
			<Select
				style={{ minWidth: '80px'}}
				onChange={e => {
					onChange(e.target.value)
					setSelecting(false)
				}}
			>
				{Object.entries(priorityLevels).map(([k, v]) => (
					<MenuItem value={k} key={k}>{v}</MenuItem>
				))}
			</Select>
		);
	} else if(value) {
		return <Chip
			style={{ color: '#FFFFFF' }}
			color='primary'
			label={<Typography style={{ color: '#FFFFFF', fontWeight: 'bold' }}>{priorityLevels[value]}</Typography>}
			onDelete={() => onChange(null)}
			onClick={() => setSelecting(true)}
		/>
	} else {
		return (
			<Button startIcon={<PriorityHighIcon />} onClick={() => setSelecting(true)} color='primary'>
				Priority
			</Button>
		);
	}
}

export { PriorityFilter };