import React, { Component, useEffect, useState, useMemo } from 'react';
import { useFeathers } from '../../app/util';
import {
  useParams,
} from 'react-router-dom';
import {
	DataDetailToolbar
} from '../../components';
import styled from 'styled-components';
import { ScoutRacesForArea } from '../../components/ScoutRacesForArea';


const ScoutBallotItems = () => {
  const feathers = useFeathers();
  const electionKey = useParams().key;
	const districtId = useParams().districtId;
	const [election, setElection] = useState(null);

	useEffect(() => {
		if(feathers && electionKey) loadElectionData();
	}, [electionKey, feathers]);

	const loadElectionData = async () => {
		const electionData = await feathers.getService('elections').get(electionKey);
		setElection(electionData);
	}

  
  return (
    <Wrapper>
      <DataDetailToolbar
        onBack={{ to: {
            pathname: `/elections/${electionKey}/coverage-plan`,
          }}}
        navTree={[
          {
            text: 'Elections',
            to: {
              pathname: `/elections`,
            }
          },
          {
            text: election?.name || 'Election',
            to: {
              pathname: `/elections/${electionKey}`,
            }
          },
					{
            text: 'Coverage plan',
            to: {
              pathname: `/elections/${electionKey}/coverage-plan`,
            }
          },
          { text: 'Scout ballot items' }
        ]}
      />
			<Inner>
				<ScoutRacesForArea
					primaryDistrictId={districtId}
          allowDirectEdits
					election={electionKey}
				/>
			</Inner>
    </Wrapper>
  )
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	padding: 24px 12px 0;
`;

const Inner = styled.div`
	padding: 36px;
	width: calc(100% - 36px * 2);
	max-width: 800px;
`;

export default ScoutBallotItems;
