import React, { useEffect, useMemo, useState } from 'react';
import { taskConfigurations, useFeathers} from '../../app/util';
import { useSelector } from 'react-redux';

import {
  DataDetailToolbar,
  PaginationControl,
  TaskPreviewTable,
  TypeDropdown,
} from '../../components';
import styled from 'styled-components';
import qs from 'qs';
import { loadFullDisplayDataForTasks } from '../../app/util';

import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert';


const TaskList = () => {
  const feathers = useFeathers();
  const user = useSelector(state => state.user);
  const { page, taskType, status } = qs.parse(window.location.search, { ignoreQueryPrefix: true });

  // options for status include complete or not-started

  const [ loading, setLoading ] = useState(true);

  const [ taskCount, setTaskCount ] = useState(null);
  const [ tasksMapped, setTasksMapped ] = useState([]);
  const [ tasks, setTasks ] = useState([]);
  const [ error, setError ] = useState(null);

  const pageSize = 30;
  const defaultTaskType = 'review-candidate';

  const loadTasks = async (page, taskType, status) => {
    setLoading(true)
    setTasks([])

    try {
      const pageAsNumber = (page && !isNaN(parseInt(page))) ? parseInt(page) : 1;
      const $skip = Math.ceil(pageAsNumber - 1, 0) * pageSize;

      const statusToReference = status || 'not-started';
      const statusFilter = statusToReference === 'not-started' ? ['in-progress', 'not-started'] : [statusToReference];

      const tasks = await feathers.getService('research-tasks').find({
        query: {
          status: statusFilter,
          type: taskType || defaultTaskType,
          $includeCheckedOut: true,
          $limit: pageSize,
          $skip,
          $sort: {
            priority: -1,
            createdAt: 1
          }
        }
      });

      setTasks(tasks.data)
      setTaskCount(tasks.total)

      const tasksMapped = await loadFullDisplayDataForTasks(taskType, feathers, tasks.data);
      setTasksMapped(tasksMapped)
    } catch(err) {
      console.error(err)
      setError(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if(feathers) {
      loadTasks(page, taskType, status)
    }
  }, [ feathers ])

  return (
    <Wrapper>
      <DataDetailToolbar
        onBack={{ to: {
					pathname: `/home`,
				}}}
        navTree={[
          {
            text: 'Home',
            to: {
              pathname: `/home`,
            }
          },
          { text: 'Tasks' }
        ]}
      />
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', marginTop: '12px', gap: '12px' }}>
        <TypeDropdown
          value={status || 'not-started'}
          options={[
            { type: 'not-started', title: 'In queue' },
            { type: 'complete', title: 'Completed' },
          ]}
          onChange={(newType) => {
            const newQuery = qs.stringify({ ...qs.parse(window.location.search, { ignoreQueryPrefix: true }), status: newType, page: 1 })
            window.history.pushState({}, '', `${window.location.pathname}?${newQuery}`)
            loadTasks(1, taskType, newType)
          }}
        />
        <TypeDropdown
          value={taskType || defaultTaskType}
          options={Object.keys(taskConfigurations).map(key => ({ type: key, title: taskConfigurations[key].title }))}
          onChange={(newType) => {
            const newQuery = qs.stringify({ ...qs.parse(window.location.search, { ignoreQueryPrefix: true }), taskType: newType, page: 1 })
            window.history.pushState({}, '', `${window.location.pathname}?${newQuery}`)
            loadTasks(1, newType, status)
          }}
        />
      </div>
			<TaskPreviewTable 
        tasks={tasksMapped} 
        loading={loading} 
        actions={[
          'back-to-queue'
        ]}
        style={{ marginTop: '12px' }}
        columnConfig={{ 
          name: {
           hideType: true, 
           showDetailName: true, 
          },
        }}
      />
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '12px' }}>
        <PaginationControl
          activePage={page ? parseInt(page) : 1}
          items={taskCount}
          itemsPerPage={pageSize}
          onChange={(page) => {
            const newQuery = qs.stringify({ ...qs.parse(window.location.search, { ignoreQueryPrefix: true }), page })
            window.history.pushState({}, '', `${window.location.pathname}?${newQuery}`)
            loadTasks(page, taskType, status)
          }}
        />
      </div>
      <Snackbar
          open={Boolean(error)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          autoHideDuration={ 6000 }
          style={{position: 'absolute'}}
          onClose={() => setError(undefined)}
        >
          <Alert
            severity="error"
            onClose={() => setError(undefined)}
            elevation={6}
            variant="filled"
          >
            {error?.message ?? `${error}`}
          </Alert>
        </Snackbar>
    </Wrapper>
  )
}


const Wrapper = styled.div`
  width: calc(100% - 2 * 24px);
  min-height: calc(100% - 24px * 2);
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: stretch;
`;


export default TaskList;
