import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { useFeathers } from '../../app/util';
import moment from 'moment';
import { CTAButton } from '../CTAButton';
import {useDropzone} from 'react-dropzone';
import styled from 'styled-components';
import CloudUpload from '@material-ui/icons/CloudUpload';
import Typography from '@material-ui/core/Typography';

const languages = [
  { code: 'es', name: 'Spanish' },
  { code: 'ko', name: 'Korean' },
  { code: 'zh', name: 'Chinese (simplified)' },
  { code: 'zh-TW', name: 'Chinese (traditional)' },
  { code: 'vi', name: 'Vietnamese' },
  { code: 'hi', name: 'Hindi' }
]

const TranslationPairUpload = ({ style }) => {
  const feathers = useFeathers();
  const hiddenFileInput = React.useRef();
  const [ uploading, setUploading ] = useState(false);
  const [ message, setMessage ] = useState(false);

  const onDrop = (inputs) => {
    const allFiles = inputs.target?.files || inputs;
    const targetFile = allFiles ? allFiles[0] : null;
    if(targetFile) handleFile(targetFile)
  }

  const uploadData = async (data) => {
    try {
      const result = await feathers.getService('translation-edits').create(data)
      setUploading(false)
      setMessage(`Success! Uploaded ${result.length} edits.`)
    } catch(err) {
      console.log(err)
      setMessage('Upload failed', err)
    } finally {
      setUploading(false)
    }
  }

  const handleFile = (file) => {
		const reader = new FileReader();
		reader.onload = (e) => {
      setUploading(true)
			/* Parse data */
			const ab = e.target.result;
			const wb = XLSX.read(ab, {type:'array'});
			/* Get first worksheet */
			const wsname = wb.SheetNames[0];
			const ws = wb.Sheets[wsname];
			/* Convert array of arrays */
			const data = XLSX.utils.sheet_to_json(ws, {header:1});
			/* Update state */
      console.log(data)
			uploadData(data);
		};
		reader.readAsArrayBuffer(file);
	}

  const selectFile = () => {
    hiddenFileInput.current.click()
  }

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    noClick: true,
    maxFiles: 1
  })

  return (
    <Wrapper
      style={style}
      {...getRootProps()}
      isDragActive={isDragActive}
    >
      {message && <Typography variant='body1'>{message}</Typography>}
      <input {...getInputProps()} />
      <div style={{ display: 'flex', marginBottom: '12px' }}>
        <CloudUpload style={{ marginRight: '12px' }}/>
        <Typography variant='body1'>Drag a completed translation edit file</Typography>
      </div>
      <CTAButton
        style={{ }}
        mode='pill'
        onClick={selectFile}
      >
        Upload
      </CTAButton>
      <input type="file"
        ref={hiddenFileInput}
        onChange={onDrop}
        style={{display:'none'}}
        accept='.xlsx'
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 500px;

  border-radius: 8px;

  ${ props => props.isDragActive ? 'background-color: #0000000A;' : '' }
  border: ${ props => props.isDragActive ? '#DDDDDD dashed 2px' : 'solid 1px #DDDDDD;' };
`;

export { TranslationPairUpload };
