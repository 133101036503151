import React from 'react';
import { ReactComponent as LinkIcon } from './external-link-icon.svg';
import { ReactComponent as WebsiteLink } from './website-link.svg';
import { ReactComponent as TwitterIcon } from './twitter.svg';
import { ReactComponent as FacebookIcon } from './facebook.svg';
import { ReactComponent as InstagramIcon } from './instagram.svg';
import styles from './LinkList.css';

function getDisplayForUrl(url) {
  let displayURL = url;
  const splitUrl = url.split(/^(https?:\/\/)?(www\.)?([^\/]*)?/)

  if(splitUrl.length > 1) {
    displayURL = splitUrl[3];
  }
  return displayURL;
}

function iconForMediaType(mediaType) {
  switch(mediaType) {
    case 'twitter':
      return <TwitterIcon />;
    case 'instagram':
      return <InstagramIcon />;
    case 'facebook':
      return <FacebookIcon />;
    default:
      return <WebsiteLink />;
  }
}

export default function LinkList(props) {
  const links = props.links || [];
  const align = props.align || 'space-between';
  const websiteLinks = links.filter((link) => link.mediaType === 'website');
  const websiteURL = websiteLinks.length > 0 ? websiteLinks[0].url : false;
  const displayWebsiteURL = websiteURL ? getDisplayForUrl(websiteURL) : '';

  const socialLinks = links.filter((link) => link.mediaType === 'twitter'
    || link.mediaType === 'facebook' || link.mediaType === 'instagram');

  return (
    <div className='links-list-wrapper' style={{ justifyContent: align }}>
      {
        websiteURL &&
        (<div className='website-link-wrapper' style={{ marginRight: '16px' }}>
          <WebsiteLink />
          <a href={websiteURL} target='_blank'>{displayWebsiteURL}</a>
        </div>)
      }

      <div className='social-links-wrapper'>
        {
          socialLinks.map((link, index) => {
            return (
              <a href={link.url} target='_blank' key={`link-${index}-${link.url}`}>
                {iconForMediaType(link.mediaType)}
              </a>
            );
          })
        }
      </div>
    </div>
  );

  // return (
  //   <div className='links-list-wrapper'>
  //     {props.links.map((link) => {
  //       let displayURL = link.url;
  //       const splitUrl = link.url.split(/^(https?:\/\/)?(www\.)?([^\/]*)?/)
  //
  //       if(splitUrl.length > 1) {
  //         displayURL = splitUrl[3];
  //       }
  //       return (<a
  //         href={link.url}
  //         target="_blank"
  //         key={link.url+link.mediaType}
  //       >
  //         <div className='candidate-link'>
  //           <span>{displayURL}</span>
  //           <LinkIcon />
  //         </div>
  //       </a>)
  //     })}
  //   </div>
  // );
}
