import React, {useEffect, useState} from 'react';
import {isPermitted, useFeathers} from "../../app/util";
import styled from 'styled-components';
import { AddTeamMemberModal, DataDetailToolbar, LoadingSpinner, TeamPreviewTable} from "../../components";
import {useHistory, useLocation, useParams, useRouteMatch} from "react-router-dom";
import {useSelector} from "react-redux";
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core';

const Assignments = ({

}) => {
  const feathers = useFeathers();
  const electionKey = useParams().key;
  const election = useSelector(state => state?.elections?.byKey[electionKey]);
  const [reviewers, setReviewers] = useState(null);
  const [researchers, setResearchers] = useState(null);
  const [ candidateCountTextResearchers, setCandidateCountTextResearchers ] = useState(null)
  const [ candidateCountTextReviewers, setCandidateCountTextReviewers ] = useState(null)

  const [ addingResearchers, setAddingResearchers ] = useState(false)
  const [ addingReviewers, setAddingReviewers ] = useState(false)

  const loadCandidateCount = async (type) => {
    const query = {
      election: electionKey,
      $limit: 0
    }
    const queryExtra = {
      ...query
    }
    queryExtra[`workflow.assignments.${type}`] = null;

    const [ candidates, candidatesUnassigned ] = await Promise.all([
      feathers.getService('candidates').find({
        query
      }),
      feathers.getService('candidates').find({
        query: queryExtra
      })
    ]);

    const text = `${candidates.total - candidatesUnassigned.total} out of ${candidates.total} candidates have been assigned.`;
    if(type === 'researcher')
      setCandidateCountTextResearchers(text)
    else if(type === 'reviewer')
      setCandidateCountTextReviewers(text)
  }
  const loadUsers = async (type) => {
    const [ teamMembersRes, assignmentsRes ] = await Promise.all([
      feathers.getService('team-members').find({
        query: {
          $and: [
            { role: type },
            { election: electionKey }
          ],
          $limit: 30
        }
      }),
      feathers.getService('batch-assignments').find({
        query: {
          election: electionKey,
          closed: false,
          $limit: 30
        }
      })
    ]);

    const assignments = assignmentsRes.data;
    const teamMembers = teamMembersRes.data;

    const rows = teamMembers.map(teamMember => {
      const u = teamMember?.user
      // for each user, see if they have an active assignment
      const assignment = assignments.find(a => `${a?.user?._id || a?.user}` === `${u?._id}`);
      if(assignment && assignment.role !== type) return null

      return {
        ...teamMember,
        assignment
      }
    }).filter(Boolean)

    if(type === 'researcher') setResearchers(rows);
    else setReviewers(rows)
  }

  useEffect(() => {
    if(feathers) {
      loadUsers('researcher');
      loadCandidateCount('researcher')
      loadUsers('reviewer');
      loadCandidateCount('reviewer')
    }
  }, [ feathers ])

  const RenderSection = ({ content, previewText, viewType = 'researcher' }) => {
    return (
      <RenderSectionWrapper>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '12px' }}>
          <div>
            <Typography variant={'h2'} color={'textPrimary'}>
              {viewType  === 'researcher' ? 'Researchers' : 'Reviewers'}
            </Typography>
            {
              previewText &&
              <Typography variant={'body1'}>
                {previewText}
              </Typography>
            }
          </div>
          <AddTeamMemberButton
            variant='contained'
            color='primary'
            onClick={() => {
              viewType === 'researcher' ? setAddingResearchers(true) : setAddingReviewers(true)
            }}
          >
            Add { `${viewType[0]?.toUpperCase()}${viewType.slice(1)}`}
          </AddTeamMemberButton>
        </div>

        {
          content?.length === 0 &&
          <EmptyStateWrapper>
            <Typography variant={'overline'}>No {viewType  === 'researcher' ? 'researchers' : 'reviewers'} found.</Typography>
          </EmptyStateWrapper>
        }
        {
          content?.length > 0 &&
          <TeamPreviewTable
            users={content}
            electionKey={electionKey}
            teamMembers={content}
          />
        }
        {
          content === null &&
          <EmptyStateWrapper>
            <LoadingSpinner />
          </EmptyStateWrapper>
        }
      </RenderSectionWrapper>
    );
  }

  return (
    <Wrapper>
      {
        (addingResearchers || addingReviewers) &&
        <AddTeamMemberModal
          election={election}
          role={addingResearchers ? 'researcher' : 'reviewer'}
          onClose={() => {
            setAddingResearchers(false)
            setAddingReviewers(false)
          }}
          currentMembers={addingResearchers ? researchers : reviewers}
          refreshUsers={() => loadUsers(addingResearchers ? 'researcher' : 'reviewer')}
        />
      }
      <DataDetailToolbar
        onBack={{ to: {
            pathname: `/elections/${electionKey}`
          }}}
        navTree={[
          {
            text: 'Elections',
            to: {
              pathname: `/elections`,
            }
          },
          {
            text: election?.name || 'Election',
            to: {
              pathname: `/elections/${electionKey}`,
            }
          },
          { text: 'Assignments' }
        ]}
        actionButtonsComponent={null}
      />
      <WrapperInner>
        <RenderSection content={researchers} previewText={candidateCountTextResearchers} viewType='researcher' />
        <RenderSection content={reviewers} previewText={candidateCountTextResearchers} viewType='reviewer' />
      </WrapperInner>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: calc(100% - 2 * 24px);
  min-height: calc(100% - 24px * 2);
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: stretch;
`

const WrapperInner = styled.div`
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: stretch;
  gap: 12px;
`

const EmptyStateWrapper = styled.div`
  width: 100%;
  min-height: 80px;
  margin: 8px 0 0;
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  border-radius: 8px;
  border: solid 1px #EEEEEE;
  span {
    ${({ theme }) => theme.font.normal};
    opacity: 0.8;
    font-size: 16px;
  }
`

const RenderSectionWrapper = styled.section`
  padding-top: 3em;
`

const AddTeamMemberButton = withStyles({
  root: {
    width: '200px',
    height: '40px'
  },
})(Button);

export default Assignments;