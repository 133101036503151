import React, { useState } from 'react';
import styled from "styled-components";
import { Checkbox } from "../Checkbox";
import Typography from "@material-ui/core/Typography";
import { ChevronRightButton } from '../ChevronRightButton';

const AssignmentOptions = ({
   races,
   assignedCandidates,
   role,
   onChange,
}) => {

  const [ expandedRows, setExpandedRows ] = useState([]);

  const candidateIsAssigned = cd => (cd?.workflow?.assignments || {})[role] ? true : false
  const candidateIsAssignable = role === 'researcher'
    ? cd => cd.workflow.status === 'draft' || (cd.workflow.status === 'researcher-submitted' && cd.workflow.rejectionStatus === true)
    : cd => (cd.workflow.status === 'researcher-submitted') || (cd.workflow.status === 'reviewer-submitted' && cd.workflow.rejectionStatus === true)

  const expandRow = id => setExpandedRows([id, ...expandedRows]);
  const collapseRow = id => setExpandedRows(expandedRows.filter(r => r !== id));

  const checkCandidate = id => onChange([... new Set([ ...assignedCandidates, id ])]);
  const uncheckCandidate = id => onChange(assignedCandidates.filter(c => c !== id));
  const checkRace = race => onChange([... new Set([ ...assignedCandidates, ...race.candidatesUnassigned.map(cd => cd._id) ])]);
  const uncheckRace = race => {
    const candidateIds = race.candidatesUnassigned.map(cd => cd._id);
    onChange(assignedCandidates.filter(c => !candidateIds.includes(c)));
  }

  const rows = races.map(r => {
    const assignableCandidates = r.candidates.filter(candidateIsAssignable);
    const unassignableCandidates = r.candidates.filter(cd => !candidateIsAssignable(cd));
    const candidatesUnassigned = assignableCandidates
      .filter(cd => !candidateIsAssigned(cd))
      .map(cd => ({
        ...cd,
        checked: assignedCandidates.includes(cd._id)
      }))
    const candidatesAssigned = assignableCandidates
      .filter(cd => candidateIsAssigned(cd))
    const checked = candidatesUnassigned.every(cd => cd.checked);

    return {
      ...r,
      assignableCandidates,
      unassignableCandidates,
      candidatesUnassigned,
      candidatesAssigned,
      expanded: expandedRows.includes(r._id),
      checked,
      midChecked: !checked && candidatesAssigned.some(cd => cd.checked)
    }
  });

  return (
    <Wrapper>
      {
        rows.map((r, i) => (
          <Row key={r._id} border={i !== (rows.length - 1)}>
            <RowInner onClick={
              !r.checked ? () => checkRace(r) : () => uncheckRace(r)
            }>
              <Checkbox midChecked={r.midChecked} checked={r.checked}/>
              <Typography variant={'body1'}>{r.officeName}</Typography>
              <div style={{ width: '20px', minWidth: '20px', height: '2px' }}/>
              <Typography variant={'body1'}>{r.district?.longName}</Typography>
              <div style={{ flex: 1 }}/>
              <Typography variant={'body1'} style={{ textAlign: 'right' }}>{r?.candidatesUnassigned?.length}/{r?.assignableCandidates?.length} candidates unassigned</Typography>
              <div style={{ width: '20px', minWidth: '20px', height: '2px' }}/>
              <div
                style={{ height: '30px', width: '50px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
                onClick={(e) => {
                  e.stopPropagation();
                  if(r.expanded) collapseRow(r._id)
                  else expandRow(r._id)
                }}
              >
                <ChevronRightButton
                  style={r.expanded ? { transform: 'rotate(90deg)', cursor: 'pointer' } : {  cursor: 'pointer' }}
                />
              </div>
            </RowInner>
            {
              r.expanded &&
              r.candidatesUnassigned.map(cd => (
                <RowInner key={cd._id} onClick={cd.checked ? () => uncheckCandidate(cd._id) : () => checkCandidate(cd._id)}>
                  <div style={{ width: '20px', minWidth: '20px', height: '2px' }}/>
                  <Checkbox
                    checked={cd.checked}
                  />
                  <Typography variant={'body1'}>{cd?.name}</Typography>
                </RowInner>
              ))
            }
            {
              r.expanded &&
              r.candidatesAssigned.map(cd => (
                <RowInner key={cd._id}>
                  <div style={{ width: '55px', minWidth: '55px', height: '2px' }}/>
                  <Typography variant={'body1'}>{cd?.name}</Typography>
                  <div style={{ width: '20px', minWidth: '20px', height: '2px' }}/>
                  <Typography variant={'body1'}><i>Assigned</i></Typography>
                </RowInner>
              ))
            }
            {
              r.expanded &&
              r.unassignableCandidates.map(cd => (
                <RowInner key={cd._id}>
                  <div style={{ width: '55px', minWidth: '55px', height: '2px' }}/>
                  <Typography variant={'body1'}>{cd?.name}</Typography>
                  <div style={{ width: '20px', minWidth: '20px', height: '2px' }}/>
                  <Typography variant={'body1'}><i>Cannot be assigned</i></Typography>
                </RowInner>
              ))
            }
          </Row>
        ))
      }
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: #FFFFFF;
  border: solid 1px #D8D8D8;
  border-radius: 8px;
`

const Row = styled.div`
  display: flex;
  flex-direction: column;
  ${props => props.border ? 'border-bottom: solid 1px #D8D8D8;' : ''}
  padding: 4px 12px;
  width: calc(100% - 12px * 2);
`

const RowInner = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 12px;
  padding: 4px 0;
  cursor: pointer;
`
export { AssignmentOptions };