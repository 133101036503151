import React from 'react';
import { createPortal } from 'react-dom';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import styles from './Modal.css';

const modalRoot = document.getElementById( 'modal' );

const Modal = ({
  onClose = () => console.log('Close modal!'),
  children,
  style,
  className
}) => {
  return (
    <ModalInjection>
      {
        (onClose && typeof(onClose) === 'object' && onClose.to)
        ? <ModalBackground as={Link} to={onClose.to}/>
        : <ModalBackground onClick={onClose}/>
      }

      <ModalInner style={style} className={className}>
        {children}
      </ModalInner>
    </ModalInjection>
  )
}

const ModalInner = styled.div`
  background-color: #FFFFFF;
  border-radius: 12px;
  min-height: 200px;
  max-height: 70vh;
  min-width: 200px;
  z-index: 1;
  padding: 60px 72px;
  overflow-y: auto;
`;

const ModalBackground = styled.div`
  background-color: #00000020;
  width: 100vw;
  height: 100vh;
  position: fixed;
`;

class ModalInjection extends React.Component {
   constructor( props ) {
      super( props );
      // We create an element div for this modal
      var g = document.createElement( 'div' );
      g.id = 'modal-wrapper';
      this.element = g;
   }

   // We append the created div to the div#modal
   componentDidMount() {
      modalRoot.appendChild( this.element );
   }
  /**
    * We remove the created div when this Modal Component is unmounted
    * Used to clean up the memory to avoid memory leak
    */
   componentWillUnmount() {
      modalRoot.removeChild( this.element );
   }
   render() {
      return createPortal( this.props.children, this.element );
   }
}

export { Modal };
