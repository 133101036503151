import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import NoPhoto from './candidate-icon.png';
import Cropper from 'react-easy-crop';
import { LoadingSpinner } from '../LoadingSpinner';
import {useDropzone} from 'react-dropzone';
import { CloseButton } from '../CloseButton';
import getCroppedImg from './output';
import { useFeathers } from '../../app/util';
import { Button, Modal, Typography } from '@material-ui/core';
import { ModalInner } from '../lower-order';
const axios = require('axios').default;

const PhotoUploadInput = ({
  style,
  value,                   // the URL of the photo. Set to null if no photo uploaded yet
  onChange,                // called with the url of the final uploaded photo
}) => {
  const feathers = useFeathers();
  const [ cropping, setCropping ] = useState(false);
  const [ crop, setCrop ] = useState({ x: 0, y: 0 });
  const [ zoom, setZoom ] = useState(1);
  const [ initialUpload, setInitialUpload ] = useState(null);
  const [ initialUploadURL, setInitialUploadURL ] = useState(null);
  const [ croppedAreaPixels, setCroppedAreaPixels ] = useState(null);
  const [ uploading, setUploading ] = useState(false);

  useEffect(() => {
    if(!cropping) {
      setZoom(1);
      setCrop({ x: 0, y: 0 })
    }
  }, [cropping])
  const hiddenFileInput = useRef();

  const onDrop = (inputs) => {
    const allFiles = inputs.target?.files || inputs;
    const targetFile = allFiles ? allFiles[0] : null;
    if(targetFile) {
      setInitialUpload(targetFile)
      const urlOfUpload = URL.createObjectURL(targetFile);
      setInitialUploadURL(urlOfUpload)
      setCropping(true)
    }
  }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const clearPhoto = () => {
    setCropping(false);
    setInitialUpload(false);
    setInitialUploadURL(false);
    onChange(null)
  }

  const uploadCroppedImage = useCallback(async () => {

    try {
      setUploading(true);
      const filename = 'photo-upload'.toLowerCase().replaceAll(' ', '_');
      const croppedImage = await getCroppedImg(
        initialUploadURL,
        croppedAreaPixels
      )

      const croppedImageRenamed = new File([croppedImage], `${filename}.png`, {type: croppedImage.type});

      const dataForm = new FormData();
      dataForm.append('file', croppedImage);

      const { accessToken } = await feathers.get('authentication');
      const url = process.env.NODE_ENV === 'development'
          ? 'http://localhost:3030/api/v1/uploads'
          : 'https://www.branch.vote/api/v1/uploads';
      const urlWithType = url + '?type=candidatePhoto';
      const result = await axios.post(urlWithType, dataForm, {
        headers: { 'Authorization' : accessToken }
      });

      if(!result.data?.location) throw new Error('Error uploading file')
      onChange(result.data.location);
      setCropping(false);
      setInitialUpload(false);
      setInitialUploadURL(false);
      setUploading(false);
    } catch (e) {
      console.error(e)
      setUploading(false);
    }
  }, [croppedAreaPixels, initialUploadURL])

  const selectFile = () => {
    hiddenFileInput.current.click()
  }

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    noClick: true,
    maxFiles: 1
  })

  // Determine which elements of the view to display
  const showCropper = cropping && initialUpload && !uploading;
  const showPhoto = !cropping && !initialUpload && !uploading;
  const showRemovePhoto = (cropping && initialUpload) || (!cropping && value);
  const showSave = cropping && initialUpload;

  return (
    <Wrapper
      style={style}
      {...getRootProps()}
      isDragActive={isDragActive}
      editing={cropping}
    >
      <Top editing={cropping}>
        <input {...getInputProps()} />
        {
          uploading &&
          <LoadingSpinner style={{ alignSelf: 'center' }}/>
        }
        {
          showPhoto &&
          <PhotoWrapper >
            <RoundImage src={value || NoPhoto} onClick={
        !cropping && !uploading 
        ? selectFile
        : () => {}
      }/>
            {
              showRemovePhoto &&
              <CloseButton
                style={{
                  position: 'absolute',
                  right: 0,
                  top: 0
                }}
                onClick={clearPhoto}
              />
            }
          </PhotoWrapper>
        }
      </Top>
      <Bottom>
        {
          showSave &&
          null
        }
        {
          !cropping &&
          <>
            <p style={{ textAlign: 'center', opacity: 0.4, margin: 0, fontSize: '12px', fontWeight: 'bold' }}>
              drag and drop or click to edit
            </p>
          </>
        }
        <input type="file"
          ref={hiddenFileInput}
          onChange={onDrop}
          style={{display:'none'}}
          accept='.png,.jpg,.jpeg'
        />
      </Bottom>
      <Modal open={showCropper} onClose={clearPhoto}>
        <ModalInner style={{ display: 'flex', flexDirection: 'column', gap: '12px', alignItems: 'center', padding: '24px', backgroundColor: '#FFFFFF', width: 'calc(200px + 48px)' }}>
          <Typography variant='h3' style={{ textAlign: 'center', margin: '16px 0' }}>Crop your photo</Typography>
          <div style={{ height: '200px', width: '200px', overflow: 'hidden', borderRadius: '100px', position: 'relative' }}>
            <Cropper
              crop={crop}
              zoom={zoom}
              image={initialUploadURL}
              aspect={1 / 1}
              maxZoom={5}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              cropShape='round'
              onCropComplete={onCropComplete}
            />
          </div>
          <input
            type="range"
            value={zoom}
            min={1}
            max={5}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e) => {
              setZoom(e.target.value)
            }}
            className="zoom-range"
          />
          <Button color='primary' variant='contained' onClick={uploadCroppedImage} style={{ alignSelf: 'flex-end'}}>
            Save
          </Button>
        </ModalInner>
      </Modal>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 100px 20px;
  width: 100px;
  border-radius: 8px;

  ${ props => props.isDragActive ? 'background-color: #0000000A;' : '' }
  border: ${ props => props.isDragActive ? '#DDDDDD dashed 2px' : 'none' };
`;

const Top = styled.div`
  position: relative;
  background-color: ${props => props.editing ? `#000000` : `none` };
`;

const Bottom = styled.div`
  
`;

const PhotoWrapper = styled.div`
  position: relative;
  width: 100%;
`
const RoundImage = styled.img`
  width: 100%;
  border-radius: 100px;
  cursor: pointer;
`;

export { PhotoUploadInput };
