import React from 'react';
import PolicyViewMarkdown from '../../PolicyViewMarkdown';
// import SoundbitePlayer from '../../SoundbitePlayer';
import Text from '@material-ui/core/Typography';
import { SourceList } from '../SourceList';
import { StyledMarkdown } from '../StyledMarkdown';
import { ReactComponent as TopPriorityStar } from './top-priority-star.svg';
import styles from './CandidateIssue.css';

function topPriority() {
  return (
    <div className='top-priority-marker'>
      <TopPriorityStar/>
      <Text variant='overline' style={{ color: '#000', opacity: 0.5, fontSize: '14px' }}>
        TOP PRIORITY
      </Text>
    </div>
  )
}

const CandidateIssue = ({
  issue,
  soundbiteDescriptor,
  candidateId,
  pauseOthers,
  forcedPause,
  infoButton,
  style={}
}) => {
  const pvEnabled = issue.textPolicyMarkup && issue.policyTerms.length > 0;

  return (
    <div className='issue-wrapper' style={style}>
      <div className='issue-header'>
        {
          (issue.isTopPriority) &&
          topPriority()
        }
        <div className='issue-title'>
          <Text variant='body1' color='textPrimary' style={{ fontWeight: 'bold' }}>{issue.title}</Text>
          {
            infoButton
          }
        </div>
      </div>
      <div className='issue-body'>
        {
          issue.complete && (
            pvEnabled
              ? <PolicyViewMarkdown
                policyViewMarkup={issue.textPolicyMarkup}
                backupText={issue.text}
              />
              : <StyledMarkdown children={issue.text}/>
          )
        }
        {
          !issue.complete && issue.stances?.length > 0 &&
          <StyledMarkdown children={issue.text}/>
        }
        {
          !issue.complete && (!issue.stances || issue.stances?.length === 0) &&
          <StyledMarkdown children={`No information for this issue yet.`}/>
        }
      </div>
      {
        issue?.sources?.length > 0 &&
        <SourceList sources={issue.sources} />
      }
    </div>
  )
}

export { CandidateIssue };
