import React, { useState, useMemo } from 'react';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import styled from 'styled-components';
import { ExpandableContent } from '../index';


/**
 *
 * props: {
 *  sectionTitles: string[], - titles to display on each section of the side pane
 *  sectionsInitiallyExpanded: boolean[] - boolean that indicates whether each section should be expanded by default
 *  children: React.ReactNode | React.ReactNode[], - react component that corresponds to each section
 *  style: React.CSSProperties,
 *  leftSide: boolean, - set to true if should be a left side of screen side pane
 *  autoScroll: boolean, - set to true for the autoscroll bar , defaults to having the larger always visible scroll bar
 * }
 */
const SidePane = ({ sectionTitles, sectionsInitiallyExpanded, children, style, leftSide = false, autoScroll = false, expandedWidth = '400px' }) => {
    const [expanded, setExpanded] = useState(true);

    const arrowDirection  = useMemo(() => {
      // left if expanded and right side, right if contracted and right side, left if contracted and left side, right if expanded and left side
      if (leftSide && !expanded) return 'left';
      if (leftSide && expanded) return 'right';
      if (!leftSide && expanded) return 'left';

      if (!leftSide && !expanded) return 'right';

      return (expanded && !leftSide) || (!expanded && leftSide) ? 'left' : 'right';
    }, [expanded])

    const childrenRef = Array.isArray(children) ? children : [children];

    return (
      <div style={{
        height: '100%',
        overflowX: 'hidden',
        position: 'relative',
        width: expanded ? expandedWidth : '20px',
        minWidth: expanded ? expandedWidth : '20px',
        justifySelf: 'end',
        borderLeft: 'solid 1px #EEEEEE',
        boxShadow: `${leftSide ? '4px' : '-4px'} 4px 5px 5px #00000010`,
        transition: 'width 0.3s cubic-bezier(0.075, 0.82, 0.165, 1)',
        ...(style || {}),
        }}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            overflowY: autoScroll ? 'auto' : 'scroll',
            height: 'calc(100% - 24px * 2)',
            width: 'calc(100% - 36px - 24px)',
            padding: '24px 36px 24px 24px',
            boxShadow: `${leftSide ? '8px' : '-8px'} 0px 20px 5px #00000010`,
            minWidth: '200px'
          }}>
            {
              (childrenRef || []).filter(Boolean).map((child, index, arr) => {
                return (
                  <React.Fragment
                    key={index}
                  >
                    {
                      sectionTitles
                      ?
                      <>
                        <ExpandableContent
                          key={index}
                          title={sectionTitles[index]}
                          initiallyExpanded={(sectionsInitiallyExpanded || [])[index] ?? false}
                        >
                          <div style={{ width: '100%', marginTop: '16px' }}>
                            {child}
                          </div>
                        </ExpandableContent>
                        {
                          index !== (arr.length - 1) &&
                          <div style={{ minHeight: '1px', minWidth: '100%', margin: '8px 0', backgroundColor: '#DDDDDD' }} />
                        }
                      </>
                      : child
                    }
                  </React.Fragment>
                )
              })
            }
          </div>
          <ToggleSidePaneButton
            onClick={() => {
            setExpanded(!expanded)
            }}
            expanded={expanded}
            leftSide={leftSide}
            expandedWidth={expandedWidth}
          >
            <ArrowForwardIosIcon
              size={'small'}
              style={{ height: '16px', width: '16px', transform: arrowDirection === 'left' ? '' : 'rotate(180deg)'}}
            />
          </ToggleSidePaneButton>
      </div>
    )
  }

  const ToggleSidePaneButton = styled.div`
  height: 36px;
  min-height: 36px;
  width: 36px;
  min-width: 36px;
  border-radius: 36px;
  border: solid 1px #22034f20;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  color: ${props => props.theme.colors.darkPurple};
  cursor: pointer;
  position: fixed;
  bottom: 16px;
  float: right;
  ${props => props.leftSide ? `margin-left: ${props.expanded ? `calc(${props.expandedWidth} - 16px)` : '2px'}` : 'margin-left: -16px;'}
`

  export { SidePane };