import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useFeathers, isPermitted } from '../../app/util';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import {
  ChevronRightButton,
  LoadingSpinner,
  PaginationControl,
  PaginationStatus,
  ProgressBar,
  TypeDropdown,
  Modal,
  DataDetailToolbar,
} from '../../components';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import qs from 'qs';
import { TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { CreateMeasureModal } from '../../components/CreateMeasureModal';

const Measures = ({

}) => {
  const feathers = useFeathers();
  const history = useHistory();
  const route = useRouteMatch();
  const election = useParams().key;
  const electionData = useSelector(state => state?.elections?.byKey[election])
  const user = useSelector(state => state.user);
  const addPermissions = isPermitted(user, ['editor', 'researcher', 'super-admin', 'admin', 'reviewer', 'publisher']);
  const resultsVisible = (electionData?.status || '').includes('results')

  /* data */
  const { page, taskType, status, completion } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const [ searchText, setSearchText ] = useState('');

  const [ measureModalOpen, setMeasureModalOpen ] = useState(false);
  const [ data, setData ] = useState([]);
  const [ dataLoading, setDataLoading ] = useState(true);
  const [ activePage, setActivePage ] = useState(0);
  const [ totalNumber, setTotalNumber ] = useState(0);
  const measurePerPage = 15;

  const loadData = useCallback(async (pageRef, statusRef, completionRef, searchTextRef) => {
    setDataLoading(true)
    const activePageToUse = typeof(pageRef) === 'undefined' ? activePage : pageRef;
    const statusToRef = statusRef || status;
    const statusModified = (!statusToRef || 'all' === statusToRef) ? undefined : statusToRef;

    const completionToRef = completionRef || completion;
    const completionModified = (!completionToRef || 'all' === completionToRef) ? undefined : completionToRef;
    console.log('completionModified', completionModified)

    const searchTextToRef = searchTextRef || searchText;
    const searchTextModified = searchTextToRef ? { 
      districtSearchTerm: { $search: searchTextToRef } 
        // { title: { $search: searchTextToRef } },
    } : {};
    try {
      const res = await feathers.getService('measures').find({ query: {
        election: election,
        $sort: { createdAt: -1, name: 1 },
        ...(statusModified ? { coverageStatus: statusModified } : {}),
        ...(completionModified ? { status: completionModified } : {}),
        ...searchTextModified,
        $limit: measurePerPage,
        $skip: activePageToUse * measurePerPage
      }})
      setData(res.data)
      setTotalNumber(res.total);
    } catch(err) {
      console.error(err)
    } finally {
      setDataLoading(false);
    }
  }, [ activePage, measurePerPage, dataLoading, feathers, status, searchText, completion ])

  useEffect(() => {
    if(feathers) loadData()
  }, [ activePage, feathers ])

  /* Table render config */
  const labelsForField = {
    title: 'Title',
    district: 'District',
    descriptionShort: 'Short description',
    coverageStatus: 'Status',
    results: 'Results linked',
    lastUpdated: 'Last updated'
  }
  const renderField = (label, value, index) => {
    if(label === 'result') {
      const numerator = typeof(value?.numOptionsLinked) === 'undefined' ? 0 : value?.numOptionsLinked;
      const denom = typeof(value?.numOptions) === 'undefined' ? 0 : value?.numOptions;
      const percentage = typeof(value?.percentageLinked) === 'undefined' ? 0 : value?.percentageLinked;
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant='body1' style={{ opacity: 1.0, fontSize: '12px' }}>{numerator}/{denom} Options Linked</Typography>
          <ProgressBar percentage={percentage} />
        </div>
      )
    } else if(label === 'title') {
      return (
        <Typography
          key={index}
          variant='h4'
          style={{ gridColumn: index + 2 }}
        >
          {value}
        </Typography>
      );
    } else if(label === 'coverageStatus') {
      return (
        <div key={index} style={{ display: 'flex', flexDirection: 'column', gap: '0px', gridColumn: index + 2 }}>
          <Typography
            variant='body1'
          >
            {value?.coverageStatus}
          </Typography>
          <Typography
            variant='body2'
            style={{ opacity: 0.7 }}
          >
            {value?.completionStatus}
          </Typography>
        </div>
      );
    } else {
      return (
        <Typography
          key={index}
          variant='body1'
          style={{ gridColumn: index + 2 }}
        >
          {value}
        </Typography>
      );
    }
  }

  const dataMapped = data.map(measure => ({
    onClick: () => history.push(`/elections/${election}/measures/${measure._id}`),
    title: measure.title,
    descriptionShort: measure.descriptionShort || 'No description.',
    district: measure.district?.longName,
    ...(resultsVisible
      ? { result: measure.result }
      : { 
        coverageStatus: {
          coverageStatus: (
            measure.coverageStatus === 'future-coverage' 
              ? 'In draft' 
              : measure.coverageStatus === 'no-coverage'
                ? `Won't cover`
                : 'Published'
          ),
          completionStatus: measure.status === 'complete' ? 'Ready to review' : 'Not complete',
        }
      }
    ),
    lastUpdated: moment(measure.updatedAt).fromNow(),
  }))

  const { onClick, ...columnFields } = (dataMapped[0] || {});

  return (
    <>
      <Wrapper>
        <DataDetailToolbar
          style={{ marginBottom: '24px' }}
          onBack={{ to: {
              pathname: `/elections/${election}`,
            }}}
          navTree={[
            {
              text: 'Elections',
              to: {
                pathname: `/elections`,
              }
            },
            {
              text: electionData?.name || 'Election',
              to: {
                pathname: `/elections/${election}`,
              }
            },
            { text: 'Ballot Referendums' }
          ]}
          actionButtonsComponent={
            addPermissions
              ? <Button variant='contained' color='primary' onClick={() => setMeasureModalOpen(true)}>New referendum</Button>
              : <div/>
          }
        />
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '24px', gap: '12px' }}>
          <TextField
            label='Search'
            variant='outlined'
            size='small'
            style={{ width: '200px' }}
            InputProps={{
              endAdornment: <SearchIcon />
            }}
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value)
              loadData(0, status, completion, e.target.value)
            }}
          />
          <TypeDropdown
            value={completion || 'all'}
            options={[
              { type: 'all', title: 'All' },
              { type: 'incomplete', title: 'Not complete' },
              { type: 'complete', title: `Ready to review` },
            ]}
            onChange={(newType) => {
              const newQuery = qs.stringify({ ...qs.parse(window.location.search, { ignoreQueryPrefix: true }), completion: newType, page: 1 })
              window.history.pushState({}, '', `${window.location.pathname}?${newQuery}`)
              loadData(0, status, newType)
            }}
          />
          <TypeDropdown
            value={status || 'all'}
            options={[
              { type: 'all', title: 'All' },
              { type: 'future-coverage', title: 'In draft' },
              { type: 'no-coverage', title: `Won't cover` },
              { type: 'coverage', title: 'Published' },
            ]}
            onChange={(newType) => {
              const newQuery = qs.stringify({ ...qs.parse(window.location.search, { ignoreQueryPrefix: true }), status: newType, page: 1 })
              window.history.pushState({}, '', `${window.location.pathname}?${newQuery}`)
              loadData(0, newType, completion)
            }}
          />
        </div>
        {
          dataLoading &&
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <LoadingSpinner />
          </div>
        }
        {
          !dataLoading && dataMapped?.length > 0 &&
          <>
            <TableGrid>
              <Heading>
                {
                  Object.entries(columnFields).map(([ key, value ], index) => (
                    <Typography variant='h5' style={{ gridColumn: index + 2 }}>{labelsForField[key] || ''}</Typography>
                  ))
                }
              </Heading>
              {
                dataMapped.map(({ onClick, ...office }, i) => (
                  <Row key={i} onClick={onClick}>
                    {
                      Object.entries(office).map(([ fieldName, fieldValue ], index) => renderField(fieldName, fieldValue, index))
                    }
                    <ChevronRightButton />
                  </Row>
                ))
              }
            </TableGrid>
            {
              totalNumber > measurePerPage &&
              <PaginationRow>
                <PaginationStatus
                  activePage={activePage + 1}
                  items={totalNumber}
                  itemsPerPage={measurePerPage}
                  itemName='race'
                />
                <PaginationControl
                  activePage={activePage + 1}
                  items={totalNumber}
                  itemsPerPage={measurePerPage}
                  onChange={(pg) => setActivePage(pg - 1)}
                />
              </PaginationRow>
            }
          </>
        }
        {
          !dataLoading && dataMapped?.length === 0 &&
          <div style={{ width: 'calc(100% - 36px)', padding: '36px', dislay: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant='h3' style={{ opacity: 0.7, letterSpacing: '1px', textAlign: 'center' }}>
              NO BALLOT REFERENDA
            </Typography>
          </div>
        }
      </Wrapper>
      {
        measureModalOpen &&
        <Modal onClose={() => setMeasureModalOpen(false)}>
          <CreateMeasureModal
            election={electionData}
            redirectOnFinish={true}
            onClose={() => setMeasureModalOpen(false)}
          />
        </Modal>
      }
    </>
  )
}

const sidePadding = '20px';

const Wrapper = styled.div`
  width: calc(100% - 24px - 24px);
  height: calc(100% - 24px * 2);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: stretch;
`;

const PaginationRow = styled.div`
  margin: 18px 0 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const TableGrid = styled.div`
  display: grid;
  grid-template-columns:
    ${sidePadding}
    minmax(150px, 1fr)
    minmax(250px, 2fr)
    minmax(150px, 1fr)
    minmax(150px, 1fr)
    minmax(150px, 1fr)
    20px
    ${sidePadding};
  grid-row-gap: 10px;
  grid-column-gap: 4px;
`

const Heading = styled(TableGrid)`
  grid-column: 1 / -1;
`

const Row = styled(TableGrid)`
  grid-column: 1 / -1;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  background-color: #FFFFFF;
  padding: 10px 0;
  min-height: 50px;
  align-items: center;

  &:hover {
    cursor: pointer;
    background-color: #F8F8F8;
  }
`

export default Measures;
