import React from 'react';
import styled from 'styled-components';
import { ExpandableContent } from '../ExpandableContent';
import { StyledMarkdown } from '../StyledMarkdown';
import { SourceList } from '../SourceList';

const BioTile = ({
  title,
  bio,
  sources,
  style
}) => (
  <ExpandableContent
    title={title}
    chevronStyle={{ height: '16px', width: '16px' }}
    chevronVariant='outlined'
    style={{ padding: '8px 0'}}
  >
    <div style={{ marginTop: '8px' }}>
      <StyledMarkdown children={bio}/>
      <SourceList sources={sources} style={{ marginTop: '4px' }} />
    </div>
  </ExpandableContent>
)

export { BioTile };
