import React from 'react';
import CandidateNoImage from './candidate-icon.png';
import styled from 'styled-components';

const CandidateNamePhoto = ({
  name = 'Candidate',
  photo,
  style
}) => {
  return (
    <Wrapper style={style}>
      {
        <img src={photo || CandidateNoImage}/>
      }
      <p>{name}</p>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 36px;

  p {
    color: #707683;
    font-size: 16px;
  }

  img {
    height: 30px;
    width: 30px;
    overflow: 0;
    margin-right: 6px;
    border-radius: 15px;
  }
`;

const TileInner = styled.div`
  ${props => props.left ? `flex: 1;` : ``}
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font-size: 20px;
    margin: 0 0 8px;
  }
`;

export { CandidateNamePhoto };
