import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
    root: {
      backgroundColor: 'transparent',
      '& .MuiSelect-select': {
        backgroundColor: 'transparent',
        padding: '4px 30px 2px 8px',
        fontSize: '12px'
      },
    }, 
    outlined: {
      padding: '4px 30px 2px 8px',
    },
    notchedOutline: {
      borderColor: '#AAAAAA',
      borderRadius: '18px'
    }
  });
  
const TypeDropdown = ({
    value,
    options,
    onChange,
    placeholder
  }) => {
    const handleIssueChange = (event) => {
      event.stopPropagation();
      if(event.target.value === 'placeholder') return;
      onChange(event.target.value);
    };
    const classes = useStyles();
  
    return (
      <Select className={classes.root} value={value || 'placeholder'} size='small' onChange={handleIssueChange} variant='outlined' input={
        <OutlinedInput classes={{ notchedOutline: classes.notchedOutline }} />
      }>
        {
          placeholder &&
          <MenuItem value={'placeholder'}>
            <Typography variant='body1' style={{ fontSize: '14px' }}>{placeholder}</Typography>
          </MenuItem>
        }
        {options.map(({ type, title }) => (
          <MenuItem style={{whiteSpace: 'normal', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }} key={type} value={type}>
            <Typography variant='body1' style={{ fontSize: '14px' }}>{title}</Typography>
          </MenuItem>
        ))}
      </Select>
    );
  }

export { TypeDropdown };