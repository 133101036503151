import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { onClickOrLink } from '../../app/util';

const CTAButton = ({
  onClick = () => {},   // if object, with parameter `to`, i.e. to { to: `/episodes/123` }, will function as a link
  disabled = false,
  style = {},
  value,
  children,
  dropShadowEnabled = true,
  hoverHighlightColor = undefined,
  mode = 'expand',       // `expand` fills the parent container, `normal` fits the text, `pill` rounds the corners
  ...rest
}) => {
  const {
    backgroundColor,
    ...styleRest
  } = style;
  return (
    <Button
      disabled={disabled}
      style={styleRest}
      mode={mode}
      backgroundcolor={backgroundColor}
      hoverhighlightcolor={hoverHighlightColor}
      dropshadowenabled={dropShadowEnabled ? 1 : 0}
      {...rest}
      {...onClickOrLink(onClick)}
    >
      {value || children}
    </Button>
  );
}

const Button = styled.div`
  background-color: ${ props => {
    if(props.backgroundcolor) return props.backgroundcolor;
    else if(props.mode === 'pill') return `#453DB7`;
    else return `#22034F`;
  }};
  border-color: ${ props => props.mode === 'pill' ? `#453DB7` : `#22034F`};
  border-radius: ${ props => props.mode === 'pill' ? `60px` : `8px`};
  text-decoration: none;

  color: white;
  font-size: 16px;
  letter-spacing: 0.5px;
  font-family: HouschkaRounded, HouschkaRoundedAlt;
  display: flex;
  justify-content: center;
  align-items: center;

  ${ props => props.mode === 'expand' ? `width: ${props.width || '100%'}` : ``}
  display: block;
  font-weight: bold;
  text-align: center;
  padding: ${ props => props.mode === 'pill' ? `8px 16px` : `15px 20px`};
  transition: all 0.1s;
  cursor: pointer;

  ${props => props.disabled
    ? ``
    : `&:hover {
      ${props => props.dropshadowenabled ? `box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.24);` : ``}
      ${props => props.hoverhighlightcolor ? `background-color: ${props.hoverhighlightcolor};` : ``}
    }`
  }

  &[disabled] {
    opacity: 0.3;
    cursor: default;
  }
`;

export { CTAButton };
