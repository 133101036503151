import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { TextInput, CTAButton, CandidatePhoto } from '../../components';
import styles from './CandidatesList.css'
import Table from '../../components/Table/Table';
import styled from 'styled-components';
import lodash from 'lodash';


import { ReactComponent as RepublicanIcon } from '../../images/republican-icon.svg';
import { ReactComponent as DemocratIcon } from '../../images/dem-icon.svg';
import { ReactComponent as NonpartisanIcon } from '../../images/nonpartisan-icon.svg';

const CandidateListWrapper = styled.div`
  flex: 1;
`

const CandidateListHeaderWrapper = styled.div`
  /* background-color: green; */
  display: flex;
  flex-direction: row;
  margin: 30px;
  form {
    input {
      min-width: 300px;
      margin-left: 40px;
      border: 1px solid grey;
      height: 50px;
    }
    .CTAButton {
      min-width: 100px;
      /* height: 50px; */
    }
  }
  .search-bar {
    /* background-color: pink; */
    /* height: none; */
    /* min-height: 0; */
    /* margin-bottom: 0; */
    /* align-self: center; */
    height: unset;
    margin-bottom: unset;
  }
  /* align-: center; */
`

const CandidateListBodyWrapper = styled.div`
  a {
    text-decoration: none;
  }
  background-color: #EAEFFD;
  .new-candidate-button {
    color: white;
    background-color: #4341B1;
    max-width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    padding: 10px;
    border-radius: 10px;
  }
  padding: 40px;
`

const TableWrapper = styled.div`
  table {
    border-spacing: 0;
    /* width: 100%; */
  }
  th {
    text-align: left;
    background: white;
    padding: 20px;
  }
  td {
    padding: 20px;
  }
  tbody {
    tr:nth-child(odd) {
      background: #EEEEEE;
    }
    tr:hover {
      background: #4341B1;
      color: white;
      cursor: pointer;
    }
  }
  .candidate-photo-bubble {
    width: 50px;
    border-radius: 25px;
    height: 50px;
    overflow: hidden;
  }
  margin-top: 40px;
  background-color: white;
  .candidate-letter-bubble {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: #B4B3F8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.5em;
  }
  a {
    text-decoration: none;
    padding: none;
    margin: none;
  }
`

const mapStateToProps = (state) => {
  const idList = state.candidates.list || [];
  const candidates = idList.map((id) => state.candidates.byId[id]);
  return {
    canFetch: (state.feathers.staging ? true : false) && (state.candidates.error ? false : true),
    loggedIn: state.user.loggedIn,
    candidates
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCandidates: () => console.log('TODO'),
    onLoad: () => console.log('TODO')
  }
}

class CandidatesList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      limit: 40,
      formInputs: { searchTerm: '' }
    };
  }

  componentDidMount = () => {
    this.props.onLoad()
  }

  changeHandler = event => {
    const name = event.target.name;
    const value = event.target.value;

    const formInputs = this.state.formInputs;
    formInputs[name] = value;
    this.setState(Object.assign({}, this.state, formInputs));
  };

  searchByName = (e) => {
    e.preventDefault();
    const { page, limit, formInputs } = this.state;
    console.log('SEARCH BY NAME')
    console.log(formInputs.searchTerm)
    this.props.getCandidates(page, limit, formInputs.searchTerm);
  }
  getPartyIcon = (party) => {
    switch(party) {
      case 'R':
        return <RepublicanIcon />
      case 'D':
        return <DemocratIcon />
      default:
        return <NonpartisanIcon />
    }
  }
  goToCandidatePage = (candidate) => {
    const { _id, official } = candidate;
    this.setState({redirect: true, redirectedCandidateId: _id, redirectedCandidateOfficial: official});
  }

  render() {
    // if(!this.props.loggedIn) {
    //   return <Redirect to='/' />
    // }

    let candidatesToDisplay;
    if(this.props.candidates && this.props.candidates.length > 0) {
      candidatesToDisplay = true;
    } else {
      if(this.props.canFetch) {
        candidatesToDisplay = false;
        const { page, limit } = this.state;
        this.props.getCandidates(page, limit);
      }
    }

    if (this.state.redirect) {
      console.log(this.state)
      return <Redirect push to={`/candidates/${this.state.redirectedCandidateOfficial}?candidate=${this.state.redirectedCandidateId}`} />;
    }


    return (
      <CandidateListWrapper>
        <CandidateListHeaderWrapper>
          <h1>Candidates</h1>
          <form className='search-bar' onSubmit={this.searchByName}>
          <TextInput
            onChange={this.changeHandler}
            name='searchTerm'
            value={this.state.formInputs.searchTerm}
            placeholder='Search Candidate Name'
          />
          <CTAButton value='Search' onClick={this.searchByName} disabled={false}/>
        </form>
        </CandidateListHeaderWrapper>
        <CandidateListBodyWrapper>
          <Link to='/createCandidate'><div className='new-candidate-button'>Create a New Candidate</div></Link>
          <TableWrapper>
            <table>
              <thead>
                <tr>
                  <th className="candidate-image"></th>
                  <th>Candidate Name</th>
                  <th>Party</th>
                  <th>Office Name</th>
                  <th>District</th>
                  <th>Interviewed?</th>
                  <th>Progress</th>
                </tr>
              </thead>
              <tbody>
                {
                  candidatesToDisplay &&
                  this.props.candidates.map((candidate) => {

                    let candidateImage
                    if (!candidate.photoPathFace) {
                      candidateImage = <div className="candidate-letter-bubble"> {candidate.name.charAt(0).toUpperCase()} </div>
                    }
                    else {
                      candidateImage = <CandidatePhoto className='candidate-photo-bubble' src={candidate.photoPathFace} />
                    }

                    let candidateId = candidate._id
                    return (
                      <tr key={candidate._id} onClick={() => this.goToCandidatePage(candidate)}>
                          <td>{candidateImage}</td>
                          <td>{candidate.name}</td>
                          <td>{this.getPartyIcon(lodash.get(candidate, 'party', '?'))}</td>
                          <td>{lodash.get(candidate, 'race.officeName', '?')}</td>
                          <td>{lodash.get(candidate, 'race.district.displayName', '?')}</td>
                          <td>{candidate.interviewed ? 'Yes' : 'No'}</td>
                          <td>{candidate.progress}%</td>
                          <td><Link to={`/candidates/${candidate.official}?candidateId=${candidate._id}`}>Edit</Link></td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </TableWrapper>
        </CandidateListBodyWrapper>
      </CandidateListWrapper>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidatesList);
