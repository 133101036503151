import React, { Component, useEffect, useState } from 'react';
import { useFeathers, isPermitted } from '../../app/util';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import {
  CTAButton,
  LoadingSpinner,
  DataDetailToolbar,
  TranslationPairUpload
 } from '../../components'
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';


const Translations = () => {
  const feathers = useFeathers();
  const user = useSelector(state => state.user);
  const { key } = useParams();

  return (
    <Wrapper>
      <DataDetailToolbar
        title={'Upload Translations'}
      />
      <TranslationPairUpload style={{ alignSelf: 'center', marginTop: '40px' }}/>

      <ReactTooltip place='left' effect='solid' type='light'/>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: calc(100% - 24px - 36px);
  display: flex;
  flex-direction: column;
  padding: 36px 36px 36px 24px;
  align-items: stretch;
`;

const Section = styled.div`
  margin: 0 0 48px;
  width: 100%;
  display: flex;
  h1 {
    font-size: 24px;
    margin: 0 0 0;
  }
`
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: -10px;

  div {
    margin: 0 0 0 0;
  }
`


export default Translations;
