import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Placeholder } from './candidate-no-image.svg';
import { ReactComponent as GreenCheckSvg } from './green-check.svg';

const CirclePhoto = ({
  photoPath,
  backwardsCompatible = false,        // if true, will try to append the aws bucket location
  highlight,
  size='medium',
  checkmark,
  style={},
  onClick
}) => {
  const photoResolved = photoPath;
  let pxSize;
  if(size === 'mini') pxSize = 38;
  else if(size === 'small') pxSize = 48;
  else if(size === 'medium') pxSize = 64;
  else if(size === 'large') pxSize = 84;
  else pxSize = 128;

  const pxSizeInner = pxSize - 4*2;
  const pxSizeCheck = (pxSize - 4) / 2;

  return (
    <Wrapper
      style={style}
      onClick={onClick}
    >
      {
        checkmark &&
        <GreenCheck pxSize={pxSizeCheck}/>
      }
      <ImageOuter highlight={highlight ? 1 : 0} pxSize={pxSize}>
        <ImageInner highlight={highlight ? 1 : 0} pxSizeInner={pxSizeInner} style={onClick ? { cursor: 'pointer' } : {}}>
          {
            (photoResolved)
            ? <img src={photoResolved}/>
            : <Placeholder/>
          }
        </ImageInner>
      </ImageOuter>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
`;

const ImageOuter = styled.div`
  padding: 2px;
  height: ${props => props.pxSize}px;
  width: ${props => props.pxSize}px;
  border-radius: calc(${props => props.pxSize}px);

  ${props => props.highlight ? `background: linear-gradient(133.22deg, ${props.theme.palette.tertiary} -0.72%, ${props.theme.palette.accent} 76.4%);` : ``}
`;

const ImageInner = styled.div`
  padding: 4px;
  height: ${props => props.pxSizeInner}px;
  width: ${props => props.pxSizeInner}px;
  border-radius: calc(${props => props.pxSizeInner}px);

  ${props => props.highlight ? `background-color: ${props.theme.palette.background};` : `background-color: #FFFFFF;`}

  svg, img {
    height: ${props => props.pxSizeInner}px;
    width: ${props => props.pxSizeInner}px;
    border-radius: calc(${props => props.pxSizeInner}px);
    overflow: hidden;
  }
`;

const GreenCheck = styled(GreenCheckSvg)`
  position: absolute;
  right: 2px;
  bottom: 2px;
  width: ${props => props.pxSize}px;
  height: auto;
`

export { CirclePhoto };
