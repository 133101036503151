import React from 'react';
import styled from 'styled-components';
import { LoadingSpinner } from '../LoadingSpinner';
import TextField from '@material-ui/core/TextField';
import { useFormInputs } from '../../app/util';
import { CTAButton } from '../CTAButton';
import Typography from '@material-ui/core/Typography';
import useKeypress from 'react-use-keypress';
import { FieldButtonToolbar } from '../FieldButtonToolbar';

export const FieldInlineEdit = ({
  multiline = false,
  loading = false,
  onSave,
  onReset,
  onCancel,
  style = {},
  label,
  seedValue = '',
  inputComponent
}) => {
  const [ formInputs, onChange ] = useFormInputs({ value: seedValue });

  useKeypress('Enter', () => {
    if(!multiline && !loading) onSave(formInputs.value)
  });
  useKeypress('Escape', () => {
    if(!loading) onCancel()
  });

  return (
    <Wrapper style={style}>
      <TitleRow multiline={multiline ? 1 : 0}>
        <Typography variant={multiline ? 'h3' : 'h5' }>
          {label}
        </Typography>
        <FieldButtonToolbar
          onSave={() => onSave(formInputs.value)}
          onReset={onReset}
          onCancel={onCancel}
          disabled={loading}
        />
      </TitleRow>
      {
        inputComponent
        ? inputComponent
        : (
          <TextField
            name='value'
            value={formInputs.value}
            variant='outlined'
            onChange={onChange}
            size='small'
            multiline={multiline}
            disabled={loading}
          />
        )
      }
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;


  margin-bottom: 4px;
  justify-content: space-between;
`
