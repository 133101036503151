import React, { Component, useEffect, useState } from 'react';
import { useFeathers, isPermitted } from '../../app/util';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {
  CTAButton,
  LoadingSpinner,
  HintTile,
  PaginationStatus,
  PaginationControl,
  CFSelectInput
 } from '../../components'
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';

const FinanceHookups = ({ electionKey }) => {
  const feathers = useFeathers();
  const limitPerPage = 25;

  const [ numCandidates, setNumCandidates ] = useState(0);
  const [ results, setResults ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ syncing, setSyncing ] = useState(false);
  const [ activePage, setActivePage ] = useState(0);
  const [ error, setError ] = useState(null);

  const [ newFinanceInput, setNewFinanceInput ] = useState(null);

  const loadData = async () => {
    setError(null)
    setLoading(true)
    try {
      const probe = await feathers.getService('candidates').find({
        query: { $limit: 1, election: electionKey, 'finance.filerId': null }
      })
      setNumCandidates(probe.total);
      console.log('NumCandidates', probe.total)

      const finances = await feathers.getService('finances').find({ query: {
        $limit: limitPerPage,
        $skip: activePage * limitPerPage,
        election: electionKey
      }})
      setResults(finances)
    } catch(err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if(feathers) loadData()
  }, [ feathers, activePage ])

  const conclusiveMatches = (results || []).filter(cd => cd.financeOptions?.length === 1)
                                           .map(cd => ({ ...cd, filerId: cd.financeOptions[0].filerid }));
  const inconclusiveMatches = (results || []).filter(cd => cd.financeOptions?.length !== 1);

  const syncCandidates = async (candidates) => {
    // for conclusive candidates, performs the matching operation
    setSyncing(true)
    try {
      console.log(candidates)
      for(let i = 0; i < candidates.length; i++) {
        const res = await feathers.getService('finances').patch(candidates[i]._id, {
          filerId: candidates[i].filerId
        })
        console.log('res', res)
      }
      const candidateIds = candidates.map(cd => `${cd._id}`);
      const newResults = results.filter(r => !candidateIds.includes(`${r._id}`));
      setResults(newResults);
    } catch(err) {
      console.log(err)
      setError(err)
    } finally {

      setSyncing(false)
    }
  }

  return (
    <>
      {
        !loading &&
        <div style={{ margin: '12px 0 12px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
          <Button variant='contained' color='primary' onClick={() => setActivePage(activePage + 1)}>
            Next batch
          </Button>
          <Typography variant='body1' style={{ marginTop: '2px' }}>Batch {activePage + 1} out of {Math.ceil(numCandidates/limitPerPage)}</Typography>
        </div>
      }
        {
          error &&
          <HintTile>
            {error.message}
          </HintTile>
        }
        {
          loading
          ? <LoadingSpinner />
          :
            <>
              {
                conclusiveMatches.length > 0 &&
                <div style={{ margin: '12px 0 12px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                  <div>
                    <Typography variant='h3'>{conclusiveMatches.length} Matched</Typography>
                    <Typography variant='body1'>
                      {conclusiveMatches.length} out of {results.length} candidates had conclusive matches in the system. Click "Sync" to match those candidates.
                    </Typography>
                  </div>
                  <div>
                    <Button variant='contained' color='primary' onClick={() => syncCandidates(conclusiveMatches)} disabled={syncing}>
                      {syncing ? '...' : 'Sync'}
                    </Button>
                  </div>
                </div>
              }
              {
                inconclusiveMatches.length > 0 &&
                <>
                  <Typography variant='h3'>{inconclusiveMatches.length} Unmatched</Typography>
                  <Typography variant='body1'>
                    {inconclusiveMatches.length} out of {results.length} candidates had inconclusive matches in the system.
                  </Typography>
                  <Table>
                    <HeaderCell>
                      Candidate
                    </HeaderCell>
                    <HeaderCell>
                      # Matches
                    </HeaderCell>
                    <HeaderCell>
                      Manual Link
                    </HeaderCell>
                    {inconclusiveMatches.map(cd => <FinanceSelect
                      candidate={cd}
                      key={cd._id}
                      syncCandidates={syncCandidates}
                      saving={syncing}
                    />)}
                  </Table>
                </>
              }
            </>
        }
      </>
  )
}

const FinanceSelect = ({ candidate, syncCandidates, saving }) => {
  const [ financeInput, setFinanceInput ] = useState(null)
  const onChangeFinance = (e) => setFinanceInput(e.target.value)

  return (
    <>
      <Cell>
        <Typography variant='body1' style={{ fontWeight: 'bold' }}>{candidate.name}</Typography>
        <Typography variant='body1' style={{ fontSize: '13px' }}>
          {candidate?.race?.officeName} • {candidate?.race?.district?.longName}
        </Typography>
      </Cell>
      <Cell>
        <Typography variant='body1'>{(candidate.financeOptions || []).length} matches</Typography>
      </Cell>
      <Cell style={{ display: 'flex', alignItems: 'center' }}>
        <CFSelectInput
          onChange={onChangeFinance}
          value={financeInput}
          candidateId={candidate._id}
          style={{ width: 'calc(300px)' }}
        />
        {
          financeInput &&
          <Button
            style={{ marginLeft: '36px' }}
            variant='contained'
            color='primary'
            disabled={saving}
            onClick={() => syncCandidates([{ _id: candidate._id, filerId: financeInput.filerId }])}
          >
            { saving ? '...' : 'Save' }
          </Button>
        }

      </Cell>
    </>
  )
}


const Table = styled.div`
border: solid 1px #EEEEEE;
border-radius: 8px;
background-color: #FFFFFF;
display: grid;
grid-template-columns: 2fr 0.75fr 2fr;
`

const HeaderCell = styled.div`
background-color: #EEEEEE;
border-bottom: solid 1px #AAAAAA;
padding: 8px 12px;
display: flex;
justify-content: center;
`

const Cell = styled.div`
padding: 16px 12px;
`

export { FinanceHookups };
