import React from 'react';
import styled from 'styled-components';

import IconButton from '@material-ui/core/IconButton';
import Save from '@material-ui/icons/AssignmentTurnedIn';
import Cancel from '@material-ui/icons/Clear';
import Reset from '@material-ui/icons/Replay';
import ReactTooltip from 'react-tooltip';

const FieldButtonToolbar = ({
  onReset,
  onSave,
  onCancel,
  disabled
}) => {
  const buttonIconStyle = {
    height: '18px',
    width: '18px'
  }

  return (
    <ButtonWrapper>
      {
        onReset &&
        <IconButton
          size='small'
          onClick={onReset}
          disabled={disabled}
          style={{ marginLeft: '4px' }}
          data-tip='Reset to default'
        >
          <Reset style={buttonIconStyle}/>
        </IconButton>
      }
      {
        onCancel &&
        <IconButton
          size='small'
          onClick={onCancel}
          disabled={disabled}
          style={{ marginLeft: '4px' }}
          data-tip='Cancel'
        >
          <Cancel style={buttonIconStyle}/>
        </IconButton>
      }
      {
        onSave &&
        <IconButton
          size='small'
          onClick={onSave}
          disabled={disabled}
          style={{ marginLeft: '4px' }}
          color='secondary'
          data-tip='Save'
        >
          <Save style={buttonIconStyle} color='secondary'/>
        </IconButton>
      }
      <ReactTooltip place='left' effect='solid' type='light'/>
    </ButtonWrapper>
  )
}

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export { FieldButtonToolbar };
