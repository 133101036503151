import React from 'react';
import styled from 'styled-components';

const PaginationStatus = ({
  activePage = 1,               // indexing starts at 1
  items = 0,                    // the total # of items
  itemsPerPage = 10,
  itemName                      // race or candidate
}) => {
  if(items) {
    const lowerLim = (activePage - 1)*itemsPerPage + 1;
    const upperLim = Math.min((activePage)*itemsPerPage, items);
    return (
      <Text>
        <Text>Showing </Text>
        <TextBold>{`${lowerLim}-${upperLim}`}</TextBold>
        <Text> out of </Text>
        <TextBold>{items} </TextBold>
        {itemName && <Text>{items === 1 ? itemName : `${itemName}s`}</Text>}
      </Text>
    )
  } else {
    return (
      <Text>No results</Text>
    )
  }
}

const Text = styled.span`
  color: #707683;
  font-size: 18px;
`;

const TextBold = styled(Text)`
  font-weight: bold;
`


export { PaginationStatus };
