import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import IconButton from '@material-ui/core/IconButton';
import TranslateIcon from '@material-ui/icons/Translate';
import { useFeathers } from '../../app/util';
import moment from 'moment';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const { languageConfig } = require('../../language.config');

const TranslationPairDownload = ({
  serviceName,
  objectId,
}) => {
  const feathers = useFeathers();
  const [ modalOpen, setModalOpen ] = useState(false);

  const downloadTranslationEdits = async (language) => {
    setModalOpen(false)
    let data;
    try {
      data = await feathers.getService('translation-pairs').get(objectId, {
        query: {
          language,
          service: serviceName
        }
      })
    } catch(err) {
      console.log('err')
      console.log(err)
      return;
    }

    const ws = XLSX.utils.aoa_to_sheet(data);
    ws["!cols"] = [ { wch: 10 }, { wch: 10}, { wch: 20 }, { wch: 60 }, { wch: 60 }, { wch: 60 }, ];
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
		/* generate XLSX file and download to client */
    XLSX.writeFile(wb, `${language}-${serviceName}-${objectId}-${moment().format()}.xlsx`);
  }

  return (
    <>
      <IconButton data-tip='Download translation pairs' onClick={() => setModalOpen(true)}>
        <TranslateIcon/>
      </IconButton>
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <DialogTitle id="alert-dialog-title">{"Select a language"}</DialogTitle>
        <List sx={{ pt: 0 }}>
          {languageConfig.map(({ code, name }) => (
            <ListItem button onClick={() => downloadTranslationEdits(code)} key={code}>
              <ListItemText primary={name} />
            </ListItem>
          ))}
        </List>
      </Dialog>
    </>
  )
}

export { TranslationPairDownload };
