import React from 'react';
import styles from './MeasureBreakdown.css';
import ReactMarkdown from 'react-markdown';
import { ReactComponent as SupportersCheck } from './supporters-check.svg';
import { ReactComponent as OpponentsX } from './opponents-x.svg';
import { SourceList } from '../lower-order';

export default function(props) {
  const { whatItMeans, supportersSay, opponentsSay, sources, commentaryAvailable } = props;
  return (
    <div className='measure-breakdown'>
      <h1>The Branch Breakdown</h1>
      {
        whatItMeans &&
        <div className='measure-bd-section'>
          <h2>What it means</h2>
          <ReactMarkdown>{whatItMeans}</ReactMarkdown>
        </div>
      }
      <div className='hr' />
      <div className='supporters-opponents'>
        <div className='measure-bd-section'>
          <div className='svg-wrap'><SupportersCheck /></div>
          <h2>{commentaryAvailable ? `Supporters of the bill say` : `A "yes" vote means`}</h2>
          <ReactMarkdown >{supportersSay || 'No information.'}</ReactMarkdown>
        </div>
        <div className='measure-bd-section'>
          <div className='svg-wrap'><OpponentsX /></div>
          <h2>{commentaryAvailable ? `Opponents of the bill say` : `A "no" vote means`}</h2>
          <ReactMarkdown>{opponentsSay || 'No information.'}</ReactMarkdown>
        </div>
      </div>
      {
        sources && sources.length > 0 &&
        <div className='sources measure-bd-section'>
          <SourceList sources={sources}/>
        </div>
      }
    </div>
  )
}
