import React from "react";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import { ViewDiff } from "../ViewDiff";

const displayNames = {
	title: 'Title',
	descriptionShort: 'Short Description',
	ballotText: 'Appears on ballot as',
	actNumbers: 'Act numbers',
	whatItMeans: 'What it means',
	supportersSay: 'What a yes vote means',
	opponentsSay: 'What a no vote means',
}

const ViewMeasureDiff = ({
	diff
}) => {
	// diff is expected to have properties of measure:
	// title, description, supporters, opponents, sources, 

	const sections = [
		'title',
    'descriptionShort',
    'ballotText',
    'actNumbers',
    'whatItMeans',
    'supportersSay',
    'opponentsSay',
	]

  return (
		<Wrapper>
			{sections.map((key, index) => {
				return (
					<Section key={key}>
						<Typography variant='h5' color='textPrimary'>{displayNames[key]}</Typography>
						<ViewDiff diff={diff?.[key]} />
					</Section>
				)
			})}
		</Wrapper>
	);
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	width: 100%;
	gap: 24px;
`

const Section = styled.div`

`

const List = styled.ul`
	margin: 0;
	padding-inline-start: 16px;
`

const Seperator = styled.div`
	height: 1px;
	width: 100%;
	background-color: #E0E0E0;
`

export { ViewMeasureDiff };