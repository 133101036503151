import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import { ReactComponent as PolicyViewArrow } from './policy-view-arrow.svg';

/*
* policyViewMarkup - the text which is marked up
* backupText
* showPolicy(policyKey)
*/

class PolicyViewMarkdown extends Component {
  render() {
    const onClick = (e, policyKey) => {
      e.stopPropagation();
      this.props.showPolicy(e, policyKey)
    }
    const { policyViewMarkup, backupText } = this.props;
    let text;
    if(policyViewMarkup) {
      try {
        text = policyViewMarkup;
        //An array of complete tags
        const policyTags = text.match(/<\s*pv[^>]*>(.*?)<\s*\/pv>/gi);

        //for each of the tags, replace it with link information
        policyTags.forEach((tag, i) => {
          const [ wholeTag, policyKey, matchTerm ] = tag.match(/<\s*pv[^']*'(.*?)'\s*>(.*?)<\s*\/pv>/i);
          if(this.props.ignoreKeys.includes(policyKey)) {
            text = text.replace(tag, `${matchTerm}`);
          } else {
            text = text.replace(tag, `[${matchTerm}](${policyKey})`);
          }
        });
      } catch(e) {
        text = backupText;
      }
    } else {
      text = backupText;
    }

    return (
      <ReactMarkdown
        children={text}
        renderers={{link: PVTermComponent(onClick)}}
      />
    );
  }
}

function PVTermComponent(onClick = () => {}) {
  return (props) => {
    return (
      <span onClick={(e) => onClick(e, props.href)} style={{
        backgroundColor: '#F0EFF9',
        padding: '2px 1px 1px',
        borderBottom: '1px dashed rgb(34, 3, 79)',
        cursor: 'pointer',
        fontWeight: 'bold'
      }}>
        {props.children}
        <PolicyViewArrow style={{ marginLeft: '4px', marginBottom: '4px' }}/>
      </span>
    );
  };
}

const mapStateToProps = state => {
  return {
    ignoreKeys: []
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showPolicy: () => console.log('show')
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PolicyViewMarkdown);
