import React from 'react';
import Typography from '@material-ui/core/Typography';

export const Researcher = ({ style, className, user }) => {
  const display = user
    ? (user?.name ?? user?.firstName ?? user?.email)
    : 'None';
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px', ...(style || {}) }} className={className}>
      <Typography variant={'body1'}>{display}</Typography>
    </div>
  )
}