import React from 'react';
import { AddButton } from '../AddButton';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';

/* Expected Props
* disabled - true or false
* name - the name of the form element (also it's key within the input state manager)
* value - the value of the form element
* emptyItem - an object containing the format and value of a new list item
* renderItem - a component or function that renders a single list item
* onChange - the function to call when the state should be updated
* enableReorder - true or false
* onAdd - function that triggers when the "add" button is clicked.
*   Defaults to adding a new "emptyItem" to the list
* emptyList - a component to display when there are no items in the list. (optional)
* addText - defaults to 'Add Item'
*/

export default class InputList extends React.Component {
  changeHandlerEntireIndex = event => {
    const name = event.target.name;
    const value = event.target.value;

    const wholeElementValue = this.props.value;
    const wholeElementName = this.props.name;

    const [ field, indexAsStr ] = name.split('-');
    const index = parseInt(indexAsStr);

    const newArray = [].concat(
      wholeElementValue.slice(0,index),
      [ value ],
      wholeElementValue.slice(index + 1)
    )

    this.props.onChange({ target: {
      name: wholeElementName,
      value: newArray
    }});
  }
  changeHandlerGroup = event => {
    const name = event.target.name;
    const value = event.target.value;

    const wholeElementValue = this.props.value;
    const wholeElementName = this.props.name;

    const [ field, indexAsStr ] = name.split('-');
    const index = parseInt(indexAsStr);
    const updateToEntryAtIndex = {};
    updateToEntryAtIndex[field] = value;
    const updatedEntry = {
      ...wholeElementValue[index],
      ...updateToEntryAtIndex
    }

    const newArray = [].concat(
      wholeElementValue.slice(0,index),
      [ updatedEntry ],
      wholeElementValue.slice(index + 1)
    )

    this.props.onChange({ target: {
      name: wholeElementName,
      value: newArray
    }});
  }

  addGroupElement = () => {
    const { value, emptyItem, onChange, name, onAdd } = this.props;
    if(onAdd) {
      onAdd()
    } else {
      let newValue = value.slice();
      newValue.push(emptyItem);

      onChange({ target: {
          name,
          value: newValue
        }});
    }
  }

  removeGroupElementAtIndex = (index) => {
    const { value, onChange, name } = this.props;
    let newValue = value;

    newValue.splice(index, 1);

    onChange({ target: {
      name,
      value: newValue.slice()
    }});
  }

  handleOnDragEnd = (result) => {
    if(result?.reason !== 'DROP' || !result?.destination) return;
    let source = result.source.index;
    let target = result.destination.index;

    var arr = this.props.value;
    var new_arr = this.props.value;

    [new_arr[source], new_arr[target]] = [arr[target], arr[source]];
    this.props.onChange( { target: { value: new_arr } } );
  }

  render() {
    const {
      name,
      disabled,
      value = [],
      renderItem,
      addText = 'Add item',
      enableReorder = true,
      separators = false,
      allowRemoveItem = true,
      allowAddItem = true,
      style = {},
      extraProps = {},
      emptyList,
      iconButtonStyles = {},
      rowStyles = {}
    } = this.props;

    const isDragDisabled = (!enableReorder || value.length <= 1);
    if(value?.length === 0 && emptyList) {
      return (
        <Wrapper style={style || {}}>
          {emptyList}
          <AddButton onClick={this.addGroupElement}>
            {addText}
          </AddButton>
        </Wrapper>
      )
    }

    return (
      <Wrapper style={style || {}}>
        <DragDropContext onDragEnd={this.handleOnDragEnd}>
          <Droppable droppableId='inputListItem'>
            { (provided) => (
              <InnerWrapper {...provided.droppableProps} ref={provided.innerRef} >
                {
                  value.map((item, i) => {
                    const renderItemProps = {
                      name,
                      disabled,
                      onChange: this.changeHandlerGroup,
                      onSetElement: this.changeHandlerEntireIndex,
                      ...(extraProps || {})
                    };

                    return (
                      <div key={`${name}-${i}`}>
                        {
                          i !== 0 && separators &&
                          <Separator />
                        }
                        <Draggable
                          draggableId={`${name}-${i}`}
                          index={i}
                          isDragDisabled={isDragDisabled}
                        >
                          { (provided, snapshot) => (
                            <Row
                              className='item-list-row'
                              key={`${name}-${i}`}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              isDragging={snapshot.isDragging}
                              rowStyles={rowStyles}
                            >
                              {renderItem(item, i, renderItemProps)}
                              {
                                allowRemoveItem &&
                                <IconButton
                                  size='small'
                                  style={{
                                    position: 'absolute',
                                    top: '0%',
                                    right: '0%',
                                    marginTop: '-12px',
                                    marginRight: '-12px',
                                    ...iconButtonStyles
                                  }}
                                  onClick={() => this.removeGroupElementAtIndex(i)}
                                >
                                  <CancelIcon />
                                </IconButton>
                              }

                            </Row>
                          )}
                        </Draggable>
                      </div>
                    );
                  })
                }
              </InnerWrapper>
            )}
          </Droppable>
        </DragDropContext>
        {
          allowAddItem &&
          <AddButton onClick={this.addGroupElement}>
            {addText}
          </AddButton>
        }
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
const InnerWrapper = styled.ul`
  width: 100%;
  padding-left: 0;
  margin: 0;
`
const Row = styled.li`
  display: flex;
  width: 100%;
  border-radius: 8px;
  flex-direction: ${props => props.rowStyles?.flexDirection ? props.rowStyles.flexDirection : 'row-reverse'};
  padding: ${props => props.rowStyles?.padding ? props.rowStyles.padding : ''};

  align-items: center;
  position: relative;

  ${props => props.isDragging ? 'padding: 20px;' : ''}
  ${props => props.isDragging ? 'background-color: #fbfbfb;' : ''}
`

const Separator = styled.div`
  height: 1px;
  background-color: #dddddd;
  width: 90%;
  margin: 24px auto;
`
