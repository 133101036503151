import React, { useEffect, useState, useMemo } from 'react';
import superagent from 'superagent';
import {isPermitted, useFeathers} from '../../app/util';
import { useSelector } from 'react-redux';
import {
  useParams,
  useLocation,
  useHistory
} from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';

import {
  CoveragePlanMap,
	LoadingSpinner,
	SupportedDistrictList,
  DataDetailToolbar,
  TypeDropdown,
  EditCoveragePlan
} from '../../components';
import styled from 'styled-components';
import moment from 'moment'
import Typography from '@material-ui/core/Typography';
import qs from 'qs';
import { IconButton } from '@material-ui/core';

const ElectionCoveragePlan = () => {
  const feathers = useFeathers();
  const electionKey = useParams().key;
  const user = useSelector(state => state.user);
  const editPerms = isPermitted(user, ['editor', 'super-admin']);
  const electionDataPreloaded = useSelector(state => state?.elections?.byKey[electionKey]);
  const [ election, setElection ] = useState(electionDataPreloaded);
  const [ electionImplementation, setElectionImplementation ] = useState(null)
  const [ loading, setLoading ] = useState(!Boolean(electionDataPreloaded));
  const [ districtsCount, setDistrictsCount ] = useState(null)
  const [ editingPlan, setEditingPlan ] = useState(false)
  const [ configForState, setConfigForState ] = useState(null)

  const location = useLocation()
  const history = useHistory()
  const { status, districtType } = qs.parse(location.search, { ignoreQueryPrefix: true })

  const onChangeStatusFilter = (newStatus) => {
    const newQuery = qs.stringify({status: newStatus, districtType })
    const newLocation = {...location, search: newQuery}
    history.push(newLocation)
  }

  const onChangeTypeFilter = (newType) => {
    const newQuery = qs.stringify({status , districtType: newType })
    const newLocation = {...location, search: newQuery}
    history.push(newLocation)
  }

  const tier2TypeOptions = useMemo(() => {
    if(configForState) {
      return [].concat(
        [{ type: 'all', title: 'All districts' }],
        configForState.types.state.map(type => ({ type, title: type.split('-').map(t => t.slice(0,1).toUpperCase() + t.slice(1).toLowerCase()).join(' ') }))
      )
    }
    else return [
      { type: 'all', title: 'All districts' },
    ]
  }, [ configForState ])

  const refreshElectionData = async () => {
    try {
      const newData = await feathers.getService('elections').get(
        electionKey
      );
      setElection(newData)
      const eiData = await feathers.getService('election-implementations').get(electionKey);
      setElectionImplementation(eiData)

      const configForState = (
        await superagent.get(`https://districts-microservice.herokuapp.com/api/v1/district-configurations/${newData.stateCode.toLowerCase()}`)
      )
      setConfigForState(configForState.body)
      setLoading(false)
    } catch(err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if(feathers) {
      refreshElectionData()
    }
  }, [ feathers ])

  if(loading) {
    return (
      <Wrapper>
        <LoadingTile>
          <LoadingSpinner scale={0.8}/>
        </LoadingTile>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <DataDetailToolbar
        onBack={{ to: {
            pathname: `/elections/${electionKey}`,
          }}}
        navTree={[
          {
            text: 'Elections',
            to: {
              pathname: `/elections`,
            }
          },
          {
            text: election?.name,
            to: {
							pathname: `/elections/${electionKey}`,
            }
          },
          { text: 'Coverage plan' }
        ]}
        actionButtonsComponent={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {
              editPerms && !editingPlan &&
              <IconButton size='small' onClick={() => setEditingPlan(true)}>
                <EditIcon />
              </IconButton>
            }
          </div>
        }
      />
      {
        editingPlan &&
        <EditCoveragePlan
          style={{ marginTop: '24px', maxWidth: '600px' }}
          election={electionKey}
          onSave={() => {
            setEditingPlan(false)
            refreshElectionData()
          }}
        />
      }
      {
				electionImplementation?.supportedArea?.length > 0 && !editingPlan &&
				<Inner>
					<MapWrapper>
						<CoveragePlanMap
              style={{ maxHeight: 'calc(500px - 36px * 2)' }}
              supportedArea={electionImplementation?.supportedDistricts}
              expectedReadershipForDistricts={electionImplementation?.expectedReadershipForDistricts}
              priorityArea={electionImplementation.priorityAreas}
							state={election.state._id}
						/>
					</MapWrapper>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '12px', marginBottom: '-8px', gap: '12px' }}>
            <Typography variant='h4'>Supported districts ({typeof(districtsCount) === 'number' ? districtsCount : '--' })</Typography>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
              <TypeDropdown
                value={districtType || 'all'}
                options={tier2TypeOptions}
                onChange={onChangeTypeFilter}
              />
              <TypeDropdown
                value={status || 'all'}
                options={[
                  { type: 'incomplete', title: 'Incomplete' },
                  { type: 'complete', title: 'Completed' },
                  { type: 'all', title: 'All statuses' },
                ]}
                onChange={onChangeStatusFilter}
              />
            </div>
          </div>
					<ListWrapper>
						<SupportedDistrictList
              onChangeCount={setDistrictsCount}
              expectedReadershipForDistricts={electionImplementation?.expectedReadershipForDistricts}
              supportedDistricts={electionImplementation?.supportedDistricts}
              electionKey={electionKey}
              statusFilter={status}
              typeFilter={districtType}
              onChangePageNumber={(pageNumberNew) => {
                // set in the query params of the page
                const newQuery = qs.stringify({status, districtType, page: pageNumberNew })
                const newLocation = {...location, search: newQuery}
                history.push(newLocation)
              }}
              pageNumber={parseInt(qs.parse(location.search, { ignoreQueryPrefix: true }).page) || 1}
            />
					</ListWrapper>
				</Inner>
			}
			{
				electionImplementation?.supportedArea?.length === 0 && !editingPlan &&
				<Inner>
					<Typography variant='h3'>No coverage plan has been set for this election.</Typography>
					{
            editPerms && <Typography variant='body1' onClick={() => setEditingPlan(true)} style={{ cursor: 'pointer'}}><u>Set a plan</u></Typography>
          }
				</Inner>
			}
    </Wrapper>
  )
}


const Wrapper = styled.div`
  width: calc(100% - 2 * 24px);
  min-height: calc(100% - 24px * 2);
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: stretch;
`;

const LoadingTile = styled.div`
  width: 100%;
  align-self: center;
  justify-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MapWrapper = styled.div`
	max-width: calc(100% - 36px * 2);
  width: 600px;
	height: 400px;
	padding: 36px;
	background-color: #ffffff;
	border-radius: 8px;
	box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.1);
`

const ListWrapper = styled.div`
	width: calc(100% - 24px * 2);
	height: auto;
	padding: 12px 24px 12px;
	background-color: #ffffff;
	border-radius: 8px;
	box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.1);
`

const Inner = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding-top: 36px;
`

export default ElectionCoveragePlan;
