import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Link } from 'react-router-dom';
import { isPermitted, useFeathers } from "../../app/util";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import { TextField, MenuItem } from "@material-ui/core";
import styled from "styled-components";
import DeleteIcon from "@material-ui/icons/Delete";
import { AddButton } from "../AddButton";
import CancelIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from "@material-ui/core/IconButton";
import InputIcon from "@material-ui/icons/Input";
import { OfficialsSelectInput } from "../OfficialsSelectInput";
import { useSelector } from "react-redux";

const partiesAll = {
  'D': 'Democrat',
  'R': 'Republican',
  'L': 'Libertarian',
  'G': 'Green',
  'I': 'Independent',
  'N': 'Nonpartisan',
  'C': 'Constitution',
  'W': 'We The People',
  'P': 'Populist',
  'NL': 'No Labels',
  'S': 'Socialist Party',
  'F': 'Forward',
  'ASP': 'American Solidarity Party',
  'CP': 'Conservative Party',
  'WFP': 'Working Families',
  'PF': 'People First',
  'LR': 'LaRouche Party'
}

const partyOptions = Object.keys(partiesAll).map(party => ({ label: partiesAll[party], value: party }))

const RaceCandidatePreviewTile = ({
  race,
  removeCandidate,      //if true, allows you to remove a candidate
  onAddCandidate,
  onError,
}) => {
  const feathers = useFeathers();
  const user = useSelector((state) => state.user);
  const [ newCandidate, setNewCandidate ] = useState(null);
  const [ candidateSaving, setCandidateSaving ] = useState(false);
  const [ addingCandidate, setAddingCandidate ] = useState(false);
  const editorDeletePerms = isPermitted(user, ['editor', 'super-admin'])
  const recentDeletePerms = isPermitted(user, ['reviewer', 'publisher', 'editor', 'super-admin'])

  const candidateSubmittable = Boolean(newCandidate) && (race?.party || newCandidate?.party);

  const onChangeOfficial = (e) => {
    setNewCandidate({ ...newCandidate, official: e.target.value });
  };

  const onChangeCandidateParty = (e) => {
    setNewCandidate({ ...newCandidate, party: e.target.value });
  };

  const addCandidateToRace = async () => {
    if(!candidateSubmittable || candidateSaving) return;
    setCandidateSaving(true)

    try {
      const { official } = newCandidate;
      const { party } = newCandidate;
      const data = {
        official: official.isNew ? { $create: true } : official.key,
        name: official.name,
        party: party || race.party
      };

      const cd = await feathers.getService('candidates').create({
        ...data,
        race: race._id,
      })

      if(onAddCandidate) onAddCandidate(cd)

      setNewCandidate(null);
      setAddingCandidate(false);
      setCandidateSaving(false)
    } catch (err) {
      console.log(`Error on add candidate to race`, err)
      if(onError) onError(err)
    } finally {
      setCandidateSaving(false)
    }
  };


  const raceParty = useMemo(() => {
    if (race.party) {
      return (partyOptions.find((p) => p.value === race.party) || {}).label;
    } else {
      return null;
    }
  }, [ race ]);


  const [office, setOffice] = useState(null);
  const [district, setDistrict] = useState(null);

  const loadOfficeAndDistrict = async () => {
    try {
      const office = race._id
      ? race.office
      : await feathers.getService("offices").get(race.office?.key);
    setOffice(office);
    const district = race.district._id
      ? race.district
      : await feathers.getService("districts").get(race.district);
    setDistrict(district);
    } catch (err) {
      console.log(`Error loading office and district`, err)
    }
  };

  useEffect(() => {
    if (!feathers || !race) return;
    loadOfficeAndDistrict();
  }, [feathers, race]);

  const keylistener = useCallback((event) => {
    if(event.keyCode === 13) {
      setEnterPressed(true)
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", keylistener, false);
    return () => {
      document.removeEventListener("keydown", keylistener, false);
    };
  }, []);

  const [ enterPressed, setEnterPressed ] = useState(false);
  useEffect(() => {
    if(enterPressed) {
      setEnterPressed(false);
      if(candidateSubmittable)
        addCandidateToRace();
    }
  }, [ enterPressed, candidateSubmittable ])

  const appearsOnBallotAs = useMemo(() => {
    const entityMatchInputs = race?.entityMatchInputs.filter(i => !!i)
    if (!entityMatchInputs?.length) {
      return ''
    } else if (entityMatchInputs.length === 1) {
      return `${entityMatchInputs[0]}`
    } else {
      return entityMatchInputs.slice(0, -1).join(', ') + ' or ' + entityMatchInputs.slice(-1)
    }
  }, [race])

  return (
    <Tile>
      <TileInner>
        <HeadingWrapper>
          <Typography variant="h3">{office?.name}{raceParty && ` (${raceParty})`}{race.retention && ` (Retention election)`}</Typography>
          <Typography variant='body1'>
            (Vote for {race.maxChoices})
          </Typography>
          <div style={{ flex: 1}} />
          <Link to={`/elections/${race.election?.key ?? race.election}/races/${race._id}`} target='_blank'>
            <EditIcon style={{ marginBottom: '-12px' }}/>
          </Link>
        </HeadingWrapper>
        <Typography variant="body1" style={{ color: '#453DB7'}}>{district?.longName} · {`${(race.candidates?.length || 0)}` + ' candidates'}</Typography>
        {appearsOnBallotAs && <Typography variant="body2" style={{ marginTop: '4px'}}>Appears on ballot as: "{appearsOnBallotAs}"</Typography>}
        <div style={{ paddingLeft: '36px', paddingTop: '12px'}}>
          <Candidates>
            <Typography variant="body2" style={{ fontWeight: 'bold', marginBottom: '-8px' }}>Candidate name</Typography>
            <Typography variant="body2" style={{ fontWeight: 'bold', marginBottom: '-8px' }}>Party</Typography>
            <div></div>
            {(race.candidates|| []).map((candidate, idx) => (
              <React.Fragment key={idx}>
                <HighlightRow rowNum={idx + 2}/>
                <Typography variant="body1" style={{ gridRow: `${idx + 2} / ${idx + 3}`, gridColumn: `1 / 2`}}>
                  {candidate.name}
                </Typography>
                <Typography variant="body1" style={{ gridRow: `${idx + 2} / ${idx + 3}`, gridColumn: `2 / 3`}}>
                  {candidate.party}
                </Typography>
                {
                  Boolean(removeCandidate) &&
                  (
                    moment(candidate.createdAt).isAfter(moment().diff(1, 'day'))
                    ? recentDeletePerms
                    : editorDeletePerms
                  ) &&
                  (
                    <IconButton style={{ gridRow: `${idx + 2} / ${idx + 3}`, gridColumn: `3 / 4`}}
                      onClick={() =>
                        removeCandidate(candidate._id)
                      }
                      size='small'
                    >
                      <DeleteIcon />
                    </IconButton>
                  )
                }
              </React.Fragment>
            ))}
            {addingCandidate &&
              <>
                <OfficialsSelectInput
                  value={newCandidate?.official}
                  onChange={onChangeOfficial}
                  style={{ gridRow: `${(race.candidates || []).length + 2} / ${(race.candidates || []).length + 3}`, gridColumn: `1 / 2`, maxWidth: '275px' }}
                />
                <TextField
                  variant="outlined"
                  select
                  disabled={Boolean(race.party)}
                  label="Party"
                  onChange={onChangeCandidateParty}
                  style={{ gridRow: `${(race.candidates || []).length + 2} / ${(race.candidates || []).length + 3}`, gridColumn: `2 / 3` }}
                  value={race?.party || newCandidate?.party}
                  size="small"
                >
                  {partyOptions.map((opt) => (
                    <MenuItem key={opt.value} value={opt.value}>
                      {opt.label}
                    </MenuItem>
                  ))}
                </TextField>
                <div style={{ display: 'flex', gap: '6px', marginLeft: '8px', gridRow: `${(race.candidates || []).length + 2} / ${(race.candidates || []).length + 3}`, gridColumn: `3 / 4` }}>
                  <IconButton disabled={!candidateSubmittable} size='small' onClick={addCandidateToRace} style={{}}>
                    <InputIcon style={{ width: '20px', height: '24px'}}/>
                  </IconButton>
                  <IconButton size='small' onClick={() => setAddingCandidate(null)}>
                    <CancelIcon style={{ width: '20px', height: '24px'}}/>
                  </IconButton>
                </div>
                {
                  candidateSaving &&
                  <HighlightRow style={{ zIndex: 1, opacity: 0.4, gridRow: `${(race.candidates || []).length + 2} / ${(race.candidates || []).length + 3}`, gridColumn: `1 / 4` }}/>
                }
              </>
            }
          </Candidates>
          {!addingCandidate && !!onAddCandidate && (
            <AddCandidateWrapper>
              <AddButton onClick={() => setAddingCandidate(true)}>
                Add candidate
              </AddButton>
            </AddCandidateWrapper>
          )}
        </div>

        <LastUpdated>{`Last updated on ${moment(race.updatedAt).format(
          `MMMM Do, h:mm:ss a`
        )} `}</LastUpdated>

      </TileInner>
    </Tile>
  );
};

const Tile = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 24px 30px;

  border: 1px solid #dddddd;
  text-decoration: none;
`;

const TileInner = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Candidates = styled.div`
  display: grid;
  grid-template-columns: minmax(300px, auto) minmax(150px, auto) minmax(70px, auto);
  grid-gap: 12px 0;
  justify-content: start;
  align-items: center;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  width: 100%;
  margin-bottom: 28px;
`;

const AddCandidateWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 12px;
  margin-bottom: 8px;
`

const HighlightRow = styled.div`

  grid-column: 1 / 4;
  grid-row: ${props => props.rowNum} / ${props => props.rowNum + 1};
  background-color: #f5f5f5;
  height: 100%;
  border-radius: 4px;
  margin: 0 -12px;
  width: calc(100% + 24px);
`

const LastUpdated = styled.span`
  font-size: 12px;
  opacity: 0.6;
  text-align: right;
`;

export { RaceCandidatePreviewTile };
