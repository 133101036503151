import React, { useEffect, useState } from 'react';
import { useFeathers } from '../../app/util';
import { useSelector } from 'react-redux';
import { useLocation, Link } from "react-router-dom";
import qs from 'qs'
import {CTAButton, LoadingSpinner} from '../../components';
import PurpleLogo from '../../images/BranchLogo_DarkPurple-02.png';
import { ReactComponent as Blob } from '../../images/Vector.svg';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import AccountInformation from '../../components/AccountInformation';

const Inner = () => {
  const feathers = useFeathers();
  const location = useLocation();
  const [ existingUser, setExistingUser ] = useState(null);
  const [ invitation, setInvitation ] = useState(null);
  const [ lastName, setLastName ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ confirmPassword, setConfirmPassword ] = useState('');
  const [ passwordError, setPasswordError ] = useState(false);
  const [ userAccount, setUserAccount ] = useState(null);
  const user = useSelector(state => state.user);

  const [ loaded , setLoaded ] = useState(false)
  const { loggedIn } = useSelector(state => state.user);
  const [ firstName, setFirstName ] = useState(user.firstName || '');
  const [ disabled, setDisabled ] = useState(false);

  const {
    email,
    invitationId
  } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const getInvitation = async() => {
    const invitation = await feathers?.getService('invite-links').get(invitationId,
      { query: { email: email, type: 'data-editor' } },
    );
     if (!invitation) return
     if (invitation) {
      setInvitation(invitation)
      setLoaded(true)
      if (invitation.user) {
        setExistingUser(invitation.user)
      }
    }
  }

  const createUserAccount = async () => {
    if(disabled) return;
    const { email } = invitation;
    if ( password !== confirmPassword ) {
      setPasswordError(true)
      return
    }
    setDisabled(true)
    try {
      const userAccount = await feathers?.getService('users').create({
        email,
        firstName,
        lastName,
        password,
        inviteLink: invitationId,
      })
      setUserAccount(userAccount)
    } catch(error) {
      console.log(error)
    } finally {
      setDisabled(false)
    }

  }

  const patchUserAccount = async () => {
    const { email } = invitation;

    if(disabled) return;

    setDisabled(true)
    try {
      const userAccount = await feathers?.getService('users').patch(user._id, {
        email,
        firstName,
        lastName,
        inviteLink: invitationId,
      })
      setUserAccount(userAccount)
    } catch(error) {
      console.log(error)
    } finally {
      setDisabled(false)
    }
  }

  useEffect(() => {
    if (invitationId) getInvitation();
  }, [feathers, invitationId]);


  if(!loaded) {
    return (
      <LoadingSpinner />
    )
  }

  if(!invitation.valid) {
    return <Typography variant="h6">Invalid Invitation!</Typography>
  }

  if(userAccount) {
    return (
      <WelcomeWrapper>
        <Typography variant="h6">
          You're all set up. Please log-in to continue.
        </Typography>
        { !loggedIn &&
          <Link
            to='/'
          >
            <CTAButton style={{ width: '100%' }}>Login</CTAButton>
          </Link>
        }
      </WelcomeWrapper>
    )
  }

  if(existingUser && !loggedIn) {
    return (
      <>
        <Typography
          variant="h6"
          align="center"
        >
          Our records show you already have an email address for this account. Please log-in to get started.
        </Typography>
        <Link
          to={{pathname: '/', state: { from: { pathname: location.pathname, search: location.search  }}}}
          style={{ margin: '20px'}}
        >
          <CTAButton>Login</CTAButton>
        </Link>
      </>
    )
  }

  if(existingUser && loggedIn && user.email !== invitation?.email) {
    return (
      <Typography>This invitation belongs to different user. Please ask someone from the Branch team to resend your invitation.</Typography>
    )
  }

  if(existingUser && loggedIn && user.email === invitation?.email) {
    return (
      <AccountInformation
        heading="Welcome! Please update your account information"
        firstName={firstName}
        lastName={lastName}
        setFirstName={setFirstName}
        setLastName={setLastName}
        createAccountFromInvitation={patchUserAccount}
        disabled={disabled}
      />
    )
  }

  return (
    <AccountInformation
      heading="Finish setting up your Branch account."
      email={invitation?.email}
      firstName={firstName}
      lastName={lastName}
      setFirstName={setFirstName}
      setLastName={setLastName}
      createAccountFromInvitation={createUserAccount}
      setPassword={setPassword}
      setConfirmPassword={setConfirmPassword}
      setPasswordError={setPasswordError}
      passwordError={passwordError}
      includePassword={true}
      disabled={disabled}
    />
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

`;

const AcceptInviteContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 100;
  margin-top: 36px;
  align-items: center;
  max-width: 600px;
  background-color: #FFFFFF;
  border: 1px solid #EBEBEB;
  border-radius: 12px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  padding: 36px 36px 36px 24px;
`

const WelcomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
`


const AcceptInvite = (props) => {
  return (
    <Wrapper>
      <Blob style={{ position: 'absolute', width: '100vw', height: 'auto', top: '-100px' }} />
      <img src={PurpleLogo} style={{ height: '50px', paddingTop: '40px' }} alt="branch logo"/>
      <AcceptInviteContentWrapper>
        <Inner {...props} />
      </AcceptInviteContentWrapper>
    </Wrapper>
  )
}









export default AcceptInvite;
