import React from 'react';
import styled from 'styled-components';

const BluePill = ({
  children,
  style
}) => {
  return (
    <Pill style={style}>
      {children}
    </Pill>
  )
}

const Pill = styled.div`
  padding: 6px 12px;
  border-radius: 50px;
  background-color: #453DB720;

  font-size: 14px;
  font-family: HouschkaRoundedAltSix, HouschkaRoundedAlt;
  font-weight: bold;
  color: #453DB7;

  span, p, div {
    font-size: 14px;
    font-weight: bold;
    color: #453DB7;
    font-family: HouschkaRoundedAltSix, HouschkaRoundedAlt;
  }
`;

export { BluePill };
