import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { SourceList } from '../SourceList';
import { StyledMarkdown } from '../StyledMarkdown';

const issueHasPrimer = (issueName) => {
  const nameMapped = (issueName || '').toLowerCase().trim()
  if(
    nameMapped.includes('budget') ||
    nameMapped === 'community development' ||
    nameMapped.includes('covid') ||
    nameMapped.includes('housing') ||
    nameMapped.includes('public safety') ||
    nameMapped.includes('transportation') ||
    nameMapped.includes('transit') ||
    nameMapped.includes('infrastructure') ||
    nameMapped.includes('zoning') ||
    nameMapped.includes('zone') ||
    nameMapped.includes('race') ||
    nameMapped.includes('racism') ||
    nameMapped.includes('equity') ||
    nameMapped.includes('college') ||
    nameMapped.includes('career')
  ) return true;
  else return false;
}

const IssuePrimer = ({
  issueName,
  style,
  className,
  mode = 'short',
  atlanta = false,
  school = false
}) => {
  const nameMapped = (issueName || '').toLowerCase().trim()
  if(!issueHasPrimer(issueName)) return null;

  if(nameMapped.includes('budget')) {
    if(mode === 'short') {
      if(school) {
        return (
          <ShortPrimer className={className} style={style}>
            Through allocating the School System Budget, local officials can affect things like how much teachers are paid or how much is spent on education or additional services for students.
          </ShortPrimer>
        );
      } else {
        return (
          <ShortPrimer className={className} style={style}>
            Budget approval is one of the most important jobs of city government. It determines what city departments can/cannot spend money on, including the police department, the fire department, city services, parks and recreation, and transportation.
          </ShortPrimer>
        );
      }
    } else {
      if(atlanta && school) {
        return (
          <FullPrimer className={className} style={style} sources={[
            { title: 'Center for Civic Innovation' , url: 'https://static1.squarespace.com/static/5913e2d42994ca3a9fc5bf1a/t/60fac63d90910d3e4a5c19cd/1627047486379/VoteATL+School+Board+20210722.pdf' },
            { title: 'APS', url: 'https://www.atlantapublicschools.us/Page/66009'},
            { title: 'Census.gov', url: 'https://www.census.gov/programs-surveys/school-finances.html'},
            { title: '11 Alive' , url: 'https://www.11alive.com/article/news/education/atlanta-education-board-approves-aps-budget-for-2021-22-including-nearly-24m-in-pay-upgrades/85-92fabc8f-c8d1-47b6-9b32-318def0a2ff0'}
          ]}>
            <Typography variant='body1' color='textPrimary'>
              The Atlanta Public School (APS) system has a budget of $903 million. The top three categories that receive the largest proportion of the budget are (1) Instruction, which receives 66% of the budget, (2) Operations and Maintenance, which receives 10%, and (3) Administration, which also receives 10%. Other areas of the budget include Staff Services (6%), Pupil Services (4%), Transportation (4%) and Other (1%).
            </Typography>
            <Typography variant='body1' color='textPrimary'>
              APS serves 51,000 students, employs 3,500 teachers, and runs 92 schools and programs, including 64 traditional schools, 19 charter schools, six partner schools, three alternative programs, and two alternative schools. The school system budget pays for all of these schools and programs. As of 2020, teachers earned a minimum of $48,086 and a maximum of $93,980.
            </Typography>
            <Typography variant='body1' color='textPrimary'>
              The 2021-2022 school year budget was approved in June 2021, and it included nearly $24 million in pay increases for APS employees, including a 2-9% raise for teachers based on years of experience, and a $15 minimum wage for all hourly employees. The budget also includes more than $30 million in academic recovery efforts, such as $8 million to extend the elementary school day by 30 minus, $7 million for a summer learning recovery academy taking place during the summer of 2021, $14 million in federal funding for COVID relief to be distributed to individual schools, $9 million for physical and mental health and wellness, and $8 million for technology and operations upgrades.
            </Typography>
            <Typography variant='body1' color='textPrimary'>
              APS receives $894 million in revenue. It is funded almost entirely by local taxes (77% of revenue) and state money (20% of revenue). About 50% of all property taxes and 11% of all sales taxes collected by the city of Atlanta goes to APS. APS runs a deficit each year; on average, APS has nearly $17 million in outstanding debt each year.
            </Typography>
          </FullPrimer>
        );
      } else if(atlanta && !school){
        return null;
      } else if(!school) {
        return (
          <FullPrimer className={className} style={style} sources={[
            { title: 'University of Georgia' , url: 'https://cviog.uga.edu/_resources/documents/publications/handbook-city-finance.pdf' },
            { title: 'Atlanta.gov', url: 'https://www.atlantaga.gov/home/showpublisheddocument/51114/637558963170270000'},
            { title: 'Atlanta.gov', url: 'https://www.atlantaga.gov/government/departments/finance/budget-fiscal-policy'},
            { title: 'Brookings' , url: 'https://www.brookings.edu/research/city-budgets-in-an-era-of-increased-uncertainty/'}
          ]}>
            <Typography variant='body1' color='textPrimary'>
              Every year, each city in Georgia has a multi-million dollar budget, which is funded from taxes and other sources of funding. City officials must decide how to allocate this funding by preparing a city budget.
            </Typography>
            <Typography variant='body1' color='textPrimary'>
              The budget is usually prepared and approved by the mayor and/or the city council. City residents also get a say in the process; Georgia cities are required to hold public hearings on the proposed budget so residents can ask questions and express their opinions. <b>Budget approval is one of the most important jobs of city government</b>, because it determines what city departments can and cannot spend money on, including the police department, the fire department, city services, parks and recreation, and transportation.
            </Typography>
            <Typography variant='body1' color='textPrimary'>
              Creating the budget allows city officials to prioritize which issues they think are the most important to the city. For example, city officials may have to decide between funding services like healthcare, food assistance, road improvements, hiring more police officers, building a new park, or buying the fire department a new fire engine. It also determines whether the city will or will not be in debt, because the city’s expenses need to be carefully balanced with its income.
            </Typography>
            <Typography variant='body1' color='textPrimary'>
              Money comes into the city budget through a combination of local taxes, including property and sales tax, fines and fees paid by residents, and permits paid for by residents and businesses. Although some states limit the amount that cities can charge in property taxes, there is no limit in Georgia. However, the amount a Georgia city can spend is limited.
            </Typography>
          </FullPrimer>
        );
      }
    }
  } else if(nameMapped === 'community development') {
    if(mode === 'short') {
      return (
        <ShortPrimer className={className} style={style}>
          Community development includes candidates' plans for how they would like to city their city grow. This can be physically, environmentally, culturally, financially, or socially.
        </ShortPrimer>
      );
    } else {
      return (
        <FullPrimer className={className} style={style} sources={[
          { title: 'University of Connecticut' , url: 'https://communities.extension.uconn.edu/what-is-community-economic-development/' },
        ]}>
          <StyledMarkdown>
{
`Community development is the city’s efforts to build resources to allow residents to improve their quality of life. Candidates’ positions on community development are essentially about how they would like to see their city grow. Growth can be through:
- Developing new libraries, schools, or shopping centers
- “Greening” city spaces to make them more environmentally friendly
- Expanding or limiting city services
- Changing who is a part of the city and who is outside the city
- Building community between residents and neighborhoods

The community can be developed by local government, citizens, private businesses, or a combination of all three.`}
          </StyledMarkdown>
        </FullPrimer>
      );
    }
  } else if(nameMapped.includes('covid')) {
    if(mode === 'short') {
      if(school) {
        return (
          <ShortPrimer className={className} style={style}>
            Board of Eduction members have power to decide how schools respond to COVID-19. This can include things like deciding whether or not to reopen in-person learning or requiring masks or vaccinations for students or teachers.
          </ShortPrimer>
        );
      } else {
        return (
          <ShortPrimer className={className} style={style}>
            Local officials have power to decide how to respond to COVID-19. This can include public health policies or economic recovery policies.
          </ShortPrimer>
        );
      }
    } else {
      if(school) {
        return (<FullPrimer className={className} style={style} sources={[
          { title: 'AJC' , url: 'https://www.ajc.com/education/atlanta-middle-high-schools-to-offer-covid-19-vaccines/WVKGODMN7RFKPLR76XLCA2U7D4/' },
          { title: 'NY Times' , url: 'https://www.nytimes.com/2021/08/05/us/atlanta-public-schools-covid-vaccines.html' },
          { title: 'APS' , url: 'https://www.atlantapublicschools.us/Page/66278' },
        ]}>
          <StyledMarkdown>
{`This fall, COVID is a major issue for Atlanta Public Schools (APS), as well as schools across the nation. Here are some quick facts about COVID and Atlanta Public Schools:
- As of the start of September, Georgia is experiencing a resurgence of COVID cases, with an average of nearly 4,000 new cases and 2,500 hospitalizations each day, largely due to the spread of the Delta variant. Georgia also has one of the lowest vaccination rates in the country.
- All APS schools reopened in August 2021 for required in-person instruction for most students, making Atlanta schools some of the earliest in the country to reopen.
- Currently, students and teachers are required to wear masks in the classrooms and to social distance, and students will do daily health screenings.
- APS partnered with health agencies to offer the first and second doses of the Pfizer vaccine in Atlanta middle and high schools. The vaccine is available to anyone over age 12 with parental consent. As of July 22, 2021, school officials said only 18% of eligible students were fully vaccinated and 58% of employees were vaccinated or planned to be.
- APS is not mandating that employees get the vaccine, although APS Superintendent Lisa Herring said that APS would “continue to entertain” the possibility of requiring vaccinations for APS employees. Since reopening, Charles R. Drew Charter School has had to quarantine about 278 students, faculty and staff.`}
          </StyledMarkdown>
        </FullPrimer>)
      } else {
        return (
          <FullPrimer className={className} style={style} sources={[
            { title: 'GPB' , url: 'https://www.gpb.org/news/2020/08/26/inequality-gap-among-atlanta-students-may-grow-due-covid-19-pandemic-experts-say' },
            { title: 'NY Times' , url: 'https://www.nytimes.com/2021/08/05/us/atlanta-public-schools-covid-vaccines.html' },
            { title: 'GPB' , url: 'https://www.gpb.org/news/2021/08/02/without-the-cdcs-eviction-ban-millions-could-quickly-lose-their-homes' },
            { title: 'WABE' , url: 'https://www.wabe.org/what-can-atlanta-do-to-help-businesses-post-pandemic-5-candidates-for-mayor-weigh-in/' },
          ]}>
            <StyledMarkdown>
  {`COVID continues to be a major political issue, both in terms of how elected officials respond to the virus and to both economic and community recovery. As of September 2021, Georgia is experiencing a resurgence of COVID cases, with an average of nearly 4,000 new cases and 2,500 hospitalizations each day, largely due to the spread of the Delta variant. The state also has one of the lowest vaccination rates in the country.

  Local elected officials have power to decide policy about the virus as conditions change. They can encourage or require masks indoors, encourage or require vaccinations, make shelter in place orders, stop evictions, and/or provide resources to residents, small businesses, or corporations, among other actions. Local elected officials can also attempt to address economic recovery through policies around business and workers, including partnerships, funding, and wages. They also decide how to spend and distribute millions of dollars in federal aid for COVID recovery.`}
            </StyledMarkdown>
          </FullPrimer>
        );
      }
    }
  } else if(nameMapped.includes('housing')) {
    if(mode === 'short') {
      return (
        <ShortPrimer className={className} style={style}>
          Housing includes any issue related to ensuring residents have access to housing, and includes the issues of housing affordability, gentrification, and homelessness.
        </ShortPrimer>
      );
    } else {
      return (
        <FullPrimer className={className} style={style} sources={[
          { title: 'FederalReserve.gov' , url: 'https://www.federalreserve.gov/econres/notes/feds-notes/housing-affordability-in-the-us-trends-by-geography-tenure-and-household-income-20190927.htm' },
          { title: 'HowMuch' , url: 'https://howmuch.net/articles/home-affordability-in-the-US' },
          { title: 'NLIHC' , url: 'https://nlihc.org/housing-needs-by-state/georgia' },
          { title: 'Atlanta.gov' , url: 'https://www.atlantaga.gov/home/showpublisheddocument?id=49465' },
          { title: 'USICH', url: 'https://www.usich.gov/homelessness-statistics/ga/'}
        ]}>
          <StyledMarkdown>
{`Housing has been a major issue for U.S. cities for years, particularly housing affordability, gentrification, and homelessness.

Here are some quick facts about the current housing situation in Georgia:
- The housing market across the nation has also been drastically impacted by COVID, with many people moving and housing prices increasing in both rural and urban areas of the country.
- In Georgia in 2021, the median new home price is $311,000, with 42% of households across the state able to afford to pay a mortgage at this price.
- Across the state, there is a shortage of affordable and available rental homes, particularly for low income people; 24% of Georgia’s renters are extremely low-income.
- As of January 2020, Georgia had more than 10,000 people experiencing homelessness on any given day. During the 2017-2018 school year, nearly 40,000 Georgia students were homeless at some point during the year.

Solutions to housing include affordable housing, which is defined by the U.S. Department of Housing and Urban Development as housing that costs less than 30% of a household’s monthly income.

Candidate stances on this issue include things like building or requiring developers to build affordable housing, providing tax incentives and subsidies, or funding homeless services.`}
          </StyledMarkdown>
        </FullPrimer>
      );
    }
  }  else if(nameMapped.includes('public safety')) {
    if(mode === 'short') {
      return (
        <ShortPrimer className={className} style={style}>
          Public safety means ensuring the protection and safety of the general public. Candidate stances typically include plans to address the recent national rise in violent crime.
        </ShortPrimer>
      );
    } else {
      return (
        <FullPrimer className={className} style={style} sources={[
          { title: 'US Legal' , url: 'https://definitions.uslegal.com/p/public-safety/' },
          { title: 'WABE' , url: 'https://www.npr.org/2021/05/27/1000991515/as-some-departments-reform-police-brace-themselves-for-summers-violent-crime' },
          { title: 'AJC', url: 'https://www.ajc.com/news/after-historically-deadly-2020-atlanta-homicides-are-up-nearly-60-in-2021/N63RJ5OKQZCZVOCNH2D6376S3E/'}
        ]}>
          <StyledMarkdown>
{`Public safety means ensuring the protection and safety of the general public. Local officials can take a variety of approaches to public safety. Some officials believe that increasing the amount of police officers, jails, and surveillance systems increase public safety, while others believe that providing social services and preventing poverty are more effective.

During 2020, violent crime increased throughout the country, particularly gun violence. Crime rates nationally have risen to where they were in the mid-1990s, and big cities such as Atlanta have seen the same trend. However, crime rates remain below the historic highs of the 1970s and 1980s.

Causes for the increase in crime have not been officially determined, although officials and experts point to various causes, including the COVID pandemic, unemployment, low police morale, insufficient policing, mistrust between the police and communities, and an increase in the amount of guns in communities. Atlanta police Chief Rodney Bryant said that most of the shootings are caused by personal conflicts between people who know each other. `}
          </StyledMarkdown>
        </FullPrimer>
      );
    }
  } else if(nameMapped.includes('transportation') || nameMapped.includes('infrastructure') || nameMapped.includes('transit')) {
    if(mode === 'short') {
      return (
        <ShortPrimer className={className} style={style}>
          Transportation refers to all methods of traveling around an area, including public and private transit. Infrastructure is the umbrella term for the physical systems of an area, including roads, sidewalks, water/sewage systems, and electrical grids.
        </ShortPrimer>
      );
    } else {
      return (
        <FullPrimer className={className} style={style} sources={[
          { title: 'APTA' , url: 'https://www.apta.com/news-publications/public-transportation-facts/' },
          { title: 'Investopedia' , url: 'https://www.investopedia.com/terms/i/infrastructure.asp' },
          { title: 'Associated Press', url: 'https://apnews.com/article/joe-biden-business-bills-38b84f0e9fcc8e68646eedf6608c4c70'}
        ]}>
          <StyledMarkdown>
{`**Transportation refers to all methods of traveling around an area**. Some methods of transport can be private like walking or using your bicycle or car to travel. However, most major cities offer several public transportation options (buses, trolleybuses, trains, subways, etc.) to help residents move around the city.

Public officials and political candidates often praise and promote public transport because it is often more cost-effective and safer for individuals, reduces carbon emissions, eases traffic and provides employment opportunities and greater economic mobility through easier travel across the area. Nationwide, public transportation employs more than 448,000 people is an $80 billion industry. However, as major cities continue to expand and house more people, most cities’ public transport systems have failed to adequately reach all of their people–45 percent of Americans have no access to public transportation–making it a common issue area of concern for policymakers.

Transportation is a major part of a city’s **infrastructure, which is the general term for the physical systems of an area, such as transportation systems, water, sewage, electrical grids and communication networks**. Infrastructure tends to require a lot of parts, labor, and significant investment, and these systems are essential to a location’s overall prosperity and economic development. To financially support such a large network of systems, local governments typically rely on large portions of their budget but also rely on funding through federal grants or private organizations. However, local, state and federal governments frequently debate over who has the responsibility to make infrastructure repairs and what projects to prioritize. This often leads to a decline in the quality of infrastructure, especially in low-income areas.

To address deteriorating infrastructure, the U.S. Senate recently passed a nearly $1 trillion bipartisan plan to modernize public work systems, rebuild roads and bridges, and boost broadband internet, among other infrastructure improvements. This package has not made it through the legislative process yet.`}
          </StyledMarkdown>
        </FullPrimer>
      );
    }
  } else if(nameMapped.includes('zoning') || nameMapped.includes('zone')) {
    if(mode === 'short') {
      return (
        <ShortPrimer className={className} style={style}>
          Zoning is when city officials decide how certain land can be used (e.g. for houses, apartments, retail, or manufacturing). This has a huge impact on the welfare, housing affordability, walkability, inequality, and safety of an area.
        </ShortPrimer>
      );
    } else {
      return (
        <FullPrimer className={className} style={style} sources={[
          { title: 'GAZA' , url: 'http://gazoning.org/presentations/zoning-101/' },
          { title: 'Columbus GA' , url: 'https://georgiaplanning.org/pdfs/2009_awards/zoning_handbook_gpa.pdf' },
          { title: 'Atlanta.gov', url: 'https://www.atlantaga.gov/government/departments/city-planning/office-of-zoning-development/zoning'},
          { title: 'Berkley.edu', url: 'http://www.ced.berkeley.edu/downloads/pubs/faculty/hutson_2008_environ-health.pdf'}
        ]}>
          <StyledMarkdown>
{`One of the biggest jobs of city government is city planning, in which city officials design and develop how land in the city will be used. One of the biggest components to city planning is zoning.

Essentially, **zoning maps out which types of buildings can be built in different areas in a city**. Generally speaking, an area of land is usually “zoned” as one of the following types:
- *Residential zones* – these are for houses, condos, and apartments. Not all residential zones are created equally, though. Zoning can also regulate what types of homes can be built in an area, by limiting it to single family homes or high rises, plus the overall size, height and placement of buildings on a lot.
- *Commercial zones* – these are where you find grocery stores, retail storefronts, and restaurants.
- *Industrial zones* – industrial zones are where you find land dedicated to industry, including warehouses, power plans, factories, chemical plants, and shipping docks.
- *Mixed-use zones* – Mixed-use zoning allows multiple types of buildings in one area, like an apartment building where the ground floor is a restaurant or coffee shop.

Zoning has strong implications for the welfare of a city, because it determines what a city looks and feels like. It also affects issues like housing affordability and walkability. Zoning can also determine how healthy and safe the population is, as well as how much inequality there is. Typically, the city council passes zoning ordinances and also appoints a board to work on zoning. `}
          </StyledMarkdown>
        </FullPrimer>
      );
    }
  } else if(nameMapped === 'equity' && school) {
    if(mode === 'short') {
      return (
        <ShortPrimer className={className} style={style}>
          Board of Education members can create equity by setting students up to achieve at the same level despite their differences, without ignoring those differences
        </ShortPrimer>
      );
    } else {
      return (
        <FullPrimer className={className} style={style} sources={[
          { title: '11 Alive' , url: 'https://www.11alive.com/article/news/education/atlanta-education-board-approves-aps-budget-for-2021-22-including-nearly-24m-in-pay-upgrades/85-92fabc8f-c8d1-47b6-9b32-318def0a2ff0' },
          { title: 'AJC' , url: 'https://www.ajc.com/news/atlanta-news/atlanta-schools-budget-includes-raises-more-mental-health-services/RSOGNFF75FAYHCIDKU4PQPZRRE/' },
          { title: 'AECF', url: 'https://www.aecf.org/blog/advancing-equity-in-atlantas-schools'},
          { title: 'GPB', url: 'https://www.gpb.org/news/2020/08/26/inequality-gap-among-atlanta-students-may-grow-due-covid-19-pandemic-experts-say'}
        ]}>
          <StyledMarkdown>
  {`The Atlanta Public School (APS) system defines equity as the “quality or ideal of being just and fair, regardless of economic, social, cultural, and human differences among and between persons.” In practice, this means **setting students up to achieve at the same level despite their differences, but without ignoring those differences**.

  There are a number of inequities within APS currently; students of color have been behind white students academically for years. Black students and other students of color are underrepresented in gifted programs and advanced placement courses and historically scored lower on math and reading tests than their white classmates. Students of color also face more discipline and suspensions than their white classmates. A majority of Atlanta students also live in poverty, making it more difficult for them to achieve in the classroom. A 2014 “equity audit” found that schools in different regions of the city receive unequal investment from APS, including different levels of spending, teacher quality, access to counselors and social workers, access to playgrounds, and PTA funding. According to AJC, some Atlanta parents believe Atlanta has “two systems in one,” with students from well off families receiving a better education than students from low income families. The COVID pandemic is expected by educational experts to expand these gaps between students of different backgrounds.

  APS has taken some measures to address inequity, including:
  - Changing criteria for gifted programs
  - Expanding access to extra-curriculars
  - Reviewing schools disciplinary measures
  - Drafting a new equity policy in 2019
  - Hiring its first Chief Equity and Social Justice Officer in February 2021

  APS’s 2021-2022 school year budget released during the summer of 2021 also includes  $377,000 for equity initiatives, such as:
  - Designating “district equity champions” to “lead professional learning related to equity”
  - Software for tracking and reporting diversity goals
  - Learning and outreach programs
  - Granting stipends of $1,000-$2,000 to teachers at 32 schools with the highest percentage of low-income students

  The budget also includes $2.8 million to hire 25 new school social workers and $1.1 million to hire 10 new psychologists.`}
          </StyledMarkdown>
        </FullPrimer>
      )
    }
  } else if(school && (nameMapped.includes('race') || nameMapped.includes('racism'))) {
    if(mode === 'short') {
      return (
        <ShortPrimer className={className} style={style}>
          Education on race and racism, and critical race theory in particular, became a major political issue for schools during the summer of 2021.
        </ShortPrimer>
      );
    } else {
      return (
        <FullPrimer className={className} style={style} sources={[
          { title: 'Associated Press' , url: 'https://apnews.com/article/what-is-critical-race-theory-08f5d0a0489c7d6eab7d9a238365d2c1' },
          { title: 'WABE', url: 'https://www.wabe.org/georgia-board-of-education-takes-aim-at-critical-race-theory/'},
          { title: 'Education in Atlanta', url: 'https://educationinatlanta.com/critical-race-theory-atlanta-public-schools-release-statement-regarding-state-boards-resolution-change/'}
        ]}>
          <StyledMarkdown>
  {`Education on race and racism, and critical race theory in particular, became a major political issue for schools during the summer of 2021. Critical race theory is a way of examining American history through the lens of racism. It is a high-level academic discipline that is mainly taught in law schools. Although critical race theory is not being taught in primary schools, the term became a catchall phrase for education about race and racism. Some argue that critical race theory concepts are an attempt to rewrite American history and persuade white students to feel guilty about their advantages.

Governor Kemp called critical race theory “dangerous” and “anti-American,” and sent a letter to the Georgia Board of Education asking them to ban the teaching of critical race theory in schools. The Georgia Board of Education then passed a resolution to limit classroom discussion on race and racism, stating “no state education, school district, or school shall teach or instruct concepts regarding race in the classroom that makes an individual feel discomfort, guilt, anguish, or any other form of psychological distress on account of his or her race or sex.” In response, Atlanta Public Schools released a statement against the resolution, saying it “is a grave mistake that is rooted in a lack of understanding of what Critical Race Theory is,” and that it could hurt students, whose racial backgrounds and academic outcomes are linked. `}
          </StyledMarkdown>
        </FullPrimer>
      )
    }
  } else if(school && (nameMapped.includes('college') || nameMapped.includes('career'))) {
    if(mode === 'short') {
      return (
        <ShortPrimer className={className} style={style}>
          Candidate positions reflect how they plan to prepare students to have a successful and productive life after school, whether they attend college or join the workforce.
        </ShortPrimer>
      );
    } else {
      return (
        <FullPrimer className={className} style={style} sources={[
          { title: 'GA CCRSC' , url: 'https://ccrscenter.org/ccrs-landscape/state-profile/georgia#:~:text=Career%20Ready%20Definitions-,College%20and%20Career%20Ready%20Definitions,college%2Dlevel%20work%20and%20careers' },
          { title: 'GA Dept. of Education', url: 'https://www.gadoe.org/CCRPI/Pages/default.aspx'},
          { title: 'APS', url: 'https://online.flippingbook.com/view/162673/'}
        ]}>
          <StyledMarkdown>
{`College and career readiness is a common priority for school districts, particularly at the high school level. Schools are tasked with preparing students for a successful and productive life regardless of whether they attend college or not. This preparation includes a rigorous educational foundation as well as exposure to a variety of career pathways, allowing students to develop skills necessary for college and the changing 21st century workforce.

Programs designed to enhance college and career readiness in APS include the Career, Technical, and Agricultural (CTAE) program, dual-enrollment program, and Move On When Ready program.

The Georgia Department of Education uses the College and Career Ready Performance Index (CCRPI) as a measurement of public schools’ ability to prepare students for college and the workforce. The CCRPI is scored on a 0-100 scale, with 100 being the best score a school can receive.

In 2019, **APS scored a 74.1 on the CCRPI while the state of Georgia as a whole scored a 78.8.**`}
          </StyledMarkdown>
        </FullPrimer>
      )
    }
  }

  return null;
}

const ShortPrimer = ({
  children,
  style,
  className
}) => {
  return (
    <Typography variant='body1' color='textPrimary' style={style} className={className}>
      {children}
    </Typography>
  )
}

const FullPrimer = ({
  children,
  style,
  className,
  sources
}) => {
  return (
    <Wrapper variant='body1' color='textPrimary' style={style} className={className}>
      {children}
      {
        sources?.length > 0 &&
        <SourceList sources={sources} style={{ justifyContent: 'flex-start' }} />
      }
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin-bottom: 12px;
  }
`

export { IssuePrimer, issueHasPrimer };
