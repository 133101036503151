import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
import { useFeathers } from '../../app/util';
import {
  DataDetailToolbar,
  PhotoOptionsHookups
 } from '../../components'
import styled from 'styled-components';
import {useParams} from "react-router-dom";


const DataHookupsBasicCoverage = () => {
  const feathers = useFeathers();
  const electionKey = useParams()?.key;
  const election = useSelector(state => state?.elections?.byKey[electionKey]);

  if(!electionKey) return null;

  return (
    <Wrapper>
      <DataDetailToolbar
        onBack={{ to: {
            pathname: `/elections/${electionKey}/data`,
          }}}
        navTree={[
          {
            text: 'Elections',
            to: {
              pathname: `/elections`,
            }
          },
          {
            text: election?.name,
            to: {
              pathname: `/elections/${electionKey}`,
            }
          },
          { 
            text: 'Data Hook-ups',
            to: {
              pathname: `/elections/${electionKey}/data`,
            }
          },
          {
            text: 'Photo selections'
          }
        ]}
      />
      <Typography style={{ marginBottom: '16px', marginTop: '24px' }} variant={'h1'}>Photo selections</Typography>
      <PhotoOptionsHookups electionKey={electionKey}/>
    </Wrapper>
  )
}


const Wrapper = styled.div`
  width: calc(100% - 24px - 36px);
  display: flex;
  flex-direction: column;
  padding: 36px 36px 36px 24px;
  align-items: stretch;
`;

export default DataHookupsBasicCoverage;
