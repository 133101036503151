import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { CandidateProgressBar } from '../ProgressBar';
import { CandidatePhoto } from '../CandidatePhoto';

import { Link } from 'react-router-dom';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CancelIcon from '@material-ui/icons/Cancel';
import moment from 'moment';

import Typography from "@material-ui/core/Typography";
import { ChevronRightButton } from '../ChevronRightButton';

import { ReactComponent as WarningIcon } from '../../images/yellow-warning.svg';
import { ReactComponent as GreenCheck } from '../../images/green-check.svg';
import { Skeleton } from '@material-ui/lab';
import { IconButton, Tooltip } from '@material-ui/core';
import ChartIcon from '@material-ui/icons/Equalizer';
import PriorityIcon from '@material-ui/icons/ImportExport';
import TimeIcon from '@material-ui/icons/AccessTime';
import { taskConfigurations } from '../../app/util';
import { useSelector } from 'react-redux';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { AssignmentReturnOutlined } from '@material-ui/icons';
import { useFeathers } from '../../app/util';
import { isPermitted } from '../../app/util';

/*
*  A preview tile used within the RaceDetail page. Displays a candidate's
*  name, photo, progress, and sync status
*/

// returns a cell for the TaskPreviewTable based on the column it exists in.
const ConfigurableTaskCell = ({
  task,
  column,
  actions,
  style,
  columnConfig = {}
}) => {
  const feathers = useFeathers();
  const user = useSelector(state => state.user);

  const [ anchorEl, setAnchorEl ] = useState(null);
  const formatTime = (timeInSec) => {
    const duration = moment.duration(timeInSec, 'seconds');
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    return `${hours ? `${hours}h ` : ''}${minutes ? `${minutes}m ` : ''}${seconds}s`
  }

  const actionsPermitted = useMemo(() => {
    return (actions || []).filter(action => {
        if(action === 'back-to-queue') {
          return isPermitted(user, ['editor', 'publisher', 'admin', 'super-admin']) && task.status === 'in-progress';
        }
        return true;
      }
    )
  }, [actions, user, task])

  const performAction = async (action) => {
    if(action === 'back-to-queue') {
      await feathers.getService('research-tasks').patch(task._id, {
        $returnToQueue: true
      })
    }
  }

  switch(column) {
      case 'name':
        return (
          <Cell
            style={{display: 'flex', flexDirection: 'column', ...style}}>
				    <Typography variant='body1'><b>{task?.name || task?.candidate?.name}</b></Typography>
				    {!columnConfig.hideType && <Typography variant="body2">{taskConfigurations[task.type]?.title ?? task.type}</Typography>}
            {columnConfig.showDetailName && Boolean(task.detailName) && <Typography variant="body2">{task.detailName}</Typography>}
			    </Cell>
        )
      case 'status':
        return (
          <Cell style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px', ...style }}>
          {
            task.status === 'complete' &&
            <CheckCircleIcon  fontSize='small'style={{ color: 'green' }} />
          }
          {
            (task.status === 'not-started' || task.status === 'in-progress') &&
            <AccessTimeIcon  fontSize='small'/>
          }
          {
            (task.status === 'cancelled') &&
            <CancelIcon fontSize='small' style={{ color: 'red' }} />
          }
          <div>
            {
              task.status === 'complete' &&
              <>
                <Typography variant='body1'><b>Completed</b></Typography>
                <Typography variant="body2">Completed by {task.completedByUser?.name ?? task.completedByUser?.firstName ?? task.completedByUser?.email} on {moment(task.completedAt).format('MMM Do, h:mma')}</Typography>
              </>
            }
            {
              task.status === 'in-progress' &&
              <>
                <Typography variant='body1'><b>In progress</b></Typography>
                <Typography variant="body2">Checked out by {task.checkedOutByUser?.name ?? task.checkedOutByUser?.firstName ?? task.checkedOutByUser?.email} on {moment(task.checkedOutAt).format('MMM Do, h:mma')}</Typography>
              </>
            }
            {
              task.status === 'not-started' &&
              <>
                <Typography variant='body1'><b>Not started</b></Typography>
              </>
            }
            {
              task.status === 'cancelled' &&
              <>
                <Typography variant='body1'><b>Cancelled</b></Typography>
              </>
            }
          </div>
        </Cell>
        )
      case 'metrics':
        return (
          <Cell style={{ display: 'flex', flexDirection: 'row', gap: '12px', ...style}}>
            	{
					task.status === 'complete' &&
					<Tooltip title='Time to complete'>
						<div style={{ display: 'flex', flexDirection: 'row', gap: '4px', alignItems: 'center' }}>
							<TimeIcon/>
							<Typography variant='body1' style={{ marginBottom: '-2px'}}>{task?.timeToCompleteSeconds ? formatTime(task.timeToCompleteSeconds) : '--'}</Typography>
						</div>
					</Tooltip>
				}
				{
					task.status !== 'complete' && <>
					<Tooltip title='Priority score of the task'>
						<div style={{ display: 'flex', flexDirection: 'row', gap: '4px', alignItems: 'center' }}>
							<PriorityIcon style={{ color: 'black' }}/>
							<Typography variant='body1' style={{ marginBottom: '-2px'}}>{task?.priority ?? 0}</Typography>
						</div>
					</Tooltip>
					{
						task.requiredResearcherLevel && (
							<Tooltip title='Researcher level required'>
								<div style={{ display: 'flex', flexDirection: 'row', gap: '4px', alignItems: 'center' }}>
									<ChartIcon style={{ color: 'black' }}/>
									<Typography variant='body1' style={{ marginBottom: '-2px'}}>{task.requiredResearcherLevel}</Typography>
								</div>
							</Tooltip>
						)
					}</>
				}
          </Cell>
        )
      case 'actions':
        return (
          <Cell style={{ justifySelf: 'end', ...style }}>
            {
              actionsPermitted?.length === 0 && <div style={{ width: '24px' }}/>
            }
            {
              actionsPermitted?.length > 0 &&
              <IconButton size='small' style={{ marginLeft: '12px', marginTop: '2px' }} onClick={(e) => setAnchorEl(e.currentTarget)}>
                <MoreVertIcon fontSize='small' style={{ opacity: 0.8 }} />
              </IconButton>
            }
            <Menu
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {
                actionsPermitted.includes('back-to-queue') && (
                  <MenuItem style={{ padding: '0px 16px' }} onClick={() => {
                    performAction('back-to-queue')
                    setAnchorEl(null)
                  }}>
                    <ListItemIcon>
                      <AssignmentReturnOutlined fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Back to queue</ListItemText>
                  </MenuItem>
                )
              }
            </Menu>
          </Cell>
        )
      case 'link':
        return (
          <Cell style={{ justifySelf: 'end', ...style }}>
					{task.status !== 'complete' && <Link to={`/tasks/${task.type}?_id=${task._id}`} style={{ }}>
						<ChevronRightButton />
					</Link>}
				</Cell>
        )
      default:
        return <RowSpacer />

  }
}

const RowSpacer = styled.div`
	grid-column: 1 / -1;
	height: 72px;
	width: 100%;
`

const Cell = styled.div`
	align-self: center;
`

export { ConfigurableTaskCell };
