import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import ReactTooltip from 'react-tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const StarRanker = ({
  value,      
  onChange,
  style,
  className,
  minValue = 1,
  maxValue = 5,
  detailMenuOnOneStar = false,
  dataTip
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);


  useEffect(() => {
    ReactTooltip.rebuild();
  }, [ dataTip ])
  
  const starRange = Array.from({ length: maxValue - minValue + 1 }, (_, i) => i + minValue);
  return (
    <Wrapper
      style={style}
      className={className}
      data-tip={dataTip}
    > 
      {starRange.map((i) => {
        const filledIn = value >= i;
        if(filledIn) {
          return <StarIcon key={i} onClick={i === 1 && detailMenuOnOneStar ? openMenu : () => onChange(i)} />
        } else {
          return <StarBorderIcon key={i} onClick={i === 1 && detailMenuOnOneStar ? openMenu : () => onChange(i)} />
        }
      })}
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Typography variant='body1' style={{ margin: '4px 12px', fontWeight: 'bold'}}>Why is this stance being hidden?</Typography>
        <MenuItem onClick={() => {
          onChange(1, 'useless')
          setAnchorEl(null)
        }}>
          <Typography variant='body1' style={{ margin: '2px 6px'}}>
            Nonsense or irrelvant information
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => {
          onChange(1, 'redundant')
          setAnchorEl(null)
        }}>
          <Typography variant='body1' style={{ margin: '2px 6px'}}>
            Redundant with other stances
          </Typography>
        </MenuItem>
      </Menu>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
  cursor: pointer;

`

export { StarRanker };