import { combineReducers } from 'redux';
import {
  USER_LOGIN,
  GET_ELECTIONS,
  USER_LOGOUT
} from './actions';

const replaceState = (oldState, newState) => Object.assign({}, oldState, newState);

const user = (state = {
  loggedIn: false,
  data: {}
}, action) => {
  switch(action.type) {
    case USER_LOGIN:
      if(action.response) {
        return {
          loggedIn: true,
          ...action.response
        }
      }
    case USER_LOGOUT:
      return {
        data: {},
        loggedIn: false
      };
    default:
      return state;
  }
}

const elections = (state = {
  default: null,
  list: [],
  byKey: {}
}, action) => {
  switch(action.type) {
    case GET_ELECTIONS:
      return {
        ...state,
        ...action.payload
      }
    case USER_LOGOUT:
      return {
        default: null,
        list: [],
        byKey: {}
      }
    default:
      return state;
  }
}


const combinedReducers = combineReducers({
  user,
  elections,
});
export default combinedReducers;
