import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import { ReactComponent as GlobeIcon } from './globe-icon-purple.svg';
import { LanguageSelectModal } from '../LanguageSelectModal';
import Modal from '@material-ui/core/Modal';

const LanguageSelectButton = ({
  onSelect,
  selected,
  style,
  className
}) => {
  const [ modalOpen, setModalOpen ] = useState(false);

  return (
    <>
      <IconButton style={style} className={className} data-tip='Translate' onClick={() => setModalOpen(true)}>
        <GlobeIcon style={{ width: '24px', height: '24px' }}/>
      </IconButton>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <LanguageSelectModal
          selected={selected}
          onSelect={(ln) => {
            onSelect(ln)
            setModalOpen(false)
          }}
          onClose={() => setModalOpen(false)}
        />
      </Modal>
    </>
  )
}

export { LanguageSelectButton };
