import React, { Component, useEffect, useState } from 'react';
import { useFeathers, isPermitted } from '../../app/util';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import asyncPool from 'tiny-async-pool';
import {
  CTAButton,
  LoadingSpinner,
  HintTile,
  PaginationStatus,
  PaginationControl,
  ElectionResultsSelectInput,
  ProgressBar,
  CirclePhoto,
  ManualVoteTally,
  DistrictFilter,
  PriorityFilter
 } from '../../components'
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { Skeleton } from '@material-ui/lab';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';


const numCells = 5;
const identifyRaces = async (feathers, electionKey, priorityLevel, incompleteOnly = true, $limit = 0, $skip = 0, extraParams) => {
  const numCandidate = priorityLevel
    ? (
      priorityLevel === 'high'
      ? { $gte: 3 } // high priority
      : 2           // low priority
    )
    : { $gte: 2 };  // all candidates
  const result = await feathers.getService('races').find({
    query: {
      election: electionKey,
      'resultSummary.numCandidates': numCandidate,
      ...(
        incompleteOnly 
        ? { 'resultSummary.percentageLinked': { $lt: 100 } }
        : {}
      ),
      ...(extraParams || {}),
      $limit: $limit,
      $skip,
      $sort: {
        districtSearchTerm: -1
      }
    }
  });

  if($limit === 0) return result.total;
  else return result;
}


const ElectionResultsHookups = ({
  electionKey,
  style,
  className,
  mode        // races or measures
}) => {
  const feathers = useFeathers();
  const electionData = useSelector(state => state?.elections?.byKey?.[electionKey]);

  const [ totalIncomplete, setTotalIncomplete ] = useState(undefined);
  const [ total, setTotal ] = useState(undefined);
  const [ error, setError ] = useState(null);
  const [ loading, setLoading ] = useState(true);
  const [ countPreviewText, setCountPreviewText ] = useState('');
  const [ activeRacesByCell, setActiveRacesByCell ] = useState([...Array(numCells).keys()]);
  const [ filterWithinDistrict, setFilterWithinDistrict ] = useState(null);
  const [ priorityLevel, setPriorityLevel ] = useState('high');

  const loadCount = async () => {
    setLoading(true)
    try {
      const racesTotal = await identifyRaces(feathers, electionKey, priorityLevel, false, 0);
      const racesIncomplete = await identifyRaces(feathers, electionKey, priorityLevel, true, 0);
      setTotal(racesTotal)
      setTotalIncomplete(racesIncomplete)
    } catch(error) {
      setError(error);
    } finally {
      setLoading(false)
    }
  }

  const setActiveRaceIdForCell = (cellNumber, raceId) => {
    let arrayNew = activeRacesByCell.slice();
    arrayNew[cellNumber] = raceId;
    setActiveRacesByCell(arrayNew);
  }

  useEffect(() => {
    if(feathers) loadCount()
  }, [ feathers ])

  useEffect(() => {
    if(typeof(totalIncomplete) !== 'undefined' && typeof(total) !== 'undefined') {
      setCountPreviewText(`${total - totalIncomplete} races out of ${total} races complete`)
    }
  }, [ totalIncomplete, total ])

  const previewText = () => (
    countPreviewText
      ? <Typography variant='body1' color='textPrimary'>{countPreviewText}</Typography>
      : <Skeleton variant="text" width={200}/>
  );

  if(loading) {
    return (
      <div style={style} className={className}>
        {previewText()}
        <Wrapper>
          <div style={{ padding: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <LoadingSpinner />
          </div>
        </Wrapper>
      </div>
    );
  }


  return (
    <div style={style} className={className}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        {previewText()}
        <div style={{ display: 'flex', gap: '24px', alignItems: 'center'}}>
          <Typography variant='body1'>Filter</Typography>
          <PriorityFilter value={priorityLevel} onChange={(newVal) => setPriorityLevel(newVal)}/>
          <DistrictFilter
            onChange={(d) => {
              setFilterWithinDistrict(d)
              if(d) setPriorityLevel(null)
            }}
            value={filterWithinDistrict}
            withinState={electionData?.state?._id || electionData?.state}
          />
        </div>
      </div>
      <Wrapper>
        {
          error &&
          <HintTile>
            {error.message}
          </HintTile>
        }
        <WrapperInner>
          { 
            [...Array(numCells).keys()].map(cellNum => <ERCell
              key={cellNum}
              electionKey={electionKey}
              setActiveRaceIdForCell={setActiveRaceIdForCell}
              activeRacesByCell={activeRacesByCell}
              cellNumber={cellNum}
              setNumIncomplete={setTotalIncomplete}
              filterWithinDistrict={filterWithinDistrict?._id}
              priorityLevel={priorityLevel}
            />)
          }
        </WrapperInner>
      </Wrapper>
    </div>
  );
}

const ERCell = ({
  filterWithinDistrict,
  priorityLevel,
  electionKey,
  setActiveRaceIdForCell,
  activeRacesByCell,
  cellNumber,
  orderMode = 'last-name',
  setNumIncomplete,
}) => {
  const [ race, setRace ] = useState(null);
  const [ loading, setLoading ] = useState(true);
  const [ saving, setSaving ] = useState(false);
  const [ batch, setBatch ] = useState(null);
  const [ refreshedResults, setRefreshedResults ] = useState({});
  const [ numLinked, setNumLinked ] = useState(0);
  const [ skipNum, setSkipNum ] = useState(0);
  const [ skipEffect, setSkipEffect ] = useState(0);
  const [ writeInVotes, setWriteInVotes ] = useState(null);
  const [ completedCandidateEffect, setCompletedCandidateEffect ] = useState(null);
  const feathers = useFeathers();

  const loadRace = async () => {
    setLoading(true);
    setBatch(null);
    setRace(null);
    setSaving(false);
    setSkipEffect(false);
    setRefreshedResults({})
    setNumLinked(0);
    setWriteInVotes(null);

    try {
      const { data, total } = await identifyRaces(feathers, electionKey, priorityLevel, true, numCells + 2, cellNumber + skipNum, filterWithinDistrict ? { $withinDistrict: { _id: filterWithinDistrict } } : undefined);
      setBatch(data)
      if(!filterWithinDistrict) setNumIncomplete(total)
    } catch(err) {

    } finally {
      setLoading(false)
    }
  }

  const skip = () => {
    setSkipNum(skipNum + 1)
    setSkipEffect(true)
  }

  const refereshRaceData = async () => {
    if(!race) return;
    try {
      const raceRefreshed = await feathers.getService('races').get(race?._id)
      setRace(raceRefreshed)
      console.log('set race')
    } catch(err) {
      console.log('error loading race')
      console.log(err)
    }
  }

  useEffect(() => {
    if(skipEffect) loadRace()
  }, [ skipEffect ])

  useEffect(() => {
    if(batch && !race) {
      const activeIdsExceptThisCell = activeRacesByCell.map((id, idx) => idx === cellNumber ? undefined : id).filter(Boolean);
      let raceSet;
      for(const race of batch) {
        if(!raceSet && !activeIdsExceptThisCell.includes(race._id)) {
          setRace(race);
          raceSet = true;
        }
      }
    }
  }, [ batch, activeRacesByCell, race ])

  useEffect(() => {
    if(completedCandidateEffect) {
      setCompletedCandidateEffect(null);
      setRefreshedResults({
        refreshedResults,
        ...completedCandidateEffect
      });
    }
  }, [ completedCandidateEffect, refreshedResults ])

  useEffect(() => {
    setActiveRaceIdForCell(cellNumber, race?._id)
    if(race) {
      setNumLinked(race.result?.numCandidatesLinked)
      setWriteInVotes(race?.writeInVotes)
    }
  }, [ race ])

  useEffect(() => {
    if(feathers) loadRace()
  }, [ feathers, filterWithinDistrict, priorityLevel ])

  if(loading) {
    return (
      <Cell>
        <Skeleton variant="text" width={150}/>
        <Skeleton variant="text" width={200}/>
        <Skeleton variant="text" width={100}/>
      </Cell>
    )
  }

  if(!loading && !race) return null;

  const complete = numLinked > 0 && numLinked === race?.result?.numCandidates;
  const baseUrl = window.location.origin;

  let candidatesOrdered;
  if(orderMode === 'first-name') 
    candidatesOrdered = race.candidates;
  else if(orderMode === 'last-name') 
    candidatesOrdered = race.candidates.sort((a, b) => {
      const lastNameA = a.name.split(' ').slice(-1)[0];
      const lastNameB = b.name.split(' ').slice(-1)[0];
      if(lastNameA === lastNameB) return 0;
      if(lastNameA < lastNameB) return -1;
      return 1;
    });

  const numCandidates = candidatesOrdered.length;

  return (
    <Cell>
      <div style={{ display: 'flex', justifyContent: 'space-between', gap: '24px' }}>
        <div style={{ display: 'flex', gap: '36px', alignItems: 'center' }}>
          <div>
            <div style={{ display: 'flex', gap: '4px', alignItems: 'flex-end' }}>
              <Typography variant='h3' style={{ fontWeight: 'bold' }}>{race.longName}</Typography>
              <a href={`${baseUrl}/elections/${electionKey}/races/${race._id}`} target='_blank'><OpenInNew/></a>
            </div>
            <Typography variant='body1' mt={1}>{race?.originalDistrict?.longName || race?.district?.longName}</Typography>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '250px'}}>
              <ProgressBar percentage={race?.result?.numCandidates > 0 ? (numLinked / race?.result?.numCandidates * 100) : 0} />
              <Typography variant='body1' style={{ opacity: 1.0, fontSize: '12px', marginTop: '2px' }}>{numLinked ?? 0}/{race?.result?.numCandidates ?? numCandidates} candidates linked</Typography>
            </div>
        </div>
        <div style={{ display: 'flex', gap: '12px', flexDirection: 'row', alignItems: 'flex-start' }}>
          <Typography variant='body1' onClick={skip} style={{ cursor: 'pointer'}}><u>Skip</u></Typography>
          {
            complete &&
            <Button variant='contained' color='primary' size='small' onClick={() => loadRace()}>
              Save
            </Button>
          }
        </div>
      </div>
      
      <div style={{ marginLeft: '16px', display: 'flex', flexDirection: 'column'}}>
        {
          race.candidates.map((cd, idx) => {
            const resultForCandidate = refreshedResults[cd?._id] ?? cd?.result;
            return (
              <div key={cd?._id} style={{ borderTop: idx === 0 ? '' : 'solid 1px #DDDDDD', padding: '12px 0 16px' }}>
                <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                  <CirclePhoto size='small' photoPath={cd?.photoPathFace} checkmark={resultForCandidate}/>
                  <Typography variant='h4' style={{ fontWeight: 'bold', marginLeft: '12px' }}>{cd.name}</Typography>
                  <a href={`${baseUrl}/elections/${electionKey}/races/${race._id}/candidates/${cd._id}`} target='_blank'><OpenInNew/></a>
                </div>
                <div style={{ width: 'calc(100% - 69px)', marginLeft: '69px', display: 'grid', flexDirection: 'column', gridTemplateColumns: '80px 150px 80px 80px 200px', gridGap: '8px', alignItems: 'center'}}>
                  <Typography variant='body1' >Automated</Typography>
                  <ElectionResultsSelectInput
                    onChange={() => console.log('Not yet implemented')}
                    value={null}
                    candidateId={cd?._id}
                    style={{ width: 'calc(150px)' }}
                  />
                  <div/>
                  <Typography variant='body1' >Manual</Typography>
                  <ManualVoteTally
                    showDataFieldName={false}
                    candidateId={cd?._id}
                    resultManual={resultForCandidate?.manual}
                    onSave={(newRes) => {
                      if(newRes) {
                        const update = {};
                        update[cd?._id] = newRes;
                        setCompletedCandidateEffect(update)
                      }
                      refereshRaceData()
                    }}
                  />
                </div>
              </div>
            )
          })
        }
        <div style={{ borderTop:  'solid 1px #DDDDDD', padding: '12px 0 16px 16px' }}>
          <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
            <Typography variant='body1' style={{ fontWeight: 'bold' }}>Write-in votes</Typography>
          </div>
          <div style={{ marginLeft: '16px', marginTop: '12px' }} >
            <ManualVoteTally 
              raceId={race?._id}
              writeInVotes={writeInVotes}
              onSave={(data) => {
                setWriteInVotes(data?.writeInVotes)
              }}
            />
          </div>
        </div>
      </div>
    </Cell>
  )

}


/* A component for identifying the number of candidates yet to be completed */
const ElectionResultsHookupCount = ({
  electionKey,
  mode,
  onLoadData      // accepts a structured object, with two properties: numRaces, numRacesCompleted
}) => {
  const feathers = useFeathers();

  const loadCounts = async () => {
    const racesAll = await identifyRaces(feathers, electionKey, null, false, 0);
    const racesIncomplete = await identifyRaces(feathers, electionKey, null, true, 0);
    if(onLoadData)
      onLoadData({
        numRaces: racesAll,
        numRacesCompleted: racesAll - racesIncomplete
      })
  }

  useEffect(() => {
    if(feathers) {
      loadCounts()
    }
  }, [ feathers, electionKey ])
  return <div/>
}


const Wrapper = styled.div`
  background-color: #FFFFFF;
  border: solid 1px #EEEEEE;
  border-radius: 8px;
  margin-top: 24px;
`


const WrapperInner = styled.div`
  border: solid 1px #EEEEEE;
  border-radius: 8px;
  background-color: #FFFFFF;
  display: grid;
  grid-template-columns: 1fr;
`

const Cell = styled.div`
  border-bottom: solid 1px #EEEEEE;
  padding: 24px 24px;  
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const OpenInNew = () => <OpenInNewIcon style={{ width: '16px', height: '16px', marginBottom: '-2px'}} />

export { ElectionResultsHookups, ElectionResultsHookupCount };
