import React, { Component } from 'react'
import styled from 'styled-components';

const TableWrapper = styled.table`
  font-size: 24px;
`

export default class Table extends Component {

  render() {
    return (
      <TableWrapper>

      </TableWrapper>
    )
  }
}
