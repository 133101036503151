import React, {useEffect, useState} from 'react';
import {isPermitted, useFeathers} from "../../app/util";
import styled from 'styled-components';
import {
  LoadingSpinner,
  CandidatePreviewTile, DueDate,
} from "../../components";
import {Link, useHistory, useLocation, useParams, useRouteMatch} from "react-router-dom";
import {useSelector} from "react-redux";
import Typography from '@material-ui/core/Typography'
import TextField from "@material-ui/core/TextField";
import moment from 'moment';
import ReactTooltip from "react-tooltip";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";


const MyAssignedWork = ({
  election,
  style,
  className,
  showActiveAssignment = true
}) => {
  const feathers = useFeathers();
  const history = useHistory();
  const user = useSelector(state => state?.user);
  const publisherLevel = isPermitted(user, ['publisher', 'editor', 'super-admin']);
  const reviewerLevel = publisherLevel || isPermitted(user, ['reviewer']);
  const researcherLevel = reviewerLevel || isPermitted(user, ['researcher'])
  const viewModeOptions = [
    publisherLevel ? 'publisher' : null,
    reviewerLevel ? 'reviewer' : null,
    researcherLevel ? 'researcher' : null
  ].filter(Boolean);

  const [ viewMode, setViewMode ] = useState(viewModeOptions[0]);
  const [ loading, setLoading ] = useState(true);
  const [ assignedCandidates, setAssignedCandidates ] = useState([]);
  const [ activeAssignment, setActiveAssignment ] = useState(null);
  const [ workOverview, setWorkOverview ] = useState(null);

  const loadAssignedWork = async () => {
    setLoading(true)
    setActiveAssignment(null)
    setWorkOverview(null)
    setAssignedCandidates([])

    if(['reviewer','researcher'].includes(viewMode)) {
      // Assigned work is where the user is assigned to the candidate
      // However, we also fold in batch-assignments for easy linking and due dates
      const candidatesQuery = {
        $limit: 50,
        election
      }
      candidatesQuery[`workflow.assignments.${viewMode}`] = user?._id;
      if(viewMode === 'researcher') {
        candidatesQuery.$or = [
          {
            'workflow.status': 'draft'
          },
          {
            'workflow.status': 'researcher-submitted',
            'workflow.rejectionStatus': true
          }
        ]
      } else if(viewMode === 'reviewer') {
        candidatesQuery.$or = [
          {
            'workflow.status': 'researcher-submitted',
            "workflow.rejectionStatus": false
          },
          {
            'workflow.status': 'reviewer-submitted',
            'workflow.rejectionStatus': true
          }
        ]
      }
      const [assignments, candidates] = await Promise.all([
        feathers.getService('batch-assignments').find({
          query: {
            closed: false,
            user: user?._id
          }
        }),
        feathers.getService('candidates').find({
          query: candidatesQuery
        })
      ])

      const activeAssignment = assignments.data[0];
      setActiveAssignment(activeAssignment)

      setAssignedCandidates(candidates.data.map(cd => ({
        ...cd,
        dueBy: (activeAssignment?.candidates || []).includes(cd?._id) ? activeAssignment.dueBy : null
      })))
    } else if (viewMode === 'publisher') {
      const candidates = await feathers.getService('candidates').find({
        query: {
          $limit: 50,
          election,
          $or: [
            {
              'workflow.status': 'reviewer-submitted',
              'workflow.rejectionStatus': false
            },
            {
              'workflow.status': 'publisher-submitted',
            }
          ]
        }
      })

      setAssignedCandidates(candidates.data)
      setWorkOverview(`There are ${candidates.total} candidates that have been initially reviewed and are awaiting final review.`);
    }

    setLoading(false)
    ReactTooltip.rebuild()
  }
  useEffect(() => {
    if(feathers) {
      loadAssignedWork()
    }
  }, [ feathers, viewMode ])

  const renderCdTile = cd => (<CandidatePreviewTile
    candidate={cd}
    key={cd._id}
    onClick={{ to: {
        pathname: `/elections/${cd?.election}/races/${cd?.race?._id || cd?.race}/candidates/${cd._id}`,
        state: {
          onBack: `/elections/${cd?.election}`
        }
      }}}
  />)

  return (
    <Wrapper style={style} className={className}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '12px' }}>
        {
          viewModeOptions?.length > 1 &&
          <Select
            onChange={e => setViewMode(e?.target.value)}
            name='viewMode'
            value={viewMode}
          >
            {
              viewModeOptions.map((value, idx) =>
                <MenuItem value={value} key={value}>{viewModeNames[value]}</MenuItem>
              )
            }
          </Select>
        }
      </div>
      {
        showActiveAssignment && !loading && activeAssignment &&
        <div style={{ width: '100%', padding: '4px 0 16px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <Typography variant={'body1'}>Due by</Typography>
            <DueDate
              date={activeAssignment.extensionDueBy || activeAssignment.dueBy}
            />
          </div>
          <Link to={`/elections/${election}/assignments/${activeAssignment?._id}`}>
            <Typography variant={'body1'}>Assignment details</Typography>
          </Link>
        </div>
      }
      {
        !loading && workOverview &&
        <Typography variant={'body1'} color={'primary'} style={{ padding: '4px 0 16px' }}>{workOverview}</Typography>
      }
      {
        loading
        ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 'calc(100% - 36px * 2)', padding: '36px'}}>
            <LoadingSpinner />
          </div>

        : (
            assignedCandidates?.length === 0
            ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 'calc(100% - 36px * 2)', padding: '36px'}}>
                <Typography variant={'body1'} style={{ textAlign: 'center' }}>No assigned profiles.<br/>Email your supervisor to be assigned more profiles.</Typography>
              </div>
            : <WrapperInner>
                {assignedCandidates.map(renderCdTile)}
              </WrapperInner>
          )
      }
      <ReactTooltip place='left' effect='solid' type='light' multiline/>
    </Wrapper>
  );
}

const viewModeNames = {
  'reviewer': 'Needs review',
  'publisher': 'Needs final review',
  'researcher': 'In research'
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: stretch;
`

const WrapperInner = styled.div`
  flex: 1;
  width: 100%;
  border-top: solid 1px #EEEEEE;
`

export { MyAssignedWork };