import React, { useEffect } from 'react';
import styled from 'styled-components';
import * as d3 from 'd3';
import moment from 'moment';

const LineGraph = ({
  data: dataGiven,    // an array with each object having values date and value
  startDate,
  endDate,
  width: widthGiven,  // defaults to 960
  height: heightGiven,  // defaults to 500
  units = 'profiles',
  className,
  style
}) => {

  const createGraph = async () => {
    // read data from csv and format variables
    let data = dataGiven.slice();
    data = datesPadded(startDate, endDate, data);

    var parseTime = d3.timeParse("%Y-%m-%d");
  
    data.forEach((d) => {
      d.date = parseTime(d.date);
      d.value = +d.value;
    });
    console.log(data)

    // set the dimensions and margins of the graph
    var margin = { top: 10, right: 40, bottom: 20, left: 20 },
    width = (widthGiven ?? 960) - margin.left - margin.right,
    height = (heightGiven ?? 500) - margin.top - margin.bottom;

    // append the svg object to the body of the page
    var svg = d3.select(".viz").append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", `translate(${margin.left}, ${margin.top})`);

    // add X axis and Y axis
    var x = d3.scaleTime().range([0, width]);
    var y = d3.scaleLinear().range([height, 0]);


    if(startDate && endDate) {
      x.domain([parseTime(startDate), parseTime(endDate)]);
    } else {
      x.domain(d3.extent(data, (d) => { return d.date; }));
    }
    y.domain([0, d3.max(data, (d) => { return d.value; })]);

    const xAxis = d3.axisBottom(x)
      .ticks(d3.timeDay);
  
    svg.append("g")
      .attr("transform", `translate(0, ${height})`)
      .call(xAxis);

    const yAxis = d3.axisLeft(y)
      .ticks(Math.ceil(d3.max(data, (d) => d.value))); // Set ticks to every whole number

    svg.append("g")
      .call(yAxis);
      
    // add the Line
    var valueLine = d3.line()
      .x((d) => { return x(d.date); })
      .y((d) => { return y(Math.max(d.value, 0)); })
      .curve(d3.curveMonotoneX);
  
    svg.append("path")
      .data([data])
      .attr("class", "line")
      .attr("fill", "none")
      .attr("stroke", "steelblue")
      .attr("stroke-width", 1.5)
      .attr("d", valueLine);

    const tooltip = d3.select("body").append("div")   
      .attr("style", `position: fixed;
      display: none;
      background-color: white;
      border: 1px solid black;
      padding: 5px;
      pointer-events: none;`)      

    svg.selectAll("circle")
      .data(data)
      .enter()
      .append("circle")
      .attr("cx", (d) => x(d.date))
      .attr("cy", (d) => y(d.value))
      .attr("r", 30)
      .attr("fill", "#00000000")
      .on("mouseover", (event, d) => {
        tooltip
          .style("display", "block")
          .html(`<div style="display: flex; flex-direction: column; gap: 8px">
            <span><b>${d.value} ${units}</b></span>
            <span>${moment(d.date).format('LL')}</span>
          </div>`);
      })
      .on("mousemove", (event) => {
        tooltip
          .style("left", (event.clientX + window.scrollX + 10) + "px")
          .style("top", (event.clientY + window.scrollY - 20) + "px");
      })
      .on("mouseout", () => {
        tooltip.style("display", "none");
      });
  }
  
  useEffect(() => {
    if(dataGiven.length > 0 ) createGraph();
  }, [ dataGiven ]);

  return (
    <Wrapper className={className} style={{ ...style, height: `${heightGiven ?? 500}px`, width: `${widthGiven ?? 960}px` }}>
      <div class="viz" style={style}></div>
      <div id="tooltip" style={{ position: 'absolute', display: 'none', backgroundColor: 'white', border: '1px solid black', padding: '5px' }}></div>
    </Wrapper>
  );
}

const datesPadded = (startDate, endDate, results) => {
  const numDays = moment.utc(endDate).diff(moment.utc(startDate), 'days') + 1;
  const dateRangeEmpty = [...Array(numDays)].map((bl, i) => moment.utc(startDate).add(i, 'days').locale('en').format('YYYY-MM-DD'));
  return dateRangeEmpty.map(dateX => {
    const { date, ...result } = (results || []).find(res => res?.date === dateX) || {};
    return {
      date: dateX,
      value: result?.value ?? 0
    }
  })
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  #tooltip {
    position: absolute;
    display: none;
    background-color: white;
    border: 1px solid black;
    padding: 5px;
    pointer-events: none;
  }
`;

export { LineGraph };