import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Eye } from './preview.svg';
import { onClickOrLink } from '../../app/util';
import { CTAButton } from '../CTAButton';

const PreviewButton = ({
  style,
  ...rest
}) => {
  const height = 38;
  const width = 38;
  const svgHeight = 38 - 9 * 2;
  const svgWidth = 38 - 6 * 2;

  const buttonStyle = {
    height: `${svgHeight}px`,
    width: `${svgWidth}px`,
    border: '1px solid #EEEEEE',
    backgroundColor: '#FFFFFF',
    padding: '9px 6px'
  };

  return (
    <CTAButton
      mode='normal'
      style={{
        ...buttonStyle,
        ...style
      }}
      dropShadowEnabled={false}
      hoverHighlightColor='#F6F6F6'
      {...rest}
    >
      <EyeSVG width={svgWidth} height={svgHeight}/>
    </CTAButton>
  );
}

const EyeSVG = styled(Eye)`
  height: ${props => props.height};
  width: ${props => props.width};
  align-self: center;
`;

export { PreviewButton };
