import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import CopySvg from './copy.js';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const CopyText = ({
  text,
  style = {},
  onCopy,
  buttonOnly = false, 
  multiline 
}) => {
  const [ copiedText, setCopiedText ] = useState(false);

  useEffect(() => {
    if(copiedText) {
      if(onCopy) onCopy()
      const timer = setTimeout(() => setCopiedText(false), 2000);
      return () => clearTimeout(timer)
    }
  }, [ copiedText ])

  const textSplit = text.split('http');
  return (
    <Wrapper style={style} showborder={buttonOnly ? 0 : 1}>
      {
        !buttonOnly &&
        <TextWrapper>
          <TextWrapperInner>
            <Typography
              variant='body1'
              color='textSecondary'
              style={{
                ...(multiline
                  ? {}
                  : { whiteSpace: 'nowrap' }
                )
              }}
            >
              {text}
            </Typography>
          </TextWrapperInner>
        </TextWrapper>
      }
      <CopyToClipboard text={text} onCopy={() => setCopiedText(true)}>
        <Button
          variant='contained'
          color='secondary'
          size='small'
          style={{
            minWidth: 'auto',
            fontSize: '16px'
          }}
          startIcon={<CopySvg />}
        >
          { buttonOnly ? '': (copiedText ? 'Copied!' : 'Copy') }
        </Button>
      </CopyToClipboard>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${
    props => props.showborder ? `padding: 8px;

    border: solid 1px #E0E0E0;
    background-color: #FFFFFF;
    border-radius: 8px;` : ``
  }
  
`

const TextWrapper = styled.div`
  flex: 1;
  margin-right: 4px;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`
const TextWrapperInner = styled.div`

`


export { CopyText, CopySvg };
