import React, { useState, useEffect } from 'react';
import { Button, IconButton, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { useFeathers } from '../../app/util';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { DistrictSelectInput } from '../../components';
import CloseIcon from '@material-ui/icons/Close';


const EditCoveragePlan = ({
	election: electionKeyGiven,
	onSave,
	style,
	className
}) => {
	const feathers = useFeathers();
	const [ electionImplementation, setElectionImplementation ] = useState(null);
	const [ election, setElection ] = useState(null);

	const [ saving, setSaving ] = useState(false);
	const [ coverageType, setCoverageType ] = useState(null);
	const [ stateId, setStateId ] = useState(null);
	const [ loading, setLoading ] = useState(true);
	const [ error, setError ] = useState(null);
	const [ supportedArea, setSupportedArea ] = useState([]);
	const submittable = (
		coverageType === 'statewide' ||
		(coverageType === 'districts' && supportedArea.length > 0)
	);

	const loadElectionImplementation = async () => {
		try {
			const res = await feathers.getService('election-implementations').get(electionKeyGiven);
			setElectionImplementation(res);

			const election = await feathers.getService('elections').get(electionKeyGiven);
			setElection(election);
			setStateId(election?.state?._id);

			if(res.supportedArea?.length > 0) {
				if(res.supportedArea.length === 1 && res.supportedArea.includes(election.state._id)) {
					// if it's just the state, we're statewide
					setCoverageType('statewide')
					setLoading(false)
				} else {
					// load with the existing data
					const districts = await feathers.getService('districts').find({
						query: {
							_id: res.supportedArea
						}
					})

					setSupportedArea(districts.data)
					setCoverageType('districts')
					setLoading(false)
				}
			} else {
				setLoading(false)
				setCoverageType(null)
				setSupportedArea([])
			}
		} catch(err) {
			setError(null)
		}
	}

	const addToSupportedArea = (district) => {
		// filter out duplicates
		const existing = supportedArea.map(sa => sa._id)
		if(existing.includes(district._id)) return;
		setSupportedArea([
			...supportedArea,
			district
		])
	}

	const removeFromSupportedArea = (district) => {
		const newSupportedArea = supportedArea.filter(sa => sa._id !== district._id)
		setSupportedArea(newSupportedArea)
	}

	const saveUpdates = async () => {
		if(saving) return;
		setSaving(true);

		try {
			const supportedAreaToSave = coverageType === 'statewide' ? [ stateId ] : supportedArea.map(sa => sa._id);
			await feathers.getService('election-implementations').patch(electionKeyGiven, {
				supportedArea: supportedAreaToSave
			})
			if(onSave) onSave()
		} catch(err) {
			console.error(err)
			setSaving(false)
		}
	}

	useEffect(() => {
		if(feathers) {
			loadElectionImplementation()
		}
	}, [feathers])

	if(loading) {
		return (
			<Wrapper style={style} className={className}>
				<Typography variant='body1'>Loading...</Typography>
			</Wrapper>
		)
	}

	return (
		<Wrapper style={style} className={className}>
			{
				error && <Typography variant='body1' color='error'>Error loading election implementation</Typography>
			}
			<Section>
				<Typography variant='body1'>The coverage area for an election defines how many counties/cities we'll need need to fully identify the ballot items for.</Typography>
			</Section>
			<Section>
				<Typography variant='h3'>Select coverage type</Typography>
				<Select
					value={coverageType}
					onChange={e => setCoverageType(e.target.value)}
					variant='outlined'
				>
					<MenuItem value={'statewide'}>Statewide</MenuItem>
					<MenuItem value={'districts'}>Select specific districts</MenuItem>
				</Select>
			</Section>
			{
				Boolean(coverageType) &&
				coverageType === 'districts' &&
				<Section>
					<Typography variant='h3'>Select the districts we'll cover</Typography>
					<Typography variant='body1' style={{  }}><b>Districts</b></Typography>
					{
						supportedArea.length === 0 &&
						<Typography variant='body1'>No districts selected</Typography>
					}
					{supportedArea.map((sa, i) => (
						<SupportedDistrictRow>
							<Typography key={i} variant='body1'>{sa?.longName}</Typography>
							<IconButton size='small' onClick={() => removeFromSupportedArea(sa)}>
								<CloseIcon  style={{ height: '14px', width: '14px' }} />
							</IconButton>
						</SupportedDistrictRow>
					))}
					<DistrictSelectInput
						onChange={e => addToSupportedArea(e.target.value)}
						value={null}
						style={{ marginTop: '20px'}}
						districtTypes={['county', 'city']}
						descendantOf={[ stateId ]}
						clearAfterSelect
					/>
				</Section>
			}
			{
				submittable &&
				<Section>
					<Button
						variant='contained'
						color='primary'
						onClick={saveUpdates}
						disabled={saving}
						fullWidth={false}
					>
						Save
					</Button>
				</Section>
			}
		</Wrapper>
	)
}

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 24px;
`

const Section = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`

const SupportedDistrictRow = styled.div`
	display: grid;
	grid-template-columns: 1fr auto;
	align-items: center;
	grid-gap: 12px;
	border-radius: 4px;

	&:hover {
		background-color: #dddddd;
		/* make slighly bigger */
		transform: scale(1.01);
	}
`

export { EditCoveragePlan };