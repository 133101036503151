import React from 'react';
import styled from 'styled-components';

const NotificationBadge = ({
  style,
  className
}) => (<Badge className={className} style={style} />)

const Badge = styled.div`
  background-color: #453DB7;
  height: 10px;
  min-height: 10px;
  width: 10px;
  min-width: 10px;
  border-radius: 30px;
`

export { NotificationBadge };
