
/* This component helps us visualize a diff for a single block of text */

import { Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const ViewDiff = ({ diff, style, className }) => {

	return (
		<Typography variant='body1' color='textPrimary' style={style} className={className}>
			{
				(diff || []).length === 0 && <Segment diffColor='grey'>No changes</Segment>
			}
			{
				(diff || []).length === 1 && ((diff[0].value || '').trim() === "") && <Segment diffColor='grey'>No changes</Segment>
			}
			{diff.map((part, index) => {
				const color = part.added ? 'green' : part.removed ? 'red' : 'grey';
				return (
					<Segment key={index} diffColor={color}>
						{part.value}
					</Segment>
				);
			})}
		</Typography>
	)
}

const Segment = styled.span`
	padding: 4px 0 4px;
	${props => props.diffColor === 'green' ? `
		font-weight: bold;
		color: green;
		background-color: #d3f8d3;
	` : ``}

	${props => props.diffColor === 'red' ? `
		font-weight: bold;
		color: red;
		background-color: #FFD6D6;
	` : ``}
`

export { ViewDiff };