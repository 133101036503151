import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useFormInputs, useFeathers } from '../../app/util';
import { SearchableSelectInput } from '../SearchableSelectInput';

const IssueSelectInput = ({
  value,
  onChange,
  name='issue',
  style = {},
  disabled = false,
  className,
  impactIssue = false,                // one of these needs to be set to true for this component to be useful
  candidateIssue = false
}) => {
  const feathers = useFeathers();

  const [ optionsForField, setOptionsForField ] = useState([]);
  const [ loadingForField, setLoadingForField ] = useState(false);
  const searchForField = async (searchText) => {
    if(!feathers) return;
    setLoadingForField(true);

    const issues = await feathers.getService('issues').find({ query: {
      ...(searchText ? { $search: searchText } : {}),
      ...(impactIssue ? { impactIssue } : {}),
      ...(candidateIssue ? { candidateIssue, supportedByProfiler: candidateIssue } : {}),
    }})
    const options = issues.data.map(({ name, key }) => ({ text: name, key, name }));

    setOptionsForField(options)
    setLoadingForField(false);
  }

  return (
    <SearchableSelectInput
      name={name}
      onChangeSearchText={(searchText) => searchForField(searchText)}
      value={value}
      onChange={onChange}
      optionsLoading={loadingForField}
      options={optionsForField || []}
      style={{
        backgroundColor: '#FFFFFF',
        ...(style || {})
      }}
      disabled={disabled}
      className={className}
      placeholder={'Issue name'}
    />
  )
}

export { IssueSelectInput };
