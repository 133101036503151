import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Lightbulb } from './lightbulb.svg';

const HintTile = ({
  children,
  style
}) => {
  return (
    <Tile style={style}>
      <Lightbulb className='light'/>
      <div className='inner'>
        {children}
      </div>
    </Tile>
  )
}

const Tile = styled.div`
  background-color: #EFF1B6;
  border: 1px #827D62 solid;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px 20px;

  svg.light {
    margin-right: 20px;
    width: 33px;
    min-width: 33px;
    height: auto;
  }

  div.inner {
    flex-grow: 1;
  }
`;

export { HintTile };
