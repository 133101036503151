import React from 'react';
import styled from 'styled-components';
import { CirclePhoto } from '../CirclePhoto';

const CirclePhotoStack = ({
  photoPaths = [],
  size = 'small',
  containerWidth = 250
}) => {
  // each photo is 48px
  const minOffset = 16;
  const photoSize = 48;
  const offsetCalc = ((photoPaths.length * 48 ) - containerWidth + 48)/(photoPaths.length - 1);
  const offset = Math.max(minOffset, offsetCalc)
  return (
    <Wrapper style={{ paddingLeft:  `${offset}px` }}>
      {photoPaths.map((path, idx) => <CirclePhoto
          photoPath={path}
          size={size}
          key={idx}
          style={{ marginLeft: `-${offset}px` }}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export { CirclePhotoStack };
