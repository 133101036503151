import React, { useState } from "react";
import { useFeathers } from "../../app/util";
import { CandidateNamePhoto } from "../CandidateNamePhoto";
import { SearchableSelectInput } from "../SearchableSelectInput";
import { name } from '@branchpolitics/microservice.clean.name';

const OfficialsSelectInput = ({ onChange, value, style, className }) => {
	const feathers = useFeathers();

	const [	options, setOptions] = useState(null);
	const [ loading, setLoading ] = useState(false);

  const searchForOfficialField = async (searchText) => {
		setLoading(true)
    const officials = await feathers
      .getService("officials")
      .find({
        query: searchText ? { searchTerm: { $search: searchText } } : {},
      });
    const options = officials.data
      .map((off) => ({
        key: off.key,
        photo:
          off.recentCandidate?.stagingDraft?.photoPathFace ||
          off.recentCandidate?.photoPathFace,
        name:
          off.recentCandidate?.stagingDraft?.name || off.recentCandidate?.name,
      }))
      .map((o) => ({ ...o, text: o.name, photo: o.photo }));
		setOptions(options);
		setLoading(false)
  };

  return (
		<SearchableSelectInput
			name='official'
			onChangeSearchText={searchForOfficialField}
			modifySearchText={(textPrev, textNew) => {
				// if we just pasted a bunch of text, clean each candidate name
				if ((textNew || '')?.length - 5 > (textPrev || '')?.length)
					return name(textNew)
				else
					return textNew;
			}}
			value={value}
			onChange={onChange}
			optionsLoading={loading}
			options={options || []}
			style={style}
			className={className}
			searchOptionComponent={CandidateNamePhoto}
			allowNewOption
			formatNewOptionFromSearchText={(text) => `Create a new candidate named "${text}"`}
			onAddNewOption={(value) => onChange({ target: { isNew: true, name: 'official', value: { name: value, text: value, isNew: true} } })}
		/>
	)
}

export { OfficialsSelectInput };