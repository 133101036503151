import React from 'react';
import styled from 'styled-components';

const SourceList = ({
  sources = [],
  style = {}
}) => {
  const titleForSource = (source) => {
    if(source.sourceType === 'website') return 'Candidate website';
    else if(source.sourceType === 'questionnaire') return source?.title || 'Branch questionnaire';
    else return source?.title || 'Article';
  }
  return (
    <Wrapper style={style}>
      {
        sources.length > 0 &&
        <>
          <span>Sources:</span>

        </>
      }
      {
        sources.length > 0 &&
        sources.map((source, i) => (
          <React.Fragment
            key={i}
          >
            <Space/>
            <a href={`${source.url}`} target='_blank'>
              {titleForSource(source)}
            </a>
            {
              (i + 1) !== sources.length &&
              <span>,</span>
            }
          </React.Fragment>
        ))
      }
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;

  a, span {
    font-size: 13px;
    opacity: 0.7;
  }
`

const Space = styled.div`
  width: 3px;
`;

export { SourceList };
