export const coveragePlanSpecifications = {
  'issues-coverage': {
    description: 'Our most thorough coverage of a race, generated manually. Each candidate is expected to have bios, summaries of platforms,' +
      ' along with basic information such as photos, name, website, and socials.',
    assignmentsEnforced: true,
    supportsWorkflow: true,
    minPermissionToPublish: 'publisher',
    progressArrayForCandidate: candidate => ([
      {
        field: 'Personal Background',
        complete: candidate.bioPersonal?.length > 0 || candidate.missingData?.bioPersonal,
        requiredFor: ['completion','publish', 'submission']
      },
      {
        field: 'Professional Background',
        complete: candidate.bioProfessional?.length > 0 || candidate.missingData?.bioProfessional,
        requiredFor: ['completion','publish', 'submission']
      },
      {
        field: 'Political Background',
        complete: candidate.bioPolitical?.length > 0 || candidate.missingData?.bioPolitical,
        requiredFor: ['completion','publish', 'submission']
      },
      {
        field: 'Information Sources',
        complete: candidate.references?.checked,
        requiredFor: ['completion','publish', 'submission']
      },
      {
        field: 'Photo',
        complete: candidate.photoPathFace,
        requiredFor: []
      },
      {
        field: 'Name',
        complete: candidate.name,
        requiredFor: ['completion','publish', 'submission']
      },
      ...(candidate.issues || []).map(({ key, complete }) => ({
        field: 'Issue: ' + key,
        complete,
        requiredFor: ['completion','publish', 'submission']
      })),
      {
        field: 'Qualification Status',
        complete: ['yes','no'].includes(candidate?.qualified),
        requiredFor: ['completion','publish']
      }
    ])
  },
  'auto-coverage': {
    description: 'Full coverage of a candidate, with bios and issues, but mainly done through ai generated content + review.',
    assignmentsEnforced: false,
    supportsWorkflow: false,
    minPermissionToPublish: 'publisher',            // this field allows publishing for all candidates, regardless of researcher's publish level
    progressArrayForCandidate: candidate => {

      return [
        {
          field: 'Personal Background',
          complete: candidate.bioPersonal?.length > 0 || candidate.missingData?.bioPersonal,
          requiredFor: ['completion','publish', 'submission']
        },
        {
          field: 'Professional Background',
          complete: candidate.bioProfessional?.length > 0 || candidate.missingData?.bioProfessional,
          requiredFor: ['completion','publish', 'submission']
        },
        {
          field: 'Political Background',
          complete: candidate.bioPolitical?.length > 0 || candidate.missingData?.bioPolitical,
          requiredFor: ['completion','publish', 'submission']
        },
        {
          field: 'Information Sources',
          complete: candidate.references?.checked,
          requiredFor: ['completion','publish', 'submission']
        },
        {
          field: 'Photo',
          complete: candidate.photoPathFace,
          requiredFor: []
        },
        {
          field: 'Name',
          complete: candidate.name,
          requiredFor: ['completion','publish', 'submission']
        },
        ...(candidate.issues || []).map(({ key, complete }) => ({
          field: 'Issue: ' + key,
          complete,
          requiredFor: ['completion','publish', 'submission']
        })),
        {
          field: 'Qualification Status',
          complete: ['yes','no'].includes(candidate?.qualified),
          requiredFor: ['completion','publish']
        }
      ]
    }
  },
  'basic-coverage': {
    description: 'Basic coverage of a race. Each candidate has simple identifying information that a voter can use to find more info.' +
      ' This includes website, social media, and photos.',
    assignmentsEnforced: false,
    supportsWorkflow: false,
    minPermissionToPublish: 'reviewer',
    progressArrayForCandidate: candidate => {
      const candidateWebsite = (candidate.references?.categories || []).find(rc => rc.type === 'website');
      const candidateSocials = (candidate.references?.categories || []).find(rc => rc.type === 'social');

      return [
        {
          field: 'Name',
          complete: candidate.name,
          requiredFor: ['completion']
        },
        {
          field: 'Qualification Status',
          complete: ['yes','no'].includes(candidate?.qualified),
          requiredFor: ['completion']
        },
        {
          field: 'Website',
          complete: candidateWebsite?.missing || candidateWebsite?.sources?.length > 0,
          requiredFor: ['completion']
        },
        {
          field: 'Social media',
          complete: candidateSocials?.missing || candidateSocials?.sources?.length > 0,
          requiredFor: ['completion']
        },
        {
          field: 'Photo',
          complete: candidate.photoPathFace,
          requiredFor: []
        }
      ]
    }
  },
  'no-coverage': {
    description: 'We will only have names and party for each candidate. Each candidate is "complete" upon creation.',
    assignmentsEnforced: false,
    supportsWorkflow: false,
    minPermissionToPublish: 'reviewer',
    progressArrayForCandidate: candidate => ([
      {
        field: 'Name',
        complete: candidate.name,
        requiredFor: ['completion']
      },
      {
        field: 'Qualification Status',
        complete: ['yes','no'].includes(candidate?.qualified),
        requiredFor: ['completion']
      }
    ])
  }
}