import React from 'react';
import styled from 'styled-components';

const PhotoSelectFromMulti = ({
	style,
	className,
	photoOptions,
	size,
	onSelect
}) => {
	let dim = 90;
	if(size === 'small') dim = 30;
	return (
		<Wrapper className={className} style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', overflowX: 'scroll', ...(style || {}) }}>
			{(photoOptions || []).map(src => (
				<ImageTile
					dim={dim}
					key={src}
					src={src}
					onClick={() => onSelect(src)}
				/>
			))}
		</Wrapper>
	);
}

const ImageTile = styled.img`
  height: ${({dim}) => dim}px;
  width: ${({dim}) => dim}px;
  min-height: ${({dim}) => dim}px;
  min-width: ${({dim}) => dim}px;
  object-fit: cover;
  margin-right: 12px;
  border-radius: 50%;
  cursor: pointer;
`

const Wrapper = styled.div`
	padding-bottom: 4px;
	::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 2px;
	height: 7px;
  }
  
  ::-webkit-scrollbar-thumb {
	border-radius: 3px;
	background-color: rgba(0, 0, 0, .5);
	box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
`

export { PhotoSelectFromMulti };