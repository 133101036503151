import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CirclePhoto } from '../CirclePhoto';
import { useFeathers, dataEditorUrlFromEntity, isPermitted } from '../../app/util';
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import { Skeleton } from "@material-ui/lab";
import { Link } from "@material-ui/core";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import { Typography } from '@material-ui/core';
import {
	FieldInlineEdit,
	DataField
} from '../';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useSelector } from 'react-redux';

const errorTypeDescriptions = {
	'data': {
		description: 'Something in our underlying candidate or race data is incorrect'
	},
	'content': {
		description: 'Something that went wrong in our candidate research'
	},
	'geographical': {
		description: 'A user is being matched to the incorrect districts'
	},
	'technical': {
		description: 'A bug with the behavior of the website'
	}
}


const ReportedError = ({
	reportedErrorId,
	onLoadErrorFull,
	onChange						// called whenver status and/or notes change
}) => {
	const feathers = useFeathers();
  const user = useSelector(state => state.user);
  const [ loading, setLoading ] = useState(false);
  const [ detailedData, setDetailedData ] = useState([]);
  const [ reportedError, setReportedError ] = useState(null)
  const [ errorNotes, setErrorNotes ] = useState('')
  const [ status, setStatus ] = useState(null)

	const [ newErrorType, setNewErrorType ] = useState(null);
	const [ editingErrorType, setEditingErrorType ] = useState(false);
	const [ newErrorTypeSaving, setNewErrorTypeSaving ] = useState(false);

	useEffect(() => {
		if(onLoadErrorFull) onLoadErrorFull(reportedError)
	}, [ reportedError ])
	const saveNewErrorType = async () => {
		try {
			setNewErrorTypeSaving(true)
			const reportedErrorNew = await feathers.getService('reported-errors').patch(reportedErrorId, {
				type: newErrorType
			})

			setReportedError(reportedErrorNew)
			setEditingErrorType(false)
		} catch(err) {
			console.log(err)
		} finally {
			setNewErrorTypeSaving(false)
		}
	}

	const loadData = async () => {
		setLoading(true)
		setReportedError(null)
		setDetailedData([])
    const reportedError = await feathers.getService('reported-errors').get(reportedErrorId);
    setReportedError(reportedError);
    setErrorNotes(reportedError.notes || reportedError.note || '')
    setStatus(reportedError.status)
		await loadDetailedData(reportedError)
		setLoading(false)
  }

	useEffect(() => {
		if(onChange) {
			onChange({
				status,
				notes: errorNotes
			})
		}
	}, [ status, errorNotes ])


  // get the race and other info from the reported error, if applicable
  const loadDetailedData = async (reportedError) => {
    // Identify key sources of data and load those
    let dataToLoad = [];
    if(reportedError?.service && reportedError?.serviceId) {
      dataToLoad.push({
        service: reportedError.service,
        id: reportedError.serviceId
      })
    }

    if(reportedError?.election) {
      dataToLoad.push({
        service: 'elections',
        id: reportedError.election
      })
    }

    if(reportedError?.details?.candidateId) {
      dataToLoad.push({
        service: 'candidates',
        id: reportedError.details.candidateId
      })
    }

    // dedeup the reportedError array
    dataToLoad = dataToLoad.filter((v,i,a)=>a.findIndex(t=>(t.service === v.service && t.id===v.id))===i)

    const loadedData = await Promise.all(dataToLoad.map(async (data) => {
      const service = data.service;
      const id = data.id;
      const result = await feathers.getService(service).get(id);
      const url = dataEditorUrlFromEntity(result, service);
      let title, renderItem;
      if(service === 'candidates') {
        title = 'Candidate';
        renderItem = () => <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <CirclePhoto src={result.photoPathFace} size='small'/>
          <Typography variant='body1'>{result.name}</Typography>
        </div>
      } else if(service === 'races') {
        title = 'Race';
        renderItem = () => <Typography variant='body1'>{result.longName}</Typography>
      } else if(service === 'elections') {
        title = 'Election';
        renderItem = () => <Typography variant='body1'>{result.name}</Typography>
      }

      return {
        service,
        id,
        result,
        url,
        title,
        renderItem
      }
    }));

		let additionalData = [];
		if(reportedError?.reportedWithBallot) {
			const writeAs = [
				reportedError.reportedWithBallot?.addressComponents?.prettyStreet,
				reportedError.reportedWithBallot?.addressComponents?.party
			].filter(Boolean).join(' - ')
			additionalData.push({
				service: 'ballots',
        id: reportedError.reportedWithBallot?._id,
        result: reportedError.reportedWithBallot,
        url: undefined,
        title: 'Ballot',
        renderItem: () => <Typography variant='body1'>{writeAs}</Typography>
			})
		}

		const allData = [...loadedData, ...additionalData];

    setDetailedData(allData);

    setLoading(false)
  }

  useEffect(() => {
    if (!feathers) return;
    if(!reportedErrorId) {
      return
    }
    loadData();
  }, [feathers, reportedErrorId])

	return (
		<Wrapper>
			<ErrorDetailWrapper>
				{
					loading && !reportedError &&
					<>
						<Skeleton variant='rect' width={80} height={30} />
						<Skeleton variant='rect' width={80} height={30} />
					</>
				}
				{
					reportedError &&
					<>
						<Typography variant='body1' style={{ marginBottom: '24px'}}>
							{reportedError?.description?.length > 0 
								? `"${reportedError?.description}"`
								: 'No description provided.'
							}
						</Typography>
						<DetailRow>
							<Typography variant='body1' style={{ fontWeight: 'bold'}}>Error level</Typography>
							{
								editingErrorType
								?
									<FieldInlineEdit
										inputComponent={
											<Select
												value={newErrorType}
												onChange={e => setNewErrorType(e.target.value)}
												disabled={newErrorTypeSaving}
											>
												{
													['content', 'data', 'geographical', 'technical'].map(v => (
														<MenuItem key={v} value={v}>{v.slice(0,1).toUpperCase() + v.slice(1)}</MenuItem>
													))
												}
											</Select>
										}
										onCancel={() => setEditingErrorType(false)}
										onSave={saveNewErrorType}
										loading={newErrorTypeSaving}
									/>
								:
									<DataField
										title=''
										onEdit={
											isPermitted(user, ['admin', 'super-admin', 'editor', 'resolve-error' ])
											?
												() => {
													setEditingErrorType(true)
													setNewErrorType(reportedError?.type)
												}
											: null
										}
									>
										<div>
											<Typography variant={'body1'}>
												{(reportedError?.type || '').slice(0,1).toUpperCase() + (reportedError?.type || '').slice(1)} error
											</Typography>
											<Typography variant='body2' style={{ fontSize: '12px' }}>
												{errorTypeDescriptions[reportedError?.type].description}
											</Typography>
										</div>
									</DataField>
							}
						</DetailRow>
						{
							reportedError?.details && Object.keys(reportedError.details)?.length > 0 &&
							Object.keys(reportedError.details).map(key => {
								// take the camel case title and make it a human readable title
								const title = key.replace(/([A-Z])/g, ' $1').replace(/^./, function(str){ return str.toUpperCase(); });
								return (
									<DetailRow>
										<Typography variant='body1' style={{ fontWeight: 'bold'}}>{title}</Typography>
										<Typography variant='body1'>{reportedError.details[key]}</Typography>
									</DetailRow>
								);
							})
						}
						{
							Boolean(reportedError?.reportedByUser || reportedError?.followUpEmail) &&
							<DetailRow>
								<Typography variant='body1' style={{ fontWeight: 'bold'}}>Reported by</Typography>
								<Typography variant='body1'>{[reportedError?.reportedByUser?.name || reportedError?.reportedByUser?.firstName, reportedError?.followUpEmail || reportedError?.reportedByUser?.email].filter(Boolean).join(' - ')}</Typography>
							</DetailRow>
						}
					</>
				}
			</ErrorDetailWrapper>
			<div style={{ minHeight: '24px' }}>
			</div>
			<Typography variant='h3'>Related data</Typography>
			{
				loading &&
				<>
					<Skeleton variant='rect' width={80} height={30} />
					<Skeleton variant='rect' width={80} height={30} />
					<Skeleton variant='rect' width={80} height={30} />
				</>
			}
			{
				!loading &&
				detailedData.map((data, i) => {
					return (
						<DetailRow key={i}>
							<Typography variant='body1' style={{ fontWeight: 'bold' }}>{data.title}</Typography>
							{
								!data.url &&
								<Typography variant='body1'>{data.renderItem()}</Typography>
							}
							{
								data.url &&
								<Link href={data.url} target='_blank' style={{ display: 'flex', textDecoration: 'underline', alignItems: 'center' }}>
									<Typography variant='body1'>{data.renderItem()}</Typography>
									<OpenInNewIcon style={{ marginLeft: '4px', marginBottom: '3px', height: '18px', width: '18px' }}/>
								</Link>
							}
						</DetailRow>
					);
				})
			}
			<Typography variant="h3" style={{ marginTop: '48px', marginBottom: '-8px' }}>Resolution</Typography>
			<FormControl component="fieldset">
				<HorizontalWrapper style={{ gap: '48px'}}>
					<Typography variant='body1' style={{ fontWeight: 'bold' }}>Status</Typography>
					{
						!loading && reportedError?.status === 'open' &&
						<RadioGroup value={status} onChange={e => setStatus(e.target.value)} style={{ marginLeft: '24px'}}>
							<HorizontalWrapper>
								<FormControlLabel value="resolved" control={<Radio size='medium' />} label="Resolved" />
								<FormControlLabel value="resolved-as-ignore" control={<Radio size='medium' />} label="Ignored as an error" />
							</HorizontalWrapper>
						</RadioGroup>
					}
					{
						!loading && reportedError?.status !== 'open' &&
						<Typography variant='body1'>
							{reportedError?.status === 'resolved' && 'Resolved'}
							{reportedError?.status === 'resolved-as-ignored' && 'Ingored as an error'}
						</Typography>
					}
				</HorizontalWrapper>
			</FormControl>
			{
				!loading && reportedError?.status !== 'open' &&
				<Typography variant='body1'>
					Resolved by {reportedError?.resolvedByUser?.name || reportedError?.resolvedByUser?.firstName || reportedError?.resolvedByUser?.email || `${reportedError?.resolvedByUser}`}
				</Typography>
			}
			{
				!loading && reportedError?.status === 'open' &&
				<>
					<TextField
						size='medium'
						color='primary'
						multiline
						minRows={2}
						variant='outlined'
						value={errorNotes}
						onChange={e => setErrorNotes(e.target.value)}
						style={{ marginTop: '-8px', width: '100%', maxWidth: '800px' }}
						placeholder={'Notes about the error resolution. Ex: The candidate John Smith did indeed withdraw about one month ago.'}
					/>
					{
						reportedError?.followUpEmail && reportedError.followUp &&
						<>
							<Typography variant="body1">Follow up with: {reportedError?.followUpEmail}</Typography>
						</>
					}
				</>
			}
			{
				!loading && reportedError?.status !== 'open' &&
				<div style={{ border: 'solid 1px #ccc', borderRadius: '8px', padding: '16px' }}>
					<Typography variant='body1'>{reportedError?.note}</Typography>
				</div>
			}
		</Wrapper>
	);
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	max-width: 900px
`;

const HorizontalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center; 
  gap: 12px;
  width: 100%;
`

const ErrorDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-radius: 4px;
  background-color: #D0DFE9;
`

const DetailRow = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
`

export { ReportedError };